import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import {
    Box,
    Breadcrumbs,
    Link,
    Typography,
    Divider,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Stack,
    FormControlLabel,
    Checkbox,
    Grid,
    Paper,
    Avatar
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Image } from "react-bootstrap";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBullhorn,
    faCircleInfo,
    faSearch,
    faFileCsv
} from "@fortawesome/free-solid-svg-icons";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import Select from "react-select";

import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import Accordion from "react-bootstrap/Accordion";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import "../../styles/users.scss";
import { CSVLink } from "react-csv";
import TablePagination from "@mui/material/TablePagination";

import { columns_attribute_changelog_header } from "../../helpers/theaderColumns/attributes/attributeChangelog";
import dayjs from "dayjs";

import { pageAction } from "../../redux/slices/page/page.js";
import { exportXlsx } from "../../helpers/Exports/FileExport.js";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat.js";

const processedHTML = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
    return tempDiv;
};

const AddPage = () => {
    let { id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        open,
        selectedItem,
        nameCollapsable,
        typeCollapsable,
        drawerVariant,
        usersCollapsable,
    } = useSelector((state) => state?.drawerMenu);

    const {
        pageTitle,
        pageStatus,
        permissions,
        isAdvancePermission,
        html,
        css,
        js,
        pageProcessing,
        components,
        styles,
        getAllGroupsAndUsers,
        pageSlug,
        pageURL,
        menu,
        link,
        getAllChangeLogs
    } = useSelector((state) => state?.page);

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [getAllChangeLogs, setGetAllChangeLogs] = useState();
    const [search, setSearch] = useState("");

    const [isEdit, setIsEdit] = useState(id ? true : false);

    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [warningMessage, setWarningMessage] = useState([]);

    const [isSuccessMessage, setIsSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    // const [getAllGroupsAndUsers, setGetAllGroupsAndUsers] = useState([]);

    const [selectedMediaFileForUpdate, setSelectedMediaFileForUpdate] = useState()

    const [errors, setErrors] = useState({})

    const [isImageMedia, setIsImageMedia] = useState(false)

    const [istype, setIstype] = useState(false);

    const total_changelog = parseInt(getAllChangeLogs?.meta?.total);

    const columns_atttributes_headers = [
        {
            key: 1,
            disablePadding: true,
            id: "name",
            label: "Name",
            minWidth: 250,
            className: "other-color",
        },
        {
            key: 2,
            id: "view",
            disablePadding: true,
            label: "View",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 3,
            id: "deny",
            disablePadding: true,
            label: "Deny",
            minWidth: 50,
            className: "other-color",
        },
    ];

    const columns_advace_atttributes_headers = [
        {
            key: 1,
            disablePadding: true,
            id: "name",
            label: "Name",
            minWidth: 250,
            className: "other-color",
        },
        {
            key: 2,
            id: "view",
            disablePadding: true,
            label: "View",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 3,
            id: "deny",
            disablePadding: true,
            label: "Deny",
            minWidth: 50,
            className: "other-color",
        },

        {
            key: 5,
            id: "add",
            disablePadding: true,
            label: "Add",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 6,
            id: "edit",
            disablePadding: true,
            label: "Edit",
            minWidth: 50,
            className: "other-color",
        },
        {
            key: 7,
            id: "delete",
            disablePadding: true,
            label: "Delete",
            minWidth: 50,
            className: "other-color",
        },
    ];

    const debounceTimeout = 500; // Adjust the debounce timeout as needed
    let debounceTimer;

    const userToken = localStorage.getItem("accessToken");
    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);


    const updatePermissions = (permissions, value, key) => {
        return permissions.map((permission) => {
            if (permission.value === value) {
                if (key === 'deny') {
                    // If deny is toggled, reset all other permissions
                    return {
                        ...permission,
                        deny: !permission.deny,
                        view: false,
                        edit: false,
                        delete: false,
                        add: false,
                    };
                } else {
                    // Toggle the specific permission and unset deny
                    return {
                        ...permission,
                        [key]: !permission[key],
                        deny: false,
                    };
                }
            }
            return permission;
        });
    };

    const handleSetGetAllGroupsAndUsers = (permissions, value, key) => {
        return permissions.map((permission) => {
            if (permission?.value === value) {
                if (key === 'deny') {
                    // If deny is toggled, set all other permissions to false
                    return {
                        ...permission,
                        deny: !permission.deny,
                        view: false,
                        edit: false,
                        delete: false,
                        add: false,
                    };
                } else {
                    // If any other permission is toggled, set deny to false
                    return {
                        ...permission,
                        [key]: !permission[key],
                        deny: false,
                    };
                }
            }
            return permission;
        })
    }

    const handleToggle = (value, key) => {

        const updatedPermissions = updatePermissions(permissions, value, key);

        const updateGetAllGroupsAndUsers = handleSetGetAllGroupsAndUsers(getAllGroupsAndUsers, value, key)

        // Dispatch the updated permissions to Redux
        dispatch(pageAction.setPermissions(updatedPermissions));

        // setGetAllGroupsAndUsers((prevPermissions) =>
        //     prevPermissions.map((permission) => {
        //         if (permission?.value === value) {
        //             if (key === 'deny') {
        //                 // If deny is toggled, set all other permissions to false
        //                 return {
        //                     ...permission,
        //                     deny: !permission.deny,
        //                     view: false,
        //                     edit: false,
        //                     delete: false,
        //                     add: false,
        //                 };
        //             } else {
        //                 // If any other permission is toggled, set deny to false
        //                 return {
        //                     ...permission,
        //                     [key]: !permission[key],
        //                     deny: false,
        //                 };
        //             }
        //         }
        //         return permission;
        //     })
        // );
        dispatch(pageAction.setGetAllGroupsAndUsers(updateGetAllGroupsAndUsers));

    };

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setPage(newPage);
            fetchAllChangeLogs(newPage + 1);
        } else if (newPage < page) {
            setPage(newPage);
            fetchAllChangeLogs(newPage + 1);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const validateFields = () => {
        const errors = {};
        if (!pageTitle.trim()) {
            errors.title = "Page Title is required.";
        }

        if (!html.trim() || !components.trim()) {
            errors.content = "Editor content is required"
        }

        if (permissions?.length < 1) {
            errors.permissions = "Select atleast one user/group."
        }

        if (id) {
            if (!pageSlug.trim()) {
                errors.slug = "Page slug is required."
            }
        }

        return errors;
    };

    const onSubmit = async () => {
        const validationErrors = validateFields();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
        } else {
            let permissionData;
            if (isAdvancePermission == true) {
                permissionData = permissions?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: data?.view == true ? 1 : 0,
                        can_deny: data?.deny == true ? 1 : 0,
                        can_edit: data?.edit == true ? 1 : 0,
                        can_delete: data?.delete == true ? 1 : 0,
                        can_add: data?.add == true ? 1 : 0,
                        label: data?.label,
                    };
                });
            } else {
                permissionData = permissions?.map((data) => {
                    return {
                        user_group_id: data?.value,
                        type: data?.type,
                        can_view: data?.view == true ? 1 : 0,
                        can_deny: data?.deny == true ? 1 : 0,
                        can_edit: 0,
                        can_delete: 0,
                        can_add: 0,
                        label: data?.label,
                    };
                });
            }
            let data = {
                title: pageTitle,
                status: pageStatus === true ? "enable" : "disable",
                advance_permission: isAdvancePermission === true ? 1 : 0,
                permission: JSON?.stringify(permissionData),
                // image: selectedMediaFileForUpdate?.id,
                editor_html: html,
                editor_css: css,
                editor_js: js,
                editor_components: components,
                editor_styles: styles,
                // menu: menu,
                // link: link,
                // category: {}
            }

            if (id) {
                data.slug = pageSlug
            }
            if (id) {
                try {
                    setLoading(true)
                    const response = await api.post(`/admin/page/update-page/${id}`, data, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userToken}`,
                        },
                    })
                    // setLoading(false)

                    if (response?.success == true) {
                        dispatch(pageAction?.setPageProcessing(false))

                        setLoading(false)
                        Toast.success(response?.message)
                        dispatch(pageAction?.clearAll())

                        navigate("/pages")
                    }

                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attemps wait for 2 min.")
                    }
                }
            } else {
                try {
                    setLoading(true)
                    const response = await api.post("/admin/page/store-page", data, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${userToken}`,
                        },
                    })
                    // setLoading(false)

                    if (response?.success == true) {
                        dispatch(pageAction?.setPageProcessing(false))

                        setLoading(false)
                        Toast.success(response?.message)
                        dispatch(pageAction?.clearAll())

                        navigate("/pages")
                    }

                } catch (err) {
                    console.log("error in ctreate news api::", err);
                    setLoading(false);

                    if (err?.response?.status === 401) {
                        setLoading(false);
                        logoutHelper(dispatch, navigate, userToken);
                    }
                    if (err?.response?.status === 422) {
                        setLoading(false);
                        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
                    }
                    if (err?.response?.status === 429) {
                        Toast.error("Too many attemps wait for 2 min.")
                    }
                }
            }

        }

    }

    const fetchGroupsAndUsersList = async () => {
        try {
            const response = await api.get(`/group-users-list`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                let acknowledgementQuestionsArray = [];

                const users = response?.response?.users;
                const groups = response?.response?.groups;

                const categories = response?.response?.category

                const finalGroups = groups?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: true,
                        deny: false,
                        edit: false,
                        delete: false,
                        add: false,
                        type: data?.type,
                    };
                });
                const finalUsers = users?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.value,
                        view: true,
                        deny: false,
                        edit: false,
                        delete: false,
                        add: false,
                        type: data?.type,
                    };
                });

                const allGroupsAndUsersData = [
                    { label: "Users", options: finalUsers },
                    { label: "Groups", options: finalGroups },
                ];
                // Find the "all_user" option
                const allUserOption = finalUsers.find(
                    (user) => user.value == "all_users"
                );

                // setGetAllGroupsAndUsers(allGroupsAndUsersData)
                dispatch(pageAction?.setGetAllGroupsAndUsers(allGroupsAndUsersData))

                // Preselect "all_user"
                if (allUserOption && !isEdit) {
                    // setPermissions([allUserOption])
                    dispatch(pageAction?.setPermissions([allUserOption]))
                }
                dispatch(pageAction?.setPageProcessing(true))
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/type/news`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchPageDetails = async () => {
        try {
            const response = await api.get(`/admin/page/edit-page/${id}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {

                dispatch(pageAction?.setPageTitle(response?.response?.title))
                dispatch(pageAction?.setPageStatus(response?.response?.status === "enable" ? true : false))

                const permissions = response?.response?.permission;

                const finalPermissions = permissions?.map((data) => {
                    return {
                        label: data?.label,
                        value: data?.user_group_id,
                        view: data?.can_view == 0 ? false : true,
                        deny: data?.can_deny == 0 ? false : true,
                        edit: data?.can_edit == 0 ? false : true,
                        delete: data?.can_delete == 0 ? false : true,
                        add: data?.can_add == 0 ? false : true,
                        type: data?.type,
                    };
                });

                const htmlData = response?.response?.editor_html
                const cssData = response?.response?.editor_css
                const jsData = response?.response?.editor_js
                const componentData = response?.response?.editor_components
                const styleData = response?.response?.editor_styles

                dispatch(pageAction?.setPermissions(finalPermissions))
                dispatch(pageAction?.setIsAdvancePermission(response?.response?.advance_permission === 0 ? false : true))
                dispatch(pageAction?.setTemplateData({ htmlData, cssData, jsData, componentData, styleData }))
                dispatch(pageAction?.setPageSlug(response?.response?.slug))
                dispatch(pageAction?.setMenuID(response?.response?.menu))
                dispatch(pageAction?.setPageURL(`/template/${response?.response?.slug}`))

                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                Toast.warning(err?.response?.message);
                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchAllChangeLogs = async (page) => {
        try {
            setLoading(true);
            const response = await api.get(
                `admin/page/change-logs/${id}?per_page=${rowsPerPage}&page=${page}&search=${search}`,
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            if (response?.success == true) {
                response.response.data = response?.response?.data.map((log) => ({
                    ...log,
                    changed_at: dateWithTime(log.changed_at, true, true, true) || log.changed_at,
                }));
                // setGetAllChangeLogs(response?.response);
                dispatch(pageAction?.setGetAllChangeLogs(response?.response))

                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.message);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/`);

                navigate("/");
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    };

    const handleChangeLogCsvDownload = async () => {
        try {
            setLoading(true);

            const result = await exportXlsx(`admin/page/change-logs-export/${id}?search=${search}&pagination=no`, 'page_change_log');
            if (!result) {
                Toast.error("Failed to download file");
            }

        } catch (error) {
            console.error("Error while downloading csv changelog::", error);
            Toast.error("Failed to download file");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const apiCall = async () => {
            if (id) {
                if (pageProcessing === false) {
                    await fetchPageDetails()
                    await fetchGroupsAndUsersList()
                    await fetchAllChangeLogs(page + 1);
                }

            } else {
                if (pageProcessing === false) {
                    await fetchGroupsAndUsersList()

                }
            }
        }
        apiCall();

    }, [id])


    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    <Box className="">
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                <Link
                                    underline="hover"
                                    key="1"
                                    color="inherit"
                                    href="/"
                                    component="h3"
                                >
                                    Admin
                                </Link>
                                <Typography
                                    key="3"
                                    component="h3"
                                    color="inherit"
                                    className="cursor-pointer"
                                    onClick={() => {
                                        localStorage.setItem("last-redirect-page", `/pages`);
                                        dispatch(pageAction?.clearAll())
                                        navigate("/pages");
                                    }}
                                >
                                    Pages
                                </Typography>
                                <Typography key="3" component="h3" color="text.primary">
                                    {id ? "Edit" : "Add"} Page
                                </Typography>
                            </Breadcrumbs>
                        </Stack>
                    </Box>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <Row className="mt-1">
                        <Col lg="9" md="7">
                            <Stack direction="row" className="gap-30">
                                <Accordion
                                    defaultActiveKey={["0", "1", "2", "3"]}
                                    alwaysOpen
                                    className="w-100"
                                >
                                    <Accordion.Item
                                        eventKey="0"
                                        className="bg-white card-wrapper w-100"
                                    >
                                        <Accordion.Header className="bg-white fw-700 primary-text">
                                            Content
                                        </Accordion.Header>
                                        <Accordion.Body className="add-menu-content-p-0">
                                            <Row style={{ padding: "30px" }}>
                                                <Col lg="12">
                                                    <Typography className="fw-400 dark-text">
                                                        Title
                                                        <span className="required">
                                                            *
                                                        </span>
                                                    </Typography>
                                                    <TextField
                                                        className="input-field mt-2"
                                                        fullWidth
                                                        id="outlined-basic username"
                                                        variant="outlined"
                                                        size="small"
                                                        name="title"
                                                        placeholder="Enter title here..."
                                                        value={
                                                            pageTitle
                                                        }
                                                        onChange={(
                                                            event
                                                        ) => {
                                                            dispatch(pageAction?.setPageTitle(event?.target?.value))
                                                            if (event.target.value.trim()) {
                                                                setErrors((prevErrors) => ({ ...prevErrors, title: "" }));
                                                            }
                                                        }}

                                                        helperText={
                                                            errors.title
                                                        }
                                                        error={
                                                            Boolean(
                                                                errors.title
                                                            )
                                                        }
                                                    />
                                                </Col>
                                                {id && (
                                                    <><Col lg="12">
                                                        <Typography className="fw-400 dark-text">
                                                            Page Slug
                                                            <span className="required">
                                                                *
                                                            </span>
                                                        </Typography>
                                                        <TextField
                                                            className="input-field mt-2"
                                                            fullWidth
                                                            id="outlined-basic username"
                                                            variant="outlined"
                                                            size="small"
                                                            name="title"
                                                            placeholder="Enter slug here..."
                                                            value={pageSlug}
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                dispatch(pageAction?.setPageSlug(event?.target?.value));
                                                                dispatch(pageAction?.setPageURL(`/template/${event?.target?.value}`))
                                                                if (event.target.value.trim()) {
                                                                    setErrors((prevErrors) => ({ ...prevErrors, slug: "" }));
                                                                }
                                                            }}

                                                            helperText={errors.slug}
                                                            error={Boolean(
                                                                errors.slug
                                                            )} />
                                                    </Col><Col lg="12">
                                                            <Typography className="fw-400 dark-text">
                                                                Page URL
                                                                <span className="required">
                                                                    *
                                                                </span>
                                                            </Typography>
                                                            <TextField
                                                                className="input-field mt-2"
                                                                fullWidth
                                                                id="outlined-basic username"
                                                                variant="outlined"
                                                                size="small"
                                                                name="title"
                                                                placeholder="Enter url here..."
                                                                value={pageURL}
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    dispatch(pageAction?.setPageURL(event?.target?.value));
                                                                    if (event.target.value.trim()) {
                                                                        setErrors((prevErrors) => ({ ...prevErrors, url: "" }));
                                                                    }
                                                                }}

                                                                helperText={errors.url}
                                                                error={Boolean(
                                                                    errors.url
                                                                )}
                                                                disabled={true} />
                                                        </Col></>
                                                )}

                                                <Col lg="12" className="d-flex justify-content-center align-items-center">
                                                    <Button
                                                        className="btn primary-btn w-25"
                                                        // type="submit"
                                                        onClick={() => {
                                                            navigate("/create/template")
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faBullhorn
                                                            }
                                                        />
                                                        Edit Content
                                                    </Button>
                                                </Col>
                                                {errors?.content && (
                                                    <p
                                                        style={{ color: "#d32f2f" }}
                                                        className="error-msg text-center"
                                                    >
                                                        {errors?.content}
                                                    </p>
                                                )}
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item
                                        eventKey="3"
                                        className="bg-white card-wrapper w-100 mt-4"
                                    >
                                        <Accordion.Header className="bg-white fw-700 primary-text">
                                            Access and Permissions
                                        </Accordion.Header>
                                        <Accordion.Body className="content-card-body">
                                            <Row>
                                                <Col lg="12" className="">
                                                    <Typography className="fw-400 fs-14 dark-text">
                                                        Select Users
                                                    </Typography>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        options={getAllGroupsAndUsers}
                                                        menuPlacement="bottom"
                                                        name="users"
                                                        value={permissions}
                                                        placeholder="Select users..."
                                                        isMulti
                                                        menuPosition="fixed"
                                                        onChange={(selectedOption) => {
                                                            const users = selectedOption.map(
                                                                (option) => option
                                                            );
                                                            dispatch(pageAction?.setPermissions(users))
                                                            if (permissions?.length < 1) {
                                                                setErrors((prevErrors) => ({ ...prevErrors, permissions: "" }));
                                                            }
                                                        }}
                                                        className={`muilt-select-field mt-2 `}
                                                        classNamePrefix="select"
                                                        styles={{
                                                            option: (provided, state) => ({
                                                                ...provided,
                                                                backgroundColor: state.isSelected
                                                                    ? "#660c605c"
                                                                    : provided.backgroundColor,
                                                                color: state.isSelected
                                                                    ? "var(--dark-color)"
                                                                    : provided.color,
                                                            }),
                                                            multiValueLabel: (provided, state) => {
                                                                return {
                                                                    ...provided,
                                                                    color:
                                                                        state.data?.type === "group"
                                                                            ? "rgb(0, 135, 90)"
                                                                            : "rgb(0, 82, 204)",
                                                                };
                                                            },
                                                        }}
                                                    />
                                                </Col>
                                                {errors?.permissions && (
                                                    <p
                                                        style={{ color: "#d32f2f" }}
                                                        className="error-msg"
                                                    >
                                                        {errors?.permissions}
                                                    </p>
                                                )}
                                                {/* <Col lg="12" className="repeatable-col">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={isAdvancePermission === true}
                                                                name="allowEdit"
                                                                onChange={() =>

                                                                    dispatch(pageAction?.setIsAdvancePermission(!isAdvancePermission))
                                                                }
                                                            />
                                                        }
                                                        label="Show Advanced Permissions"
                                                        className="font-inter fs-14"
                                                    />
                                                </Col> */}
                                                <Col lg="12" className="repeatable-col">
                                                    <TableContainer className="z-0">
                                                        <Table
                                                            sx={{ minWidth: 750 }}
                                                            aria-labelledby="tableTitle"
                                                            stickyHeader
                                                        >
                                                            <TableHeaderForSorting
                                                                columns={
                                                                    isAdvancePermission === false
                                                                        ? columns_atttributes_headers
                                                                        : columns_advace_atttributes_headers
                                                                }
                                                            />
                                                            <TableBody>
                                                                {permissions?.length > 0 && (
                                                                    <>
                                                                        {permissions?.map(
                                                                            (permission, index) => (
                                                                                <>
                                                                                    <TableRow
                                                                                        hover
                                                                                        role="checkbox"
                                                                                        tabIndex={-1}
                                                                                        key={permission.value}
                                                                                        className="cursor-pointer tbl-row-attribute-row"
                                                                                    >
                                                                                        <TableCell
                                                                                            align="left"
                                                                                            className={` tbl-border-left tbl-cell-attribute-2`}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                top: 0,
                                                                                                left: 0,
                                                                                            }}
                                                                                        >
                                                                                            <Typography className="fs-14">
                                                                                                {permission?.label}
                                                                                            </Typography>
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            align="right"
                                                                                            className={`tbl-cell-attribute-2`}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                top: 0,
                                                                                                left: 0,
                                                                                            }}
                                                                                        >
                                                                                            <AntSwitch
                                                                                                name="view"
                                                                                                checked={
                                                                                                    permission.view
                                                                                                }
                                                                                                onChange={() =>
                                                                                                    handleToggle(
                                                                                                        permission.value,
                                                                                                        "view"
                                                                                                    )
                                                                                                }
                                                                                                inputProps={{
                                                                                                    "aria-label":
                                                                                                        "ant design",
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell
                                                                                            align="right"
                                                                                            className={`tbl-cell-attribute-2`}
                                                                                            style={{
                                                                                                width: "100%",
                                                                                                top: 0,
                                                                                                left: 0,
                                                                                            }}
                                                                                        >
                                                                                            <AntSwitch
                                                                                                name="deny"
                                                                                                checked={
                                                                                                    permission.deny
                                                                                                }
                                                                                                onChange={() =>
                                                                                                    handleToggle(
                                                                                                        permission.value,
                                                                                                        "deny"
                                                                                                    )
                                                                                                }
                                                                                                inputProps={{
                                                                                                    "aria-label":
                                                                                                        "ant design",
                                                                                                }}
                                                                                            />
                                                                                        </TableCell>

                                                                                        {/* {isAdvancePermission && (
                                                                                            <>
                                                                                                <TableCell
                                                                                                    align="right"
                                                                                                    className={`tbl-cell-attribute-2`}
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        top: 0,
                                                                                                        left: 0,
                                                                                                    }}
                                                                                                >
                                                                                                    <AntSwitch
                                                                                                        name="add"
                                                                                                        checked={
                                                                                                            permission.add
                                                                                                        }
                                                                                                        onChange={() =>
                                                                                                            handleToggle(
                                                                                                                permission.value,
                                                                                                                "add"
                                                                                                            )
                                                                                                        }
                                                                                                        inputProps={{
                                                                                                            "aria-label":
                                                                                                                "ant design",
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    align="right"
                                                                                                    className={`tbl-cell-attribute-2`}
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        top: 0,
                                                                                                        left: 0,
                                                                                                    }}
                                                                                                >
                                                                                                    <AntSwitch
                                                                                                        name="edit"
                                                                                                        checked={
                                                                                                            permission.edit
                                                                                                        }
                                                                                                        onChange={() =>
                                                                                                            handleToggle(
                                                                                                                permission.value,
                                                                                                                "edit"
                                                                                                            )
                                                                                                        }
                                                                                                        inputProps={{
                                                                                                            "aria-label":
                                                                                                                "ant design",
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                                <TableCell
                                                                                                    align="right"
                                                                                                    className={`tbl-border-right tbl-cell-attribute-2`}
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                        top: 0,
                                                                                                        left: 0,
                                                                                                    }}
                                                                                                >
                                                                                                    <AntSwitch
                                                                                                        name="delete"
                                                                                                        checked={
                                                                                                            permission.delete
                                                                                                        }
                                                                                                        onChange={() =>
                                                                                                            handleToggle(
                                                                                                                permission.value,
                                                                                                                "delete"
                                                                                                            )
                                                                                                        }
                                                                                                        inputProps={{
                                                                                                            "aria-label":
                                                                                                                "ant design",
                                                                                                        }}
                                                                                                    />
                                                                                                </TableCell>
                                                                                            </>
                                                                                        )} */}
                                                                                    </TableRow>
                                                                                    <TableRow className="extra-row"></TableRow>
                                                                                </>
                                                                            )
                                                                        )}
                                                                    </>
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    {id && (
                                        <Accordion.Item
                                            eventKey="2"
                                            className="bg-white card-wrapper mt-4"
                                        >
                                            <Accordion.Header className="bg-white fw-700 primary-text">
                                                Changelog
                                            </Accordion.Header>
                                            <Accordion.Body className="p-0 changelog-body">
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    className="changelog-div  "
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={12}
                                                        sm={12}
                                                        lg={9}
                                                        xl={9}
                                                    >
                                                        <Box className="">
                                                            <InputGroup className="">
                                                                <TextField
                                                                    className="search-changelog-input"
                                                                    fullWidth
                                                                    id="outlined-basic username"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    name="attributeTitle"
                                                                    value={search}
                                                                    placeholder="search changelog"
                                                                    onChange={(e) => {
                                                                        setIstype(true);
                                                                        setSearch(e.target.value);
                                                                        if (e.target.value === "") {
                                                                            fetchAllChangeLogs(page);
                                                                        }
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.key === "Enter") {
                                                                            e.preventDefault();
                                                                            fetchAllChangeLogs(page);
                                                                        }
                                                                    }}
                                                                />
                                                                <Button
                                                                    className="search-changelog"
                                                                    onClick={() => {
                                                                        fetchAllChangeLogs(page);
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon={faSearch}
                                                                        className="search-changelog-icon"
                                                                    />
                                                                </Button>
                                                            </InputGroup>
                                                        </Box>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={12}
                                                        sm={12}
                                                        lg={3}
                                                        xl={3}
                                                    >
                                                        <div className="w-100">

                                                            <Button
                                                                variant="outlined"
                                                                startIcon={
                                                                    <FontAwesomeIcon icon={faFileCsv} />
                                                                }
                                                                className="export-csv-btn"
                                                                onClick={handleChangeLogCsvDownload}
                                                            >
                                                                Export as CSV
                                                            </Button>
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                                <Paper
                                                    sx={{ width: "100%" }}
                                                    className="changelog-table-div mt-4"
                                                >
                                                    <TableContainer className="">
                                                        <Table
                                                            sx={{ minWidth: 750 }}
                                                            aria-labelledby="tableTitle"
                                                            stickyHeader
                                                        >
                                                            <TableHeaderForSorting
                                                                columns={
                                                                    columns_attribute_changelog_header
                                                                }
                                                                className="z-0"
                                                            />
                                                            <TableBody>
                                                                {getAllChangeLogs?.data?.map(
                                                                    (data, index) => (
                                                                        <>
                                                                            <TableRow
                                                                                hover
                                                                                role="checkbox"
                                                                                tabIndex={-1}
                                                                                key={index + 1}
                                                                                className=" tbl-changelog mt-2"
                                                                            >
                                                                                <TableCell
                                                                                    align="left"
                                                                                    className="tbl-border-left tbl-cell-5"
                                                                                >
                                                                                    <Box>
                                                                                        <Stack
                                                                                            direction="row"
                                                                                            alignContent="center"
                                                                                            alignItems="center"
                                                                                            spacing={1}
                                                                                            className="z-n1"
                                                                                        >
                                                                                            <Avatar
                                                                                                alt={data?.changed_by}
                                                                                                src="/static/images/avatar/1.jpg"
                                                                                                className="author-avtar"
                                                                                            />
                                                                                            <Typography className="fw-400 fs-14 ">
                                                                                                {data?.changed_by
                                                                                                    ? data?.changed_by
                                                                                                    : "-"}
                                                                                            </Typography>
                                                                                        </Stack>
                                                                                    </Box>
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="left"
                                                                                    className="text-nowrap"
                                                                                >
                                                                                    {data?.changed_at
                                                                                        ? data?.changed_at
                                                                                        : "-"}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="left"
                                                                                    className="text-nowrap"
                                                                                >
                                                                                    {data?.area
                                                                                        ? data?.area
                                                                                        : "-"}
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="left"
                                                                                    className="tbl-border-right"
                                                                                >
                                                                                    <Typography
                                                                                        className="fw-400 fs-14"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: data?.description
                                                                                                ? processedHTML(
                                                                                                    data?.description
                                                                                                ).innerHTML
                                                                                                : "-",
                                                                                        }}
                                                                                    ></Typography>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow className="extra-row"></TableRow>
                                                                        </>
                                                                    )
                                                                )}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>
                                                {total_changelog > rowsPerPage && (
                                                    <TablePagination
                                                        component="div"
                                                        count={total_changelog}
                                                        page={page}
                                                        onPageChange={handleChangePage}
                                                        rowsPerPage={rowsPerPage}
                                                        onRowsPerPageChange={
                                                            handleChangeRowsPerPage
                                                        }
                                                        labelDisplayedRows={({
                                                            from,
                                                            to,
                                                            count,
                                                        }) => {
                                                            const currentPage = page + 1;
                                                            const start =
                                                                currentPage * rowsPerPage -
                                                                rowsPerPage +
                                                                1;
                                                            const end = Math.min(
                                                                currentPage * rowsPerPage,
                                                                count
                                                            );
                                                            return (
                                                                <>
                                                                    {`Showing ${start} to ${end} of `}
                                                                    <span>{count}</span>
                                                                    {" records"}
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                )}
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    )}

                                </Accordion>
                            </Stack>
                        </Col>
                        <Col lg="3" md="5">
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                gap={2}
                                flexWrap="wrap"
                                className="publish-div"
                            >
                                <Card className="bg-white card-wrapper w-100">
                                    <Card.Header className="bg-white fw-700 primary-text">
                                        Publish
                                    </Card.Header>
                                    <Card.Body className="p-15 publish-div">
                                        <Button
                                            className="btn primary-btn w-100"
                                            type="submit"
                                            onClick={() => {
                                                onSubmit()
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faBullhorn} />
                                            Publish
                                        </Button>
                                        <div className="mt-3 enable-div">
                                            <Stack
                                                direction="row"
                                                justifyContent="space-between"
                                                gap={2}
                                                flexWrap="wrap"
                                            >
                                                <div className="d-flex align-items-center gap-3">
                                                    <Box className="d-flex gap-3 flex-wrap justify-content-center">
                                                        <Typography
                                                            className="fw-400 dark-text font-inter "
                                                            whiteSpace="nowrap"
                                                        >
                                                            Enable Page
                                                        </Typography>
                                                        <BootstrapTooltip
                                                            title="You can enable/disable user."
                                                            placement="bottom"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={faCircleInfo}
                                                                className="mandatory-icon fw-900"
                                                                aria-haspopup="true"
                                                            />
                                                        </BootstrapTooltip>
                                                    </Box>
                                                </div>

                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                    className="h-100"
                                                >
                                                    <AntSwitch
                                                        name="userStatus"
                                                        checked={pageStatus}
                                                        onChange={(event) => {
                                                            dispatch(pageAction?.setPageStatus(!pageStatus))
                                                        }}
                                                        inputProps={{
                                                            "aria-label": "ant design",
                                                        }}
                                                    />
                                                </Stack>
                                            </Stack>
                                        </div>
                                    </Card.Body>
                                </Card>

                            </Stack>
                        </Col>
                    </Row>



                </Container>
            </div>
            <Loader isLoading={loading} />
            <Toaster />
        </>
    )
}

export default AddPage