import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    drawerVariant,
    usersCollapsable,
    adminAccessible
  } = useSelector((state) => state?.drawerMenu);

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);

  useEffect(() => {
    if (loggedUser?.user_type === "user") {
      navigate("/dashboard");
    } else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
      localStorage.setItem("last-redirect-page-admin-user", `/dashboard`);
      navigate("/dashboard")
    } else if (loggedUser?.user_type === "admin" && adminAccessible == false) {
      localStorage.setItem("last-redirect-page", `/`);
      navigate("/")
    }
  }, []);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          <Typography variant="h4">Dashboard</Typography>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
