import React, { useEffect, useRef, useState } from "react";
import { Container, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faDownload,
  faXmark,
  faFloppyDisk,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";

import Header from "../../components/layout/header";
import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import logoutHelper from "../../helpers/logoutHelper";
import { useDispatch, useSelector } from "react-redux";
import DrawerMenu from "../../components/layout/drawer";
import { useParams } from "react-router-dom";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Button,
  IconButton,
  Typography,
  Chip,
  CircularProgress,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { CSVLink } from "react-csv";
import { columns_type_post_header } from "../../helpers/theaderColumns/type-post/typePost";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import activeTypeImg from "../../assets/images/active.png";
import inactiveType from "../../assets/images/inactive.png";
import deleteType from "../../assets/images/delete.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { Toaster } from "react-hot-toast";
import { Toast } from "../../utils/toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import StatusIcon from "../../components/common/StatusIcon";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat";
import { exportXlsx } from "../../helpers/Exports/FileExport";

const Home = () => {
  let { type } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = localStorage.getItem("user");
  const userToken = localStorage.getItem("accessToken");
  const [loading, setLoading] = useState(false);
  const [allNews, setAllNews] = useState([]);
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  //states for csv
  const [getChangelogCsvData, setGetChangelogCsvData] = useState([]);
  const [isCsvReady, setIsCsvReady] = useState(false);

  const [dataOrder, setDataOrder] = useState("asc");
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openConfirmDeleteAlert, setOpenConfirmDeleteAlert] = useState(false);
  const [categoryId, setCategoryId] = useState();

  //states for paginations
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [nextPage, setNextPage] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);


  const [anchorElMenu, setAnchorElForMenu] = useState(null);

  const openMenuFoEdit = Boolean(anchorElMenu);
  const ITEM_HEIGHT = 48;
  const selectedTypeID = localStorage.getItem("type-selected-id");
  const selectedTypeName = localStorage.getItem("type-selected-name");

  const header_csv = [
    { label: "No", key: "no" },
    { label: "Name", key: "name" },
    { label: "Last Created", key: "lastcreated" },
    { label: "Created BY", key: "createdby" },
    { label: "Status", key: "status" },
  ];

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    activeType,
    drawerVariant,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setCategoryId(row);
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
  };

  const handleCloseConfirmDeleteAlertModel = () => {
    setOpenConfirmDeleteAlert(false);
  };

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(order);
    setAllNews([]);
    setPage(1);
    fetchAllTypePost(order, 1);
  };
  const changeTypePostStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/type-post/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setAllNews((prevPosts) =>
          prevPosts.map((post) =>
            post.id === id ? { ...post, status: status } : post
          )
        );
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setAnchorElForMenu(null);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      console.log("error for change type status::", err)
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const RestoreTypePost = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/type-post/restore `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        console.log(response);
        setAllNews((prevPosts) =>
          prevPosts.map((post) =>
            post.id === id ? { ...post, status: response?.response?.status } : post
          )
        );
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setAnchorElForMenu(null);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        handleClose();
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const deleteTypePostAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/type-post/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setAllNews((prevPosts) =>
          prevPosts.map((post) =>
            post.id === id ? { ...post, status: 'deleted' } : post
          )
        );
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseDeleteAlertModel();
        setAnchorElForMenu(null);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteTypePostAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/type-post/forcedelete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setIsSuccessMessage(true);
        setPage(1);
        setAllNews([]);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseConfirmDeleteAlertModel();
        setAnchorElForMenu(null);

        fetchAllTypePost(dataOrder, 1);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseConfirmDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      setLoading(false);
    }
  };

  const fetchAllTypePost = async (order, page) => {
    try {
      if (page == 1) {
        setLoading(true);
      }
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(
        `admin/type-post/all/${order ? `?order=${order}` : ""
        }&typeid=${selectedTypeID}&page=${page ?? 1}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      // setAllNews(response?.data);
      const newPosts = response?.response?.data;
      setAllNews((prevPosts) => {
        const uniquePosts = newPosts.filter(
          (newPost) =>
            !prevPosts.some((post) => post.id === newPost.id)
        );
        return [...prevPosts, ...uniquePosts];
      });

      setTotalPages(response?.response?.meta.last_page);
      if (page + 1 <= response?.response?.meta.last_page) {
        setNextPage(true);
      }
    } catch (err) {
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        localStorage.setItem("last-redirect-page", `/type/${type}`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const handleScroll = async () => {
    const scrollableHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;
    const remainingScrollThreshold = scrollableHeight * 0.3;

    if (
      scrollableHeight - scrollPosition < remainingScrollThreshold &&
      !isDataFetching &&
      page < totalPages
    ) {
      console.log("inside handle scroll if condition");
      setIsDataFetching(true);
      const nextPageNum = page + 1;
      setPage(nextPageNum);
      await fetchAllTypePost(dataOrder, nextPageNum);
    }
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () =>
      window.removeEventListener("scroll", debouncedHandleScroll);
  }, [isDataFetching, page, totalPages]);

  const handleChangeLogCsvDownload = async (order) => {
    try {
      setLoading(true);

      const result = await exportXlsx(`admin/type-post/all/${order ? `?order=${order}` : ""
        }&typeid=${selectedTypeID}&pagination=no`, selectedTypeName);
      if (!result) {
        Toast.error("Failed to download file");
      }

    } catch (error) {
      console.error("Error while downloading csv changelog::", error);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setAllNews([]);
    setGetChangelogCsvData([]);
    setPage(1);
    fetchAllTypePost(dataOrder, 1);
  }, [type]);


  const csvLinkRef = useRef();

  const handleExportClick = async () => {
    await handleChangeLogCsvDownload(dataOrder);
  };

  useEffect(() => {
    if (isCsvReady && getChangelogCsvData.length > 0) {
      csvLinkRef.current.link.click();
      setIsCsvReady(false);
    }
  }, [isCsvReady, getChangelogCsvData]);

  return (
    <>

      {/* START :: MAIN CONTENT WRAPPER */}

      <div
        className={`main-content-wrapper ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage == true && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography key="3" component="h3" color="text.primary">
                    {selectedTypeName}
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <div className="d-flex flex-wrap gap-3">
              <Button className="btn export-btn" variant="outlined" onClick={handleExportClick}>
                Export
                <FontAwesomeIcon icon={faDownload} />
              </Button>
              <CSVLink
                data={getChangelogCsvData}
                headers={header_csv}
                filename={`type post ${selectedTypeName}.csv`}
                target="_blank"
                className="csv-btn-a"
                ref={csvLinkRef} // Refrence for auto downlaod
                style={{ display: "none" }} // Hide the CSVLink
              />
              <Button
                className="btn primary-btn"
                onClick={() => {
                  localStorage.setItem(
                    "last-redirect-page",
                    `/type/add/${type}`
                  );

                  navigate(`/type/add/${type}`);
                }}
              >
                Add New
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </div>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            ></Box>
            <Paper sx={{ width: "100%" }} className="mt-3 type-list-paper">
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  stickyHeader
                >
                  {allNews?.length > 0 && (
                    <>
                      <TableHeaderForSorting
                        columns={columns_type_post_header}
                        handleChangeDataOrdr={handleChangeDataOrdr}
                        dataOrder={dataOrder}
                      />
                      <TableBody>
                        {allNews?.map((row, index) => (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index + 1}
                            className="cursor-pointer"
                          >
                            <TableCell
                              align="left"
                              className="tbl-body-cell tbl-border-left"
                            >
                              <div
                                className="d-flex flex-warp gap-3"
                                style={{ fontSize: "14px" }}
                              >
                                <p
                                  className={`${row?.status === "deleted" &&
                                    "text-muted fw-700"
                                    }`}
                                >
                                  {row?.title}
                                </p>
                                {row?.published_date == null && (
                                  <Chip
                                    label="Draft"
                                    className="chip-label-div"
                                  />
                                )}

                                {row?.is_archived == 1 && (
                                  <Chip
                                    label="Archived"
                                    className="chip-label-div"
                                  />
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="left" className="tbl-body-cell-3">
                              {row?.published_date !== null
                                ? dateWithTime(row?.modified_date, true, false, true)
                                : "Not Published yet."}
                            </TableCell>

                            <TableCell align="left" className="tbl-body-cell">
                              <Box>
                                <Stack
                                  direction="row"
                                  alignContent="center"
                                  alignItems="center"
                                  spacing={1}
                                  className="z-n1"
                                >
                                  <Avatar
                                    src={row?.profile}
                                    className="author-avtar "
                                    sx={{ fontSize: "14px" }}
                                  >
                                    {row?.created_by?.charAt(0)}
                                  </Avatar>
                                  <Typography className="fw-400 fs-14">
                                    {row?.created_by}
                                  </Typography>
                                </Stack>
                              </Box>
                            </TableCell>
                            <TableCell
                              align="center"
                              className="tbl-body-cell-2 tbl-border-right"
                            >
                              <div>
                                <Stack
                                  direction="row"
                                  alignContent="center"
                                  alignItems="center"
                                  spacing={1}
                                  className="gap-1"
                                >
                                  <StatusIcon status={row?.is_archived === 1 ? 'archived' : row?.status} />
                                  <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-haspopup="true"
                                    onClick={(e) => handleClick(e, row)}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                </Stack>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </>
                  )}
                </Table>
              </TableContainer>
              {isDataFetching && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 2
                  }}
                >
                  <CircularProgress sx={{ color: "#660C60" }} />
                </Box>
              )}
            </Paper>
            {allNews?.length < 1 && (
              <div className="no-data mt-5">
                <div>
                  <Image
                    src={NoDataFoundImg}
                    className="text-center mx-auto no-data-img mt-4"
                  />
                </div>
                <Typography component="h5" variant="h5">
                  No data found
                </Typography>
              </div>
            )}
          </Box>

          {/* START :: NEWS LISTING WRAPPER */}
          {/* <Row>
            {isfetchAllNews == true && (
              <NewsCard
                allNews={allNews}
                isLoading={loading}
                setLoading={setLoading}
                fetchAllNews={fetchAllNews}
                navigate={navigate}
                dispatch={dispatch}
                type={type}
              />
            )}
          </Row> */}

          {/* START :: NEWS LISTING WRAPPER */}
        </Container>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to delete post?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => deleteTypePostAPI(categoryId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openConfirmDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseConfirmDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to permanantly delete post? Once you permanatly
            delete you can not restore it again.
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseConfirmDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => permanantlyDeleteTypePostAPI(categoryId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/type/edit/${type}/${categoryId?.id}`
                );
                navigate(`/type/edit/${type}/${categoryId?.id}`);
              }}
            >
              Edit
            </MenuItem>
          )}

          {categoryId?.status !== "enable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeTypePostStatus(categoryId?.id, "enable");
                }}
              >
                Active
              </MenuItem>
            )}
          {categoryId?.status !== "disable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeTypePostStatus(categoryId?.id, "disable");
                }}
              >
                Inactive
              </MenuItem>
            )}
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenDeleteAlert(true);
              }}
            >
              Delete
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                RestoreTypePost(categoryId?.id);
              }}
            >
              Restore
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirmDeleteAlert(true);
              }}
            >
              Permanantly Delete
            </MenuItem>
          )}
        </Menu>
        <Loader isLoading={loading} />
        <Toaster />
      </div>

      {/* END :: MAIN CONTENT WRAPPER */}
    </>
  );
};

export default Home;
