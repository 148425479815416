export const columns_menu_header = [
  {
    key: 1,
    disablePadding: true,
    id: "name",
    label: "Name",
    minWidth: 250,
    className: "tbl-head-class-2",
    showInfoIcon: true,
  },
  { key: 2, disablePadding: true, id: "used", label: "Date Created", minWidth: 250 },
  {
    key: 3,
    id: "author",
    disablePadding: true,
    label: "Author",
    minWidth: 70,
    className: "other-color",
  },
  {
    key: 4,
    id: "status",
    disablePadding: true,
    label: "Status",
    minWidth: 50,
    className: "other-color",
  },
];
