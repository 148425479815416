import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Row, Col, Card, Image } from "react-bootstrap";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import {
    Typography,
    Box,
    Stack,
    Breadcrumbs,
    Divider,
    Link,
    Grid,
    Paper,
    Avatar,
    CardActions,
    Popper,
    Tabs, Tab,
    List,
    ListItem,
    ListItemText,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import { Toast } from "../../utils/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faClock,
    faBriefcase,
    faChevronRight,
    faArrowRight,
    faCheck,
    faImage,
    faChevronUp,
    faChevronDown,
    faThumbsUp,
    faComments,
    faAdd,
    faHeart,
    faSmile,
    faAngry,
    faDownload,
    faShare,
    faList,
    faEye,
    faPhone,
    faEnvelopeOpen,
    faUser,
    faHome,
    faFolder
} from "@fortawesome/free-solid-svg-icons";
import "../../styles/userTypeViewPage.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import PropTypes from "prop-types";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import CardContent from "@mui/material/CardContent";
import "../../styles/userPeopleProfileView.scss"
import { columns_group_data } from "../../helpers/theaderColumns/people/group";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import { columns_categories_data } from "../../helpers/theaderColumns/people/categories";
import { columns_attributes_data } from "../../helpers/theaderColumns/people/attributes";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import { columns_manager_data } from "../../helpers/theaderColumns/people/manager";
import { headerAction } from "../../redux/slices/header/header";
import DummyUser from "../../assets/images/dummy-user.jpg";


const processedHTML = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
    return tempDiv;
};

function CustomTabPanel(props) {
    const { children, value, className, index, errorTab, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box className="">{children}</Box>}
        </div>
    );
}

const UserPeopleProfileView = () => {
    let { id } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        open,
        selectedItem,
        nameCollapsable,
        typeCollapsable,
        drawerVariant,
        usersCollapsable,
        menuFor,
        adminAccessible
    } = useSelector((state) => state?.drawerMenu);

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);
    const userToken = localStorage.getItem("accessToken");

    const [loading, setLoading] = useState(false);
    const [isWarningMessage, setIsWarningMessage] = useState(false);
    const [getProfleData, setGetProfileData] = useState();
    const [warningMessage, setWarningMessage] = useState("");
    const [tabValue, setTabValue] = useState(0);


    const fetchProfleDetails = async () => {
        try {
            setLoading(true);
            const response = await api.get(`user/people/${parseInt(id)}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                setGetProfileData(response?.response);
                setLoading(false);
            } else if (response?.success == false) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(response?.message);
                Toast.warning(response?.message);
                if (loggedUser?.user_type === "user") {
                    localStorage.setItem("last-redirect-page-user", `/`);
                    navigate(`/`);
                }
                else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                    localStorage.setItem("last-redirect-page-admin-user", `/`)
                    navigate(`/`);
                }
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status === 403) {
                setLoading(false);
                setIsWarningMessage(true);
                setWarningMessage(err?.response?.data[0]?.message);
                Toast.warning(err?.response?.data[0]?.message);
                if (loggedUser?.user_type === "user") {
                    localStorage.setItem("last-redirect-page-user", `/dashboard`);
                    navigate(`/dashboard`);
                }
                else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                    localStorage.setItem("last-redirect-page-admin-user", `/dashboard`)
                    navigate(`/dashboard`);
                }
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    };

    const handleChangeForTab = (event, newValue) => {
        setTabValue(newValue);
    };

    function a11yProps1(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                tabValue == index
                    ? `selected-tab-attribute `
                    : `non-selected-tab-attribute `,
        };
    }
    function a11yProps2(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                tabValue == index
                    ? `selected-tab-attribute `
                    : `non-selected-tab-attribute`,
        };
    }

    const formatValue = (value) => {

        if (Array.isArray(value)) {
            return value.map((item, i) => {
                return (
                    <span key={i}>
                        {item?.fieldtype === 'link_object' ? item?.value?.title : item}
                        {i < value.length - 1 && ", "}
                    </span>
                );
            });
        }
        return value;
    };

    const formatLinkObject = (value) => {
        if (Array.isArray(value)) {
            if (value.length < 1) {
                return "";
            } else {
                return JSON.stringify(value);
            }
        }
        const attValue = JSON.parse(value);
        console.log("att value::", attValue);
        return (
            <span>
                {attValue?.url && attValue?.title && (<a href={attValue?.url} target="_blank">{attValue?.title}</a>)}
            </span>
        );
    }

    const formatDisplayData = (attribute) => {
        if (attribute?.fieldtype === 'link_object') {
            return formatLinkObject(attribute?.value);
        }
        return formatValue(attribute?.value);
    }


    useEffect(() => {
        const apiCall = async () => {
            if (parseInt(id)) {
                await fetchProfleDetails();
            }
        };
        apiCall();
    }, [parseInt(id)]);


    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    <Box className="mb-5">
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                <Link
                                    underline="hover"
                                    key="1"
                                    color="inherit"
                                    href="/"
                                    component="h3"
                                    onClick={() => {
                                        if (loggedUser?.user_type === "user") {
                                            localStorage.setItem("last-redirect-page-user", `/user-type/people`);
                                            navigate(`/user-type/people`);
                                        }
                                        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                            localStorage.setItem("last-redirect-page-admin-user", `/user-type/people`)
                                            navigate(`/user-type/people`);
                                        }
                                    }}
                                    className="cursor-pointer"
                                >
                                    People Directory
                                </Link>
                                <Typography key="3" component="h3" color="text.primary">
                                    {getProfleData?.name}
                                </Typography>
                            </Breadcrumbs>
                        </Stack>
                        <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    </Box>
                    <Grid
                        container
                        spacing={3}
                        className="main-grid-post"
                    >
                        <Grid item lg={4} sm={12} md={12} xl={4} xs={12} className="people-card-wrapper">
                            <Card className="people-card-wrapper-2" >
                                <CardContent className="p-0">
                                    <Stack direction="vertical" className="gap-10 h-100 align-items-center justify-content-center w-100">
                                        <Box className="main-content-data-box w-100">
                                            <Stack
                                                direction="horizontal"
                                                className="d-flex flex-wrap gap-3 w-100 align-items-center justify-content-center"
                                            >
                                                <Box className="w-100 d-flex align-items-center justify-content-center">
                                                    {
                                                        getProfleData?.image ?
                                                            (
                                                                <Image src={getProfleData?.image} className="user-img-2 text-center" alt={getProfleData?.name} />
                                                            ) :
                                                            (
                                                                <Image src={DummyUser} className="user-img-2 text-center" alt={getProfleData?.name} />
                                                            )
                                                    }
                                                </Box>
                                            </Stack>
                                            <Stack
                                                direction="horizontal"
                                                className="d-flex flex-wrap gap-3 align-items-center justify-content-center"
                                            >
                                                <h4
                                                    className="news-title dark-text fw-400 cursor-pointer text-break"
                                                    style={{ height: "28px" }}

                                                >
                                                    {getProfleData?.name}
                                                </h4>
                                            </Stack>
                                            <Stack
                                                direction="horizontal"
                                                className="d-flex flex-wrap gap-3 mt-2 align-items-center justify-content-center"
                                            >
                                                <Box className="d-flex gap-4 justify-content-center align-items-center">
                                                    <FontAwesomeIcon icon={faPhone} className="fs-12-660C60" />
                                                    <Typography className="fs-14 opacity-50">{getProfleData?.phone ? getProfleData?.phone : "--"}</Typography>
                                                </Box>
                                            </Stack>
                                            <Stack
                                                direction="horizontal"
                                                className="d-flex flex-wrap gap-3 mt-1 align-items-center justify-content-center"
                                            >
                                                <Box className="d-flex gap-4 justify-content-center align-items-center">
                                                    <FontAwesomeIcon icon={faEnvelopeOpen} className="fs-12-660C60" />
                                                    <Typography className="fs-14 opacity-50 text-break">{getProfleData?.email ? getProfleData?.email : "--"}</Typography>
                                                </Box>
                                            </Stack>
                                            {getProfleData?.email && (
                                                <Stack
                                                    direction="horizontal"
                                                    className="d-flex flex-wrap gap-3 mt-1 align-items-center justify-content-center"
                                                >
                                                    <Button
                                                        className="btn primary-btn d-flex gap-3 w-100"
                                                        variant="outlined"
                                                        size="large"
                                                        onClick={(e) => {
                                                            window.location.href = `mailto:${getProfleData?.email}?subject=Profile%20Inquiry&body=I%20would%20like%20to%20inquire%20about%20the%20profile%20${getProfleData?.name}`;
                                                            // dispatch(headerAction.setAnchorElForChat(e?.currentTarget))
                                                        }}
                                                    >
                                                        {`contact ${getProfleData?.name}`}
                                                        <FontAwesomeIcon icon={faArrowRight} />
                                                    </Button>
                                                </Stack>
                                            )}

                                        </Box>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            lg={8}
                            sm={12}
                            md={12}
                            xl={8}
                            xs={12}
                            className="main-grid-1"
                        >
                            <Card className="">
                                <div className="people-card-wrapper" style={{ backgroundColor: "#FFFFFF" }}>
                                    <div className="">
                                        <h3 className="view-news-short-desc text-break">
                                            {getProfleData?.job_title}
                                        </h3>
                                    </div>
                                    <div className="mt-3">
                                        <Typography
                                            variant="body1"
                                            component="p"
                                            className="view-news-content text-break"

                                        >{getProfleData?.bio}</Typography>
                                    </div>

                                </div>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: "divider",
                                    }}
                                    className="box-attribute"
                                >
                                    <Tabs
                                        value={tabValue}
                                        onChange={handleChangeForTab}
                                        aria-label="basic tabs example"
                                        // variant={isTablet && "scrollable"}
                                        variant="scrollable"

                                        TabIndicatorProps={{
                                            style: {
                                                backgroundColor: "#1976d2",
                                            },
                                        }}
                                        className="people-tabs"
                                    >
                                        <Tab
                                            icon={<FontAwesomeIcon icon={faFolder} />}
                                            label="Classification"
                                            iconPosition="start"
                                            {...a11yProps1(0)}
                                        />

                                        <Tab
                                            label="Organization"
                                            icon={<FontAwesomeIcon icon={faHome} />}
                                            iconPosition="start"
                                            {...a11yProps2(1)}
                                        />
                                    </Tabs>
                                </Box>
                                <CustomTabPanel
                                    value={tabValue}
                                    index={0}
                                >
                                    <Paper
                                        sx={{ width: "100%" }}
                                    >
                                        <Typography variant="h5" className="mt-3 text-center">Groups</Typography>
                                        {getProfleData?.groups?.length > 0 ? (
                                            <TableContainer className="">
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    stickyHeader
                                                    className="tbl-people-group"
                                                >
                                                    <TableHeaderForSorting
                                                        columns={
                                                            columns_group_data
                                                        }
                                                        className="z-0"
                                                    />
                                                    <TableBody>

                                                        {getProfleData?.groups?.map((group, index) => (
                                                            <>
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={index + 1}
                                                                    className="tbl-changelog mt-2"
                                                                >
                                                                    <TableCell
                                                                        align="left"
                                                                        className={`tbl-cell-group-1 border-top-group ${index === getProfleData.groups.length - 1 ? 'border-bottom-group' : ''}`}
                                                                    >
                                                                        <div
                                                                            className="d-flex flex-warp gap-3 align-items-center"
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            <div
                                                                                className={`icon-div-user-group d-flex justify-content-center align-items-center icons-div`}
                                                                                key={index}
                                                                                style={{ backgroundColor: group?.background }}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={group?.icon}
                                                                                    className="icon-user-group"
                                                                                    style={{ color: group?.colour }}
                                                                                />
                                                                            </div>
                                                                            <p
                                                                                className={`${group?.status === "deleted" &&
                                                                                    "text-muted fw-700"
                                                                                    } fs-14`}
                                                                            >
                                                                                {group?.title}
                                                                            </p>

                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="left"
                                                                        className={`tbl-cell-group-1 fs-14 border-top-group ${index === getProfleData.groups.length - 1 ? 'border-bottom-group' : ''}`}
                                                                    >
                                                                        {group?.members_count}
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <div className="no-data">
                                                <div>
                                                    <Image
                                                        src={NoDataFoundImg}
                                                        className="text-center mx-auto no-data-img-2"
                                                    />
                                                </div>

                                            </div>
                                        )}


                                        <Typography variant="h5" className="mt-2 text-center">Categories</Typography>
                                        {getProfleData?.category_data?.length > 0 ? (
                                            <TableContainer className="">
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    stickyHeader
                                                    className="tbl-people-group"
                                                >
                                                    <TableHeaderForSorting
                                                        columns={
                                                            columns_categories_data
                                                        }
                                                        className="z-0"
                                                    />
                                                    <TableBody>

                                                        {getProfleData?.category_data?.map((category, index) => (
                                                            <>
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={index + 1}
                                                                    className="tbl-changelog mt-2"
                                                                >
                                                                    <TableCell
                                                                        align="left"
                                                                        className={`tbl-cell-group-1 border-top-group ${index === getProfleData?.category_data?.length - 1 ? 'border-bottom-group' : ''}`}
                                                                    >
                                                                        <Typography className="fs-14">{category?.title}</Typography>

                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="left"
                                                                        className={`tbl-cell-group-1 fs-14 border-top-group ${index === getProfleData?.category_data?.length - 1 ? 'border-bottom-group' : ''}`}
                                                                    >
                                                                        <Typography className="fs-14">
                                                                            {category?.categoryterm?.length > 0 ? (
                                                                                <>
                                                                                    {category?.categoryterm?.map(term => term.name).join(", ")}

                                                                                </>
                                                                            ) : "---"}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <div className="no-data">
                                                <div>
                                                    <Image
                                                        src={NoDataFoundImg}
                                                        className="text-center mx-auto no-data-img-2"
                                                    />
                                                </div>

                                            </div>
                                        )}


                                        <Typography variant="h5" className="mt-2 text-center">Attributes</Typography>
                                        {getProfleData?.attribute?.length > 0 ? (
                                            <TableContainer className="">
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    stickyHeader
                                                    className="tbl-people-group"
                                                >
                                                    <TableHeaderForSorting
                                                        columns={columns_attributes_data}
                                                        className="z-0"
                                                    />
                                                    <TableBody>
                                                        {getProfleData?.attribute?.map((attribute, index) => (
                                                            <>
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    className="tbl-changelog mt-2"
                                                                >
                                                                    <TableCell
                                                                        align="left"
                                                                        className={`tbl-cell-group-1 border-top-group ${index === getProfleData?.attribute?.length - 1
                                                                            ? 'border-bottom-group'
                                                                            : ''
                                                                            }`}
                                                                    >
                                                                        <Typography className="fs-14">{attribute?.label}</Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        align="left"
                                                                        className={`tbl-cell-group-1 fs-14 border-top-group ${index === getProfleData?.attribute?.length - 1
                                                                            ? 'border-bottom-group'
                                                                            : ''
                                                                            }`}
                                                                    >
                                                                        <Typography className="fs-14">
                                                                            {formatDisplayData(attribute)}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <div className="no-data">
                                                <div>
                                                    <Image
                                                        src={NoDataFoundImg}
                                                        className="text-center mx-auto no-data-img-2"
                                                    />
                                                </div>

                                            </div>
                                        )}

                                    </Paper>
                                </CustomTabPanel>
                                <CustomTabPanel
                                    value={tabValue}
                                    index={1}
                                >
                                    <Paper
                                        sx={{ width: "100%" }}
                                    >
                                        <Typography variant="h5" className="mt-3 text-center">Managers</Typography>
                                        {getProfleData?.manager?.length > 0 ? (
                                            <TableContainer className="">
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    stickyHeader
                                                    className="tbl-people-group"
                                                >
                                                    <TableHeaderForSorting
                                                        columns={
                                                            columns_manager_data
                                                        }
                                                        className="z-0"
                                                    />
                                                    <TableBody>
                                                        {getProfleData?.manager?.map((manager, index) => (
                                                            <>
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={index + 1}
                                                                    className="tbl-changelog mt-2"
                                                                >
                                                                    <TableCell
                                                                        align="left"
                                                                        className={`tbl-cell-group-1 border-top-group ${index === getProfleData?.manager?.length - 1 ? 'border-bottom-group' : ''}`}
                                                                    >
                                                                        <div
                                                                            className="d-flex flex-warp gap-3 align-items-center"
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            <div
                                                                                className={`icon-div-user-group d-flex justify-content-center align-items-center icons-div`}
                                                                                key={index}
                                                                            >

                                                                                <Avatar
                                                                                    aria-label="recipe"
                                                                                    className="author-avtar-creator"
                                                                                    src={manager?.thumbnail}
                                                                                >
                                                                                    {manager?.full_name?.charAt(0)}
                                                                                </Avatar>
                                                                            </div>
                                                                            <p
                                                                                className={`${manager?.status === "deleted" &&
                                                                                    "text-muted fw-700"
                                                                                    } fs-14`}
                                                                            >
                                                                                {manager?.full_name ? manager?.full_name : "---"}
                                                                            </p>

                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <div className="no-data">
                                                <div>
                                                    <Image
                                                        src={NoDataFoundImg}
                                                        className="text-center mx-auto no-data-img-2"
                                                    />
                                                </div>

                                            </div>
                                        )}

                                        <Typography variant="h5" className="mt-2 text-center">Direct Reports</Typography>
                                        {getProfleData?.direct_report?.length > 0 ? (
                                            <TableContainer className="">
                                                <Table
                                                    sx={{ minWidth: 750 }}
                                                    aria-labelledby="tableTitle"
                                                    stickyHeader
                                                    className="tbl-people-group"
                                                >
                                                    <TableHeaderForSorting
                                                        columns={
                                                            columns_manager_data
                                                        }
                                                        className="z-0"
                                                    />
                                                    <TableBody>
                                                        {getProfleData?.direct_report?.map((manager, index) => (
                                                            <>
                                                                <TableRow
                                                                    hover
                                                                    role="checkbox"
                                                                    tabIndex={-1}
                                                                    key={index + 1}
                                                                    className="tbl-changelog mt-2"
                                                                >
                                                                    <TableCell
                                                                        align="left"
                                                                        className={`tbl-cell-group-1 border-top-group ${index === getProfleData?.direct_report?.length - 1 ? 'border-bottom-group' : ''}`}
                                                                    >
                                                                        <div
                                                                            className="d-flex flex-warp gap-3 align-items-center"
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            <div
                                                                                className={`icon-div-user-group d-flex justify-content-center align-items-center icons-div`}
                                                                                key={index}
                                                                            >

                                                                                <Avatar
                                                                                    aria-label="recipe"
                                                                                    className="author-avtar-creator"
                                                                                    src={manager?.thumbnail}
                                                                                >
                                                                                    {manager?.full_name?.charAt(0)}
                                                                                </Avatar>
                                                                            </div>
                                                                            <p
                                                                                className={`${manager?.status === "deleted" &&
                                                                                    "text-muted fw-700 "
                                                                                    } fs-14`}
                                                                            >
                                                                                {manager?.full_name ? manager?.full_name : "---"}
                                                                            </p>

                                                                        </div>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        ) : (
                                            <div className="no-data">
                                                <div>
                                                    <Image
                                                        src={NoDataFoundImg}
                                                        className="text-center mx-auto no-data-img-2"
                                                    />
                                                </div>

                                            </div>
                                        )}

                                    </Paper>
                                </CustomTabPanel>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
                <Loader isLoading={loading} />
                <Toaster />
            </div>
        </>
    )
}

export default UserPeopleProfileView