import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    selectedMenuTab: 0,
    attributeID: undefined
};

const menuTab = createSlice({
    name: "menuTabDetails",
    initialState: initialData,
    reducers: {
        handleSelectedTab(state, action) {
            state.selectedMenuTab = action?.payload
        },
        setAttributeID(state, action) {
            state.attributeID = action?.payload
        }
    }
})

export const menuTabAction = menuTab.actions;
export default menuTab.reducer;