import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Image, Stack } from "react-bootstrap";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { Typography } from "@mui/material";
import { json, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../../styles/userDashboard.scss";
import {
    Box,
    Grid,
    Button,
    Tabs,
    Tab,
    Paper,
    CardHeader,
    Avatar,
    IconButton,
    Divider,
    TextField,
    Chip,
    Card,
    List,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemIcon,
    Breadcrumbs,
    Link,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFolder,
    faFile,
    faMessage,
} from "@fortawesome/free-solid-svg-icons";

import logoutHelper from "../../helpers/logoutHelper";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import api from "../../components/common/commonFunctionForApi";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";

import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "../../styles/userTypePage.scss";
import { Toast } from "../../utils/toast";

import { faXmark, faFloppyDisk, faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import DummyUser from "../../assets/images/dummy-user.jpg";
import ListItemButton from '@mui/material/ListItemButton';
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import { columns_global2_search_header } from "../../helpers/theaderColumns/globalSearch/globalSearch2";

const SearchResult = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [loading, setLoading] = useState(false);

    const [search, setSearch] = useState("");

    const [istype, setIstype] = useState(false);

    const [page, setPage] = useState(1);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [getAllCategories, setGetAllCategories] = useState([])
    const [getAllTypes, setGetAlltypes] = useState([])
    const [selectedCategories, setSelectedCategories] = useState({})
    const [type, setType] = useState('all');

    const [dataOrder, setDataOrder] = useState("asc");


    const [globalSearch, setGlobalSearch] = useState("")
    const [globalSearchData, setGlobalSearchData] = useState()
    const [globalSearchDataTabs, setGlobalSearchDataTabs] = useState([])
    const [globalSearchDataOnly, setGlobalSearchDataOnly] = useState([])
    const [globalSearchFilterData, setGlobalSearchFilterData] = useState([])
    const [openForGlobalSearch, setOpenforGobalSearch] = useState(false)

    const [isMoreData, setIsMoreData] = useState(false)

    //States for pagination
    const [totalPages, setTotalPages] = useState(0);

    const debounceTimeout = 500; // Adjust the debounce timeout as needed
    const debounceTimeoutForCatFilter = 1200; // Adjust the debounce timeout as needed

    let debounceTimer;
    const {
        open,
        selectedItem,
        nameCollapsable,
        typeCollapsable,
        drawerVariant,
        usersCollapsable,
        menuFor,
        adminAccessible
    } = useSelector((state) => state?.drawerMenu);

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);
    const userToken = localStorage.getItem("accessToken");

    function PreviousItem(props) {
        return (
            <Button
                {...props}
                startIcon={<ArrowBackIcon />}
                className="text-capitalize fs-12-pagination pagination-btns"
            >
                {"Newer Posts"}
            </Button>
        );
    }

    function NextItem(props) {
        return (
            <Button
                {...props}
                endIcon={<ArrowForwardIcon />}
                className="text-capitalize fs-12-pagination pagination-btns"
            >

                {"Previous Posts"}
            </Button>
        );
    }

    const transformCategoryData = (categories) => {
        return categories.map(category => ({
            label: category.title,
            slug: category.slug,
            id: category.id,
            options: category.categoryterm.map(term => ({
                label: term.name,
                value: term.id,
                categoryId: category.id
            }))
        }));
    };

    const handleCategoryChange = (categoryLabel, selectedOption) => {
        setSelectedCategories(prevState => ({
            ...prevState,
            [categoryLabel]: selectedOption
        }));
    };

    const transformSelectedCategories = (selectedCategories) => {
        const payload = {};

        for (const category in selectedCategories) {
            if (selectedCategories.hasOwnProperty(category)) {
                const terms = selectedCategories[category];
                const categoryId = terms.length > 0 ? terms[0].categoryId : null;

                let termValues = terms.map(term => term.value).join(',');

                if (categoryId !== null && termValues !== "") {
                    payload[category] = {
                        category_id: categoryId,
                        term_values: termValues
                    };
                }
            }
        }

        return payload;
    };

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            setPage(newPage);
            fetchGlobalSearchData(newPage, dataOrder);
        } else if (newPage < page) {
            setPage(newPage);
            fetchGlobalSearchData(newPage, dataOrder);
        }
    };

    const handleChangeDataOrdr = () => {
        let order = dataOrder === "asc" ? "desc" : "asc";
        setDataOrder(order);
        setPage(1);
        fetchGlobalSearchData(1, order);
    };

    const fetchAllCategoryData = async () => {
        try {
            setLoading(true);
            const response = await api.get(`global-search/filter`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                const categories = response?.response?.category_filter
                const groups = response?.response?.group_filter
                const types = response?.response?.type_filter
                const allCategories = transformCategoryData(categories);
                const finalGroups = groups?.map((group) => {
                    return {
                        label: group?.title,
                        value: group?.id
                    }
                })

                const finalTypes = types?.map((type) => {
                    return {
                        label: type?.name,
                        value: type?.id
                    }
                })
                finalTypes.unshift({ label: "All", value: "all" });

                setGetAllCategories(allCategories);
                setGetAlltypes(finalTypes)
                setLoading(false)
            }
        } catch (err) {
            if (err?.response?.status == 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
            setLoading(false);
        }
    }

    const fetchGlobalSearchData = async (pageNo, order) => {
        const categoryData = transformSelectedCategories(selectedCategories);
        let data = {
            search: search,
            page: pageNo,
            type_id: type,
            category_data: Object.keys(categoryData).length === 0 ? {} : JSON.stringify(categoryData), // Check if categoryData is empty
            order: order
        };
        try {
            setLoading(true)
            const response = await api.post(`global-search`, data, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success == true) {
                setGlobalSearchData(response?.response)
                setGlobalSearchDataTabs(response?.response?.Tabs)
                setGlobalSearchDataOnly(response?.response?.post?.data)
                setGlobalSearchFilterData(response?.response?.post?.data)

                setTotalPages(response?.response?.post?.meta?.last_page);

                setLoading(false)
                setOpenforGobalSearch(true)
            } else {
                setLoading(false)
            }
        } catch (err) {
            console.log("error in handleReactionAPI::", err);
            setLoading(false);

            if (err?.response?.status === 401) {
                const userToken = localStorage.getItem("accessToken");
                logoutHelper(dispatch, navigate, userToken);
            } else if (err?.response?.status === 422) {
                const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
            }
        }
    }

    useEffect(() => {
        console.log("useEffect call api from selected folder else block");
        console.log("fetchall 639")
        if (search !== "") {
            fetchGlobalSearchData(1, dataOrder)
        }
        setPage(1);
        fetchAllCategoryData()
        setSelectedCategories([])
    }, [])

    useEffect(() => {
        const { searchString, category } = location.state || {};
        if (searchString) {
            if (category) {
                setType(category);
            }

            setSearch(searchString);
        }
    }, [Location])

    useEffect(() => {
        console.log("useEffect search");
        if (search?.length >= 3) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                console.log("fetchall 656")
                setPage(1);
                fetchGlobalSearchData(1, dataOrder);
            }, debounceTimeout);
        }
        else if (search === "" && istype == true) {
            console.log("fetchall 661")
            setPage(1);
            fetchGlobalSearchData(1, dataOrder);
        }
        else {
            clearTimeout(debounceTimer);
        }

        return () => clearTimeout(debounceTimer);
    }, [search]);

    useEffect(() => {
        console.log("useEffect category");
        if (selectedCategories && Object.getOwnPropertyNames(selectedCategories)?.length > 0) {
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => {
                console.log("fetchall 680")
                if (search !== "") {
                    setPage(1);
                    fetchGlobalSearchData(1, dataOrder);
                }
            }, debounceTimeoutForCatFilter);
            return () => clearTimeout(debounceTimer);
        }
    }, [selectedCategories]);

    useEffect(() => {
        if (search !== "") {
            setPage(1);
            fetchGlobalSearchData(1, dataOrder);
        }
    }, [type])

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    <Box className="">
                        <Stack spacing={2}>
                            <Breadcrumbs
                                separator={<NavigateNextIcon fontSize="small" />}
                                aria-label="breadcrumb"
                            >
                                <Typography
                                    key="3"
                                    component="h3"
                                    color="inherit"
                                    className="breadcrumb-text-1"
                                >
                                    Search
                                </Typography>
                                <Typography
                                    key="3"
                                    component="h3"
                                    color="inherit"
                                    className="breadcrumb-text-2"
                                >
                                    {`${search}`}
                                </Typography>

                            </Breadcrumbs>
                        </Stack>
                    </Box>
                    <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
                    <Box className="">
                        <TextField
                            className="bg-white mt-5 mb-4"
                            fullWidth
                            id="outlined-basic username"
                            variant="outlined"
                            size="small"
                            name="attributeTitle"
                            value={search}
                            placeholder="Keyword Filter"
                            onChange={(e) => {
                                localStorage.removeItem("globalSearchTab")
                                setIstype(true)
                                setSearch(e.target.value);
                                if (e.target.value === "") {
                                    fetchGlobalSearchData(page, dataOrder);
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    fetchGlobalSearchData(page, dataOrder);
                                }
                            }}
                        />
                    </Box>
                    {isCollapsed == true && (getAllCategories?.length > 0 || getAllTypes?.length > 0) && (
                        <>
                            <Box className="mt-3">
                                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>


                                    {getAllCategories?.length > 0 && (
                                        <>
                                            {getAllCategories?.map((category) => (
                                                <Grid item xs={6} lg={3} md={6} sm={12} xl={3}>
                                                    <Col lg="12" className="">
                                                        <Typography className="fw-400 fs-14 dark-text ">
                                                            {category?.label}
                                                        </Typography>
                                                        <Select
                                                            closeMenuOnSelect={false}
                                                            options={category?.options}
                                                            menuPlacement="auto"
                                                            name="groupManagers"
                                                            value={selectedCategories[category.slug] || []}
                                                            placeholder={`Select Terms...`}
                                                            menuPosition="fixed"
                                                            isMulti
                                                            onChange={(selectedOption) => handleCategoryChange(category.slug, selectedOption)}
                                                            className={`muilt-select-field mt-1 `}
                                                            classNamePrefix="select"
                                                            styles={{
                                                                option: (provided, state) => {
                                                                    return {
                                                                        ...provided,
                                                                        backgroundColor: state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                        color: state.isSelected
                                                                            ? "var(--dark-color)"
                                                                            : provided.color,
                                                                    };
                                                                },
                                                            }} />
                                                    </Col>
                                                </Grid>
                                            ))}
                                        </>
                                    )}

                                    {getAllTypes?.length > 0 && (
                                        <>
                                            <Grid item xs={6} lg={3} md={6} sm={12} xl={3}>
                                                <Col lg="12" className="">
                                                    <Typography className="fw-400 fs-14 dark-text ">
                                                        Types
                                                    </Typography>
                                                    <Select
                                                        closeMenuOnSelect={true}
                                                        options={getAllTypes}
                                                        menuPlacement="auto"
                                                        name="fieldType"
                                                        value={getAllTypes.find(
                                                            (option) =>
                                                                option.value == type
                                                        )}
                                                        placeholder="Select page type..."
                                                        onChange={(selectedOption) => {
                                                            setType(selectedOption
                                                                ? selectedOption.value
                                                                : "")
                                                        }}
                                                        className="muilt-select-field mt-2 z-3 fs-14"
                                                        classNamePrefix="select"
                                                        styles={{
                                                            option: (provided, state) => {
                                                                return {
                                                                    ...provided,
                                                                    backgroundColor:
                                                                        state.isSelected
                                                                            ? "#660c605c"
                                                                            : provided.backgroundColor,
                                                                    color: state.isSelected
                                                                        ? "var(--dark-color)"
                                                                        : provided.color,
                                                                };
                                                            },
                                                        }}
                                                    />
                                                </Col>
                                            </Grid>
                                        </>
                                    )}

                                </Grid>
                            </Box>

                        </>
                    )}

                    <Divider className={`${isCollapsed == true ? "mt-4" : ""}`}>
                        <Button
                            onClick={() => setIsCollapsed((prevState) => !prevState)}
                            className="bg-white hide-option-btn d-flex gap-2"
                        >
                            {isCollapsed == false
                                ? "Show Advanced Filters"
                                : "Hide Advanced Filters"}

                            <FontAwesomeIcon
                                icon={isCollapsed == false ? faChevronDown : faChevronUp}
                                className="hide-option-btn-svg"
                            />
                        </Button>
                    </Divider>

                    <Paper sx={{ width: "100%" }} className="type-list-paper mt-4">
                        <TableContainer>
                            <Table
                                sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                stickyHeader
                            >
                                {globalSearchFilterData?.length > 0 ? (
                                    <>
                                        <TableHeaderForSorting
                                            columns={columns_global2_search_header}
                                            handleChangeDataOrdr={handleChangeDataOrdr}
                                            dataOrder={dataOrder}
                                        />
                                        <TableBody>
                                            {globalSearchFilterData?.map((row, index) => (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={index + 1}
                                                    className="cursor-pointer"
                                                    onClick={() => {
                                                        if (row?.type.toLowerCase() === "folder") {
                                                            localStorage.setItem("type-selected-name-user", row?.post_type?.type_name)
                                                            localStorage.setItem("selected-folder-item", row?.folder_id)
                                                            window.open(`/user-type/${row?.post_type?.type_name}`, '_blank');
                                                        }
                                                        else if (row?.type.toLowerCase() === "people") {
                                                            window.open(`/user-type/people/profile/view/${row?.id}`, '_blank');
                                                        }
                                                        else if (row?.type.toLowerCase() === "conversation") {
                                                            localStorage.setItem("selected-chat-room-id", row?.chat_room_id);
                                                            window.open(`/user-conversation?chat_room=${row?.chat_room_id}&chat_id=${row?.id}`, '_blank')
                                                        }
                                                        else {
                                                            localStorage.setItem("type-selected-name-user", row?.post_type?.type_name)
                                                            window.open(`/user-type/view/${row?.type}/${row?.id}`, '_blank');
                                                        }
                                                    }}
                                                >
                                                    <TableCell
                                                        align="left"
                                                        className={`tbl-body-cell`}
                                                    >
                                                        {row?.type.toLowerCase() === "folder" ? (
                                                            <div className="d-flex flex-warp gap-3 align-items-center">
                                                                <FontAwesomeIcon icon={faFolder} className="folder-tbl-cell" />
                                                                <p
                                                                    className={`folder-tbl-cell-2 ${row?.status === "deleted" ? "text-muted fw-700" : ""
                                                                        }`}
                                                                >
                                                                    {row?.name}
                                                                </p>
                                                            </div>
                                                        ) : row?.type.toLowerCase() === "people" ? (
                                                            <div className="d-flex flex-warp gap-3 align-items-center">
                                                                <Avatar
                                                                    alt={
                                                                        row?.name ||
                                                                        "Admin"
                                                                    }
                                                                    src={
                                                                        row?.profile ||
                                                                        "/static/images/avatar/1.jpg"
                                                                    }
                                                                    className="user-list-avatar"
                                                                />
                                                                <p
                                                                    className={`folder-tbl-cell-2 ${row?.status === "deleted" ? "text-muted fw-700" : ""
                                                                        }`}
                                                                >
                                                                    {row?.name}
                                                                </p>
                                                            </div>
                                                        ) : row?.type.toLowerCase() === 'conversation' ? (
                                                            <div className="d-flex flex-warp gap-3 align-items-center">
                                                                <FontAwesomeIcon icon={faMessage} className="folder-tbl-cell" />

                                                                <p
                                                                    className={`folder-tbl-cell-4 ${row?.status === "deleted" ? "text-muted fw-700" : ""
                                                                        }`}
                                                                >
                                                                    {row?.name}
                                                                </p>
                                                            </div>
                                                        ) : (
                                                            <div className="d-flex flex-warp gap-3 align-items-center">
                                                                <FontAwesomeIcon icon={faFile} className="folder-tbl-cell-3" />
                                                                <p
                                                                    className={`folder-tbl-cell-4 ${row?.status === "deleted" ? "text-muted fw-700" : ""
                                                                        }`}
                                                                >
                                                                    {row?.name}
                                                                </p>
                                                            </div>
                                                        )}

                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className="tbl-body-cell-3 "
                                                    >
                                                        {row?.last_modify}
                                                    </TableCell>
                                                    <TableCell
                                                        align="left"
                                                        className="tbl-body-cell-3 "
                                                    >
                                                        {row?.type.charAt(0).toUpperCase() + row?.type.slice(1)}
                                                    </TableCell>
                                                    <TableCell align="left" className="tbl-body-cell">
                                                        <Box>
                                                            <div className="d-flex justify-content-start align-items-center gap-2 z-n1">
                                                                <Avatar
                                                                    alt="Admin"
                                                                    src={row?.profile}
                                                                    className="author-avtar-creator"
                                                                >
                                                                    {row?.created_by?.charAt(0)}
                                                                </Avatar>
                                                                <Typography className="fw-400 fs-14">
                                                                    {row?.created_by}
                                                                </Typography>
                                                            </div>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </>
                                ) : (
                                    <div className="no-data mt-2">
                                        <div>
                                            <Image
                                                src={NoDataFoundImg}
                                                className="text-center mx-auto no-data-img mt-4"
                                            />
                                        </div>
                                        <Typography component="h5" variant="h5">
                                            No data found
                                        </Typography>
                                    </div>
                                )}
                            </Table>
                        </TableContainer>
                        <Box className="mt-4">
                            {totalPages > 1 && (
                                <Pagination
                                    count={totalPages}
                                    page={page}
                                    className="type-pagination"
                                    onChange={handleChangePage}
                                    renderItem={(item) => (
                                        <PaginationItem
                                            slots={{ previous: PreviousItem, next: NextItem }}
                                            {...item}
                                        />
                                    )}
                                />
                            )}
                        </Box>
                    </Paper>
                </Container>
                <Loader isLoading={loading} />
                <Toaster />
            </div>
        </>
    )
}

export default SearchResult