import React, { useEffect, useState } from "react";
import { Container, Button, Card, Image } from "react-bootstrap";
import {
  Typography,
  Box,
  Stack,
  Breadcrumbs,
  Divider,
  Link,
  Grid,
  Paper,
  Avatar,
  CardActions,
  Popper,
  Tabs, Tab,
  List,
  ListItem,
  ListItemText,
  TextField,
  IconButton,
  Popover,
  Fab,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import { Toast } from "../../utils/toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faArrowRight,
  faCheck,
  faThumbsUp,
  faComments,
  faAdd,
  faHeart,
  faSmile,
  faAngry,
  faDownload,
  faShare,
  faPhone,
  faEnvelopeOpen,
  faPencil
} from "@fortawesome/free-solid-svg-icons";
import "../../styles/userTypeViewPage.scss";
import InputGroup from "react-bootstrap/InputGroup";
import PropTypes from "prop-types";
import { Mention, MentionsInput } from "react-mentions";
import mentionStyle from "../../helpers/styles/dashboard/mentionStyle";
import mentionsInputStyle from "../../helpers/styles/dashboard/mentionsInputStyle";
import { styled } from "@mui/material/styles";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import CardContent from "@mui/material/CardContent";
import DummyUser from "../../assets/images/dummy-user.jpg";
import Rating from '@mui/material/Rating';
import { Toaster } from "react-hot-toast";
import apiFull from "../../components/common/commonFunctionForFullApi";
import Loader from "../../components/common/loader";
import ModalEngagement from "../../components/common/modal/ModalEngagement";
import { onlyDateFormat } from "../../helpers/DateTimeFormat/dateTimeFormat";
import PreviewLink from "../../components/common/dashboard/PreviewLink";

const processedHTML = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
  return tempDiv;
};

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="">{children}</Box>}
    </div>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const UserTypeViewPage = () => {
  let { type, id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    open,
    adminAccessible
  } = useSelector((state) => state?.drawerMenu);

  const user = localStorage.getItem("user");
  const loggedUser = JSON.parse(user);
  const userToken = localStorage.getItem("accessToken");

  const [loading, setLoading] = useState(false);
  const [isWarningMessage, setIsWarningMessage] = useState(false);

  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [getPostData, setGetPostData] = useState();

  const [anchorElForReaction, setAnchorElForReaction] = useState(null);
  const [currentPostId, setCurrentPostId] = useState(null);

  const [anchorElForReplyReaction, setAnchorElForReplyReaction] = useState(null);
  const [currentPostIdForReply, setCurrentPostIdForReply] = useState(null);

  const openForReaction = Boolean(anchorElForReaction);
  const openForReactionReply = Boolean(anchorElForReplyReaction)

  const [getAllPost, setGetAllPost] = useState([]);
  const [value, setValue] = useState(0);
  const [fetchAll, setFetchAll] = useState(false)

  const [getMentionUsers, setGetMentionUsers] = useState([])

  const [isSelectingSuggestion, setIsSelectingSuggestion] = useState(false);

  const [ackQuestions, setAckQuestions] = useState([])
  const [openAckQuePopup, setOpenAckQuePopup] = useState(false);
  const [postIdForAckQue, setPostIdForAckQue] = useState()

  const [isPopupUserExist, setIsPopupUserExist] = useState(false)
  const [popupUserDetails, setPopupUserDetails] = useState()
  const [anchorElForPopupUser, setAnchorElForPopupUser] = useState(null)
  const [ratingValue, setRattingValue] = useState()

  const [timeoutId, setTimeoutId] = useState(null);

  //state for folder breadcrumbs
  const [postFolder, setPostFolder] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  //state for edit post functionality
  const [hasPostEditPermission, setPostEditPermission] = useState(false);

  const openForPopupUser = Boolean(anchorElForPopupUser)

  const idPopupUser = openForPopupUser ? 'simple-popover' : undefined;

  const [openEngagementModal, setOpenEngagementModal] = useState(false);
  const [enagagementModalData, setEngagementModalData] = useState([]);

  const handleEngagementModalApi = async (id) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(
        `user/post/engagement/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        console.log(response?.response);
        setEngagementModalData([response?.response]);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
      }
    } catch (err) {
      console.log("error in fetch post engagement api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);
          navigate("/");
        } else if (
          loggedUser?.user_type === "admin" &&
          adminAccessible == true
        ) {
          localStorage.setItem("last-redirect-page-admin-user", `/`);
          navigate(`/`);
        }
      }
    }
  }

  const handleEngagementModalOpen = async () => {
    console.log("handle enagagement modal::", id);
    setLoading(true);
    await handleEngagementModalApi(id);
    setOpenEngagementModal(true);
    setLoading(false);
  }

  const handleCommentEngagementModalApi = async (id) => {
    try {
      setLoading(true);
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(
        `user/comment/engagement/${id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        setEngagementModalData([response?.response]);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.warning(response?.message);
      }
    } catch (err) {
      console.log("error in fetch post engagement api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);
          navigate("/");
        } else if (
          loggedUser?.user_type === "admin" &&
          adminAccessible == true
        ) {
          localStorage.setItem("last-redirect-page-admin-user", `/`);
          navigate(`/`);
        }
      }
    }
  }

  const handleCommentEngagementModalOpen = async (comment_id) => {
    console.log("handle commnet enagagement modal::", comment_id)
    setLoading(true);
    console.log("after loader::", comment_id);
    await handleCommentEngagementModalApi(comment_id);
    setOpenEngagementModal(true);
    setLoading(false);
  }

  const handleEngagementModalClose = () => {
    setOpenEngagementModal(false);
    setEngagementModalData([]);
  }


  const handleChange = (event, newValue) => {
    setValue(newValue);

  };

  const handleDownload = async (file) => {
    // window.open(file, '_blank'); // Open the file URL in a new tab
    try {

      setIsSuccessMessage(true);
      setSuccessMessage(
        "Download Started..., Will let you know when completes"
      );
      Toast.success(
        "Download Started..., Will let you know when completes"
      );

      // Make API call to download file
      const response = await apiFull.get(
        `user/download/file/${file?.id}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            "Content-Type": "application/pdf",
          },
          responseType: "blob",
        }
      );
      console.log(response);
      console.log(response.data.type);
      console.log(response.headers);
      // Check if response status is OK
      if (response.status == 200) {
        const contentType = response.data.type;
        let filename = file?.file_title + '.' + file?.file_type || file?.original_name; // Fallback filename
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: contentType });

        // Create a download link and trigger a click
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename ?? "testfile.pdf");
        document.body.appendChild(link)
          ;
        link.click();

        //clean the dom
        document.body.removeChild(link)
          ;
        URL.revokeObjectURL(url);

        setIsSuccessMessage(true);
        setSuccessMessage("Download Complete");
        Toast.success("Download Complete");
      } else {
        // Handle server error response
        setIsWarningMessage(true);
        setWarningMessage(
          response.data?.message || "An error occurred"
        );
        Toast.warning(response.data?.message || "An error occurred");

        // Navigate based on user type
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);
          navigate(`/`);
        } else if (
          loggedUser?.user_type === "admin" &&
          adminAccessible === true
        ) {
          localStorage.setItem("last-redirect-page-admin-user", `/`);
          navigate(`/`);
        }
      }
    } catch (err) {
      console.error("Error fetching file:", err);

      // Handle specific HTTP errors
      if (err?.response?.status === 401) {
        logoutHelper(dispatch, navigate, userToken);
      } else if (err?.response?.status === 403) {
        setIsWarningMessage(true);
        setWarningMessage(
          err?.response?.data?.[0]?.message || "Forbidden"
        );
        Toast.warning(err?.response?.data?.[0]?.message || "Forbidden");
        if (loggedUser?.user_type === "user") {
          localStorage.setItem(
            "last-redirect-page-user",
            `/dashboard`
          );
          navigate(`/dashboard`);
        } else if (
          loggedUser?.user_type === "admin" &&
          adminAccessible === true
        ) {
          localStorage.setItem(
            "last-redirect-page-admin-user",
            `/dashboard`
          );
          navigate(`/dashboard`);
        }
      } else if (err?.response?.status === 429) {
        Toast.error("Too many attempts; please wait for 2 minutes.");
      } else {
        Toast.error("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = async (file_path, type, title) => {

    try {
      // FileSaver.saveAs(file_path, title + '.' + type);
      fetch(file_path)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.download = title + '.' + type || "downloaded-file";
          document.body.appendChild(link);

          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error fetching the file:", error);
        });

    } catch (err) {
      console.error("Error While file download::", err);
      Toast.error("Error while file download");
    }
  };

  const getMimeType = (extension) => {
    const mimeTypes = {
      'png': 'image/png',
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'pdf': 'application/pdf',
      'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'mp4': 'video/mp4',
      'mp3': 'audio/mpeg'
    };
    return mimeTypes[extension] || 'application/octet-stream';
  };

  const handleCloseForAckQue = () => {
    setOpenAckQuePopup(false);
    setAckQuestions([])
    setPostIdForAckQue("")
  };

  const handleInputChangeForAckQuestions = (event, index) => {
    const { value } = event.target;
    setAckQuestions((prevQuestions) =>
      prevQuestions.map((que, i) =>
        i === index ? { ...que, ans: value } : que
      )
    );
  };

  const handlePopoverCloseForMentionUser = () => {
    setAnchorElForPopupUser(null);
    setPopupUserDetails()
  };



  const generateBreadcrumbs = (folder) => {
    const breadcrumbs = [];

    const traverseFolder = (currentFolder) => {
      if (currentFolder?.parent_folder?.length > 0) {
        traverseFolder(currentFolder.parent_folder[0]);
      }
      breadcrumbs.push(currentFolder);
    };

    traverseFolder(folder);
    return breadcrumbs;
  };


  useEffect(() => {
    if (postFolder) {
      const generatedBreadcrumbs = generateBreadcrumbs(postFolder);
      setBreadcrumbs(generatedBreadcrumbs);
    }
  }, [postFolder])


  const fetchPostDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`user/post/${parseInt(id)}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setGetPostData(response?.response);
        setRattingValue(response?.response?.user_rating?.rating);
        setPostEditPermission(response?.response?.has_edit_permission);
        if (response?.response?.post_folder && response?.response?.post_folder.length > 0) {
          setPostFolder(response?.response?.post_folder[0]);
        }
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/`);
          navigate(`/`);
        }
        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
          localStorage.setItem("last-redirect-page-admin-user", `/`)
          navigate(`/`);
        }

      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        if (loggedUser?.user_type === "user") {
          localStorage.setItem("last-redirect-page-user", `/dashboard`);
          navigate(`/dashboard`);
        }
        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
          localStorage.setItem("last-redirect-page-admin-user", `/dashboard`)
          navigate(`/dashboard`);
        }
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  // Function to toggle like status
  const toggleLike = (postId, user_has_liked) => {
    setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post.id == postId
          ? {
            ...post,
            user_has_liked: post.user_has_liked === "yes" ? "no" : "yes",
            like_count:
              post.user_has_liked === "yes"
                ? post.like_count - 1
                : post.like_count + 1,
          }
          : post
      )
    );
    handleLikePostAPI(postId, user_has_liked === "yes" ? "no" : "yes");
  };

  const toggleReaction = async (postId, reaction, isLiked) => {
    let newUserLikedData;

    setGetPostData((prevPost) => {
      let newUserLiked = prevPost?.user_has_liked;
      let newReaction = prevPost.reaction;
      let newLikeCount = prevPost.like_count;

      if (newUserLiked === "no") {
        // No reaction was selected before, adding a new reaction
        newUserLiked = "yes";
        newReaction = reaction;
        newLikeCount += 1; // Increase like count when adding a new reaction
      } else if (newReaction !== reaction && isLiked == false) {
        // Remove current reaction
        newUserLiked = "no";
        newReaction = "";
        newLikeCount -= 1;
      } else if (newReaction !== reaction && isLiked == true) {
        // Switching to a different reaction
        newReaction = reaction;
        // Like count remains the same since only the reaction is changing
      } else {
        // Removing the current reaction
        newUserLiked = "no";
        newReaction = "no";
        newLikeCount -= 1; // Decrease like count when removing the reaction
      }

      handleLikePostAPI(id, newUserLiked, reaction); // Update the API call with the current post ID

      return {
        ...prevPost,
        user_has_liked: newUserLiked,
        reaction: newReaction,
        like_count: newLikeCount,
      };
    });

    // await handleLikePostAPI(postId, newUserLikedData, reaction);
    await setAnchorElForReaction(null); // Close the popover after selecting a reaction
  };

  const [anchorElForPostDetail, setAnchorElForPostDetail] = useState(null);
  const [postDetailPopoverContent, setPostDetailPopoverContent] = useState([]);

  const handlePostDetailPopoverOpen = (event, remainingValues) => {
    setAnchorElForPostDetail(event.currentTarget);
    setPostDetailPopoverContent(remainingValues);
  }

  const handlePostDetailPopoverClose = () => {
    setAnchorElForPostDetail(null);
    setPostDetailPopoverContent([]);
  };

  const openPostDetailPopover = Boolean(anchorElForPostDetail);
  const postdetailPopoverid = open ? 'popover-list' : undefined;

  const handlePopoverOpen = (event, postId) => {
    handlePopoverCloseForReplyLike()
    setAnchorElForReaction(event.currentTarget);
    setCurrentPostId(postId);
  };

  const handlePopoverClose = () => {
    setAnchorElForReaction(null);
  };

  const handlePopoverOpenForReplyLike = (event, postId, commentID) => {
    handlePopoverClose()
    setCurrentPostId(postId);
    setCurrentPostIdForReply(commentID)
    setAnchorElForReplyReaction(event.currentTarget);

  };

  const handlePopoverCloseForReplyLike = () => {
    setAnchorElForReplyReaction(null);
  };

  const toggleLikeForComments = (postId, user_has_liked, commentId) => {
    setGetAllPost((prevPosts) =>
      prevPosts.map((post) =>
        post.id === postId
          ? {
            ...post,
            comments: post.comments.map((comment) =>
              comment.id === commentId
                ? {
                  ...comment,
                  has_user_liked_comment: comment.has_user_liked_comment === "yes" ? "no" : "yes",
                  comment_likes_count:
                    comment.has_user_liked_comment === "yes"
                      ? comment.comment_likes_count - 1
                      : comment.comment_likes_count + 1,
                }
                : comment
            ),
          }
          : post
      )
    );
    handleLikePostForCommentsAPI(commentId, user_has_liked === "yes" ? "no" : "yes", postId);
  };

  const toggleLikeForCommentsReaction = async (postId, reaction, commentId, isLiked) => {
    let newUserLikedData;

    await setGetPostData((prevPost) => {
      return {
        ...prevPost,
        comments: prevPost.comments.map((comment) => {
          if (comment.id === commentId) {
            let newUserLiked = comment?.has_user_liked_comment;
            let newReaction = comment.reaction;
            let newLikeCount = comment.comment_likes_count;

            if (newUserLiked === "no") {
              // No reaction was selected before, adding a new reaction
              newUserLiked = "yes";
              newReaction = reaction;
              newLikeCount += 1; // Increase like count when adding a new reaction
            } else if (newReaction !== reaction && isLiked === false) {
              // Removing the current reaction
              newUserLiked = "no";
              newReaction = "";
              newLikeCount -= 1;
            } else if (newReaction !== reaction && isLiked === true) {
              // Switching to a different reaction
              newReaction = reaction;
              // Like count remains the same since only the reaction is changing
            } else {
              // Removing the current reaction
              newUserLiked = "no";
              newReaction = "";
              newLikeCount -= 1; // Decrease like count when removing the reaction
            }
            newUserLikedData = newUserLiked
            return {
              ...comment,
              has_user_liked_comment: newUserLiked,
              reaction: newReaction,
              comment_likes_count: newLikeCount,
            };
          }
          return comment;
        }),
      };
    });
    await handleLikePostForCommentsAPI(commentId, newUserLikedData, id, reaction);
    await handlePopoverCloseForReplyLike()
  };

  const handleCommentToggle = (postId) => {
    setGetPostData((prevPost) => ({
      ...prevPost,
      commentToggle: !prevPost.commentToggle || false, // Initialize to false if not present
    }));
  };

  const handleCommentReplyToggle = (postId, commentId) => {
    const updatedPostData = {
      ...getPostData,
      comments: getPostData.comments.map((comment) =>
        comment.id === commentId
          ? {
            ...comment,
            commentReplyToggle: !comment.commentReplyToggle,
          }
          : comment
      ),
    };

    // Update the state with the modified post data
    setGetPostData(updatedPostData);
  };

  const handleCommentText = (e, postId) => {

    const regex = /@(\w{1,})$/;
    if (!isSelectingSuggestion && regex.test(e)) {
      const cleanedText = e.match(regex)[1]; // Extract the matched text without @
      fetchMentionUsers(cleanedText);
    }

    setGetPostData((prevPost) => ({
      ...prevPost,
      commentText: e, // Initialize to false if not present
    }));

    setIsSelectingSuggestion(false); // Reset the flag after setting the text
  };

  const handleCommentReplyText = (e, postId, commentId) => {
    const regex = /@(\w{1,})$/;
    if (!isSelectingSuggestion && regex.test(e)) {
      const cleanedText = e.match(regex)[1]; // Extract the matched text without @
      fetchMentionUsers(cleanedText);
    }

    const updatedPostData = {
      ...getPostData,
      comments: getPostData.comments.map((comment) =>
        comment.id === commentId
          ? {
            ...comment,
            commentReplyText: e,
          }
          : comment
      ),
    };

    // Update the state with the modified post data
    setGetPostData(updatedPostData);
    setIsSelectingSuggestion(false); // Reset the flag after setting the text

  }

  const handleAddComments = async (postId, data) => {
    await handleCommentToggle(postId)
    let newData = {
      id: data?.id,
      post_id: data?.post_id,
      user_id: data?.user_id,
      comment: data?.comment,
      is_parent: data?.is_parent,
      parent_id: data?.parent_id,
      created_at: data?.created_at,
      updated_at: data?.updated_at,
      replies_count: data?.replies_count,
      comment_likes_count: data?.comment_likes_count,
      has_user_liked_comment: data?.has_user_liked_comment,
      reaction: data?.reaction,
      user_reactions: data?.user_reactions,
      user: {
        id: data?.user?.id,
        name: data?.user?.name,
        thumbnail: data?.user?.thumbnail
      },
      replies: data?.replies

    }

    await setGetPostData((prevPostData) => ({
      ...prevPostData,
      comment_count: data?.comment_count,
      parent_comment_count: data?.parent_comment_count,
      comments: [newData, ...prevPostData?.comments],
    }));
  }

  const handleAppendReplyComments = async (postId, commentId, replyContent) => {
    await handleCommentReplyToggle(postId, commentId);

    // Update post data (getPostData) with the new reply
    setGetPostData((prevPostData) => ({
      ...prevPostData,
      comments: prevPostData.comments.map((comment) =>
        comment.id === commentId
          ? {
            ...comment,
            replies: [
              {
                id: replyContent?.id,
                post_id: replyContent?.post_id,
                user_id: replyContent?.user_id,
                comment: replyContent?.comment,
                is_parent: replyContent?.is_parent,
                parent_id: replyContent?.parent_id,
                created_at: replyContent?.created_at,
                user: {
                  id: replyContent?.user?.id,
                  name: replyContent?.user?.name,
                  thumbnail: replyContent?.user?.thumbnail,
                },
              },
              ...comment.replies, // Spread existing replies
            ],
            replies_count: comment.replies_count + 1,
          }
          : comment
      ),
    }));
  }

  const handlePopoverOpenForMentionUser = (event, id, commentID) => {
    handlePopoverClose()
    setAnchorElForPopupUser(event?.target);
    fetchMentionUserAPI(id)
  };

  const appendExtraComments = (postId, data, comment_page) => {
    setLoading(true);
    let updatedComments;
    if (getPostData?.comment_page == 0) {
      updatedComments = data
    } else {
      updatedComments = Array.isArray(data) ? [...getPostData.comments, ...data] : [...getPostData.comments, data];

    }
    setGetPostData((prevPostData) => ({
      ...prevPostData,
      comments: updatedComments,
      comment_page: comment_page,
    }));
    setLoading(false);
  };

  const appendPostLikeData = (postId, data) => {
    setLoading(true);
    setGetPostData((prevPostData) => ({
      ...prevPostData,
      like_count: data?.like_count,
      reaction: data?.reaction,
      reactions: data?.reactions,
      user_has_liked: data?.user_has_liked
    }));
    setLoading(false);
  };

  const appendCommentReplyPostLikeData = (postId, commentId, data) => {
    setLoading(true);

    setGetPostData((prevPostData) => ({
      ...prevPostData,
      comments: prevPostData.comments.map((comment) =>
        comment.id === commentId
          ? {
            ...comment,
            comment_likes_count: data?.comment_likes_count,
            has_user_liked_comment: data?.has_user_liked_comment,
            reaction: data?.reaction,
            user_reactions: data?.user_reactions
          }
          : comment
      ),
    }));
    setLoading(false);
  };

  const handleLikePostAPI = async (id, newUserLiked, reaction) => {

    let data = {
      like: newUserLiked,
      reaction: reaction
    };
    try {
      setLoading(false);
      const response = await api.post(`user/post/like/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (fetchAll == true) {
          appendPostLikeData(id, response?.response?.data[0])
        } else {
          fetchPostDetails();
        }
        setLoading(false);
        handlePopoverClose()
      } else {
        handlePopoverClose()
        setLoading(false);
        fetchPostDetails();

        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);
        setIsWarningMessage(true);
        handlePopoverClose()

        setWarningMessage(err?.response?.data?.errors?.image);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const handleLikePostForCommentsAPI = async (id, newUserLiked, post_id, reaction) => {
    let data = {
      like: newUserLiked,
      post_id: post_id,
      reaction: reaction

    };
    try {
      setLoading(false);
      const response = await api.post(`user/post/comment-like/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (fetchAll == true) {
          appendCommentReplyPostLikeData(post_id, id, response?.response?.data[0])
        } else {
          fetchPostDetails();
        }
        setLoading(false);
      } else {
        setLoading(false);
        fetchPostDetails();

        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data?.errors?.image);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchMentionUserAPI = async (id) => {
    try {
      // setLoading(true);
      const response = await api.get(`user/mention/${id}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false);
        setPopupUserDetails(response?.response)
        setIsPopupUserExist(true)

      } else {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        setIsPopupUserExist(false)
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data?.errors?.image);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const handleCommentsAPI = async (id, isParent) => {
    let data = {
      comment: getPostData?.commentText,
      is_parent: isParent,
    };
    try {
      setLoading(true);
      const response = await api.post(`user/post/comment/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (fetchAll == true) {
          handleAddComments(id, response?.response?.data[0])
        } else {
          fetchPostDetails();
        }
        setLoading(false);
      } else {
        setLoading(false);
        fetchPostDetails();
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data?.errors?.image);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const handleCommentsReplyAPI = async (id, isParent, commentId) => {
    const postData = getAllPost?.filter((post) => post?.id === id);
    let commentData;
    if (postData) {
      commentData = getPostData?.comments?.find((comment) => comment?.id === commentId);
    } else {
      console.log("Post not found");
    }


    let data = {
      comment: commentData?.commentReplyText,
      is_parent: isParent,
      parent_id: commentId
    };
    try {
      setLoading(true);
      const response = await api.post(`user/post/comment/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (fetchAll == true) {
          handleAppendReplyComments(postData[0]?.id, commentId, response?.response)
        } else {
          fetchPostDetails();
        }
        setLoading(false);
      } else {
        setLoading(false);
        fetchPostDetails();
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data?.errors?.image);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchLoadMoreCommentsAPI = async (id) => {
    try {
      setLoading(true);
      const response = await api.get(`user/post/get-comment/${id}?page=${getPostData?.comment_page + 1}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false);
        const allComments = response?.response?.data;
        appendExtraComments(id, allComments, getPostData?.comment_page + 1);

      } else {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data?.errors?.image);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps3(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const renderCommentText = (text) => {
    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
    const parts = text.split(mentionRegex);

    return parts.map((part, index) => {
      if (index % 3 === 1) {
        // Mention text
        const mentionId = parts[index + 1];
        return (
          <span key={index} className="fs-14-bold cursor-pointer" id={`${mentionId} `} aria-describedby={idPopupUser} onMouseEnter={(e) => {
            const id = setTimeout(() => {
              handlePopoverOpenForMentionUser(e, mentionId);
            }, 1000);
            setTimeoutId(id);
          }}
            onMouseLeave={() => {
              if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
                // handlePopoverCloseForMentionUser()
              }
            }}
          >
            {part}
          </span>
        );
      } else if (index % 3 === 2) {
        // Mention identifier (we skip it)
        return null;
      } else {
        // Normal text
        return (
          <span key={index} className="fs-14">
            {part}
          </span>
        );
      }
    });
  };

  const renderUpdateContent = (text) => {
    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
    const urlRegex = /((https?:\/\/|www\.)[^\s]+)/g;

    // Combine both regex patterns to match URLs and mentions
    const combinedRegex = new RegExp(`${mentionRegex.source}|${urlRegex.source}`, 'g');
    const matches = text.matchAll(combinedRegex);

    const elements = [];
    let lastIndex = 0;

    for (const match of matches) {
      const matchIndex = match.index;

      // Append any text before the match as regular text
      if (lastIndex < matchIndex) {
        elements.push(<span key={lastIndex}>{text.slice(lastIndex, matchIndex)}</span>);
      }

      // Check if match is a mention
      if (match[1] && match[2]) {
        const mentionText = match[1];
        const mentionId = match[2];
        elements.push(
          <span
            key={matchIndex}
            className="fw-bolder cursor-pointer"
            id={`update-${mentionId}`}
            aria-describedby={idPopupUser}
            onMouseEnter={(e) => {
              const id = setTimeout(() => {
                handlePopoverOpenForMentionUser(e, mentionId);
              }, 1000);
              setTimeoutId(id);
            }}
            onMouseLeave={() => {
              if (timeoutId) {
                clearTimeout(timeoutId);
                setTimeoutId(null);
              }
            }}
          >
            {mentionText}
          </span>
        );
      }
      // Check if match is a URL
      else if (match[0]) {
        const url = match[0];
        const href = url.startsWith('www.') ? `https://${url}` : url;
        elements.push(
          <a
            key={matchIndex}
            href={href}
            className="text-decoration-none color-inherit"
            target="_blank"
            rel="noopener noreferrer"
          >
            {url}
          </a>
        );
      }

      // Update lastIndex to the end of the current match
      lastIndex = matchIndex + match[0].length;
    }

    // Append any remaining text after the last match as regular text
    if (lastIndex < text.length) {
      elements.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
    }

    return elements;
  };

  const renderTitleContent = (text) => {
    const mentionRegex = /@\[(.*?)\]\((.*?)\)/g;
    const parts = text.split(mentionRegex);

    return parts.map((part, index) => {
      if (index % 3 === 1) {
        // Mention text
        const mentionId = parts[index + 1];
        return (
          <span key={index}>
            {part}
          </span>
        );
      } else if (index % 3 === 2) {
        // Mention identifier (we skip it)
        return null;
      } else {
        // Normal text
        return (
          <span key={index}>
            {part}
          </span>
        );
      }
    });
  };

  const handleCheckboxChange = async () => {
    try {
      setLoading(true);
      const response = await api.post(
        `/user/post/acknowledge/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        Toast.success(response?.message);
        setLoading(false);
        fetchPostDetails();
      } else {
        setLoading(false);
        Toast.error(response?.message)
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      setLoading(false);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message)
    }
  };

  const handleAckQuestionsAPI = async () => {
    let data = {
      answer: ackQuestions?.map((data) => {
        return {
          id: data?.id,
          answer: data?.ans
        }
      })
    }
    try {
      setLoading(true);
      const response = await api.post(
        `/user/acknowledge/answer/${id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        Toast.success(response?.message);
        setLoading(false);
        fetchPostDetails();
        handleCloseForAckQue()
        setPostIdForAckQue()
      } else {
        setLoading(false);
        Toast.error(response?.message || response?.response)
      }
    } catch (err) {
      console.log("error in acknowledge user::", err);
      const errors = err?.response?.data?.errors;

      setLoading(false);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      Toast.error(err?.response?.data?.message)

      if (errors) {
        const errorValues = Object.values(errors);
        if (errorValues.length > 0) {
          Toast.error(errorValues[0]);
        }
      }
    }
  }

  const fetchMentionUsers = async (text) => {
    try {
      setLoading(true);
      const response = await api.get(`user/suggestion/user-list?search=${text}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false);
        const allUsers = response?.response?.map((data) => {
          return {
            id: data?.id,
            display: `${data?.name} ${data?.last_name}`,
            photo: data?.thumbnail,
            job_title: data?.job_title,
            full_name: data?.full_name
          }
        })
        setGetMentionUsers(allUsers)
      } else {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.error(response?.message);
      }

    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data?.errors?.image);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = (event.clipboardData || window.clipboardData).getData('text');

    // Optionally, you can process the pasted text here if needed.
    const sanitizedText = paste.replace(/[@]/g, '@ '); // Example: add a space after @

    document.execCommand('insertText', false, sanitizedText);
  };

  const handleRatingAPI = async (value) => {
    let data = {
      rating: value
    }
    try {
      setLoading(true);
      const response = await api.post(`user/rating/store/${id}`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        fetchPostDetails()
        setLoading(false);

      } else {
        console.log("response::", response)
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.error(response?.message);
        Toast.error(response?.response)
      }

    } catch (err) {
      console.log("error in ctreate news api::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 422) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data?.errors?.image);
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  const location = useLocation();
  const [targetCommentId, setTargetCommentId] = useState(null);
  const [isTargetCommentFound, setIsTargetCommentFound] = useState(false);

  // Extract comment_id from the URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const commentId = searchParams.get('comment');
    if (commentId) {
      setTargetCommentId(commentId);  // Set the target comment ID
    }
  }, [location]);

  useEffect(() => {

    async function fetchTargetComment() {
      const loadCommentsUntilTargetFound = async (postId) => {
        let isTargetCommentFound = false;
        let page = 0;
        while (!isTargetCommentFound) {
          let result = await fetchTargetCommentApi(postId, page);

          if (result === true) {
            isTargetCommentFound = true;
          } else if (result === false) {
            Toast.error("No comment found");
            break;
          }
          page++;
        }
      };

      if (targetCommentId) {
        setLoading(true);
        await loadCommentsUntilTargetFound(id);
        setLoading(false);
      }
    }

    fetchTargetComment()

    return () => {
      setLoading(false);
    };
  }, [targetCommentId]);

  //Disable scroll while loading data
  useEffect(() => {
    if (loading) {
      // Disable scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [loading]);

  const fetchTargetCommentApi = async (id, page) => {
    try {
      setLoading(true);
      let response = "";
      response = await api.get(`user/post/get-comment/${id}?page=${page + 1}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setLoading(false);
        const allComments = response?.response?.data;

        setGetPostData((prevPostData) => ({
          ...prevPostData,
          comments: [
            ...(prevPostData?.comments || []),
            ...allComments.filter(newComment =>
              !(prevPostData?.comments || []).some(existingComment => existingComment.id === newComment.id)
            ) // Add only the new comments that don't already exist
          ],
          comment_page: page + 1,
        }));

        const commentExists = findCommentById(allComments, targetCommentId);

        if (commentExists) {
          setIsTargetCommentFound(true);
          return true;
        }

        if ((response?.response?.meta?.last_page + 1) < page + 1) {
          return false;
        }

      } else {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("error in fetchTargetCommentApi api::", err);
      setLoading(false);

      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };


  /**
   * This function recursively checks if the target comment ID exists.
   * It searches through both the top-level comments and their replies.
   * 
   * @param {Object[]} comments - Array of comment objects, each containing an id and optionally replies.
   * @param {number} targetId - The ID of the comment or reply to search for.
   * @returns {boolean} - Returns true if the targetId is found, otherwise false.
   */
  const findCommentById = (comments, targetId) => {

    for (const comment of comments) {
      // Check the comment id in parent comment
      if (comment.id == targetId) {
        return true;
      }

      // Check the comment id exist in reply
      if (comment.replies && comment.replies.length > 0) {
        const replyFound = findCommentById(comment.replies, targetId);
        if (replyFound) {
          return true;
        }
      }
    }

    // If no matching comment or reply was found
    return false;
  };

  useEffect(() => {
    const ScrollToComment = () => {
      const commentElement = document.getElementById(`comment-${targetCommentId}`);
      if (commentElement) {
        // Scroll to the target comment
        commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

        // Add blink effect class
        commentElement.classList.add('blink-effect');

        // Remove the blink effect after 3 seconds
        setTimeout(() => {
          commentElement.classList.remove('blink-effect');
        }, 3000);

        // Reset target comment after scroll
        setTargetCommentId(null);
      }
    };
    if (targetCommentId && isTargetCommentFound) {
      ScrollToComment();
    }
  }, [getPostData])

  useEffect(() => {
    const apiCall = async () => {
      if (parseInt(id)) {
        await fetchPostDetails();
      }
    };
    apiCall();
  }, [parseInt(id)]);


  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        {hasPostEditPermission && (type !== 'Update') && (
          <div className="fab-container">
            <Fab aria-label="edit" className="custom-fab" onClick={() => {
              if (loggedUser?.user_type === "user") {
                localStorage.setItem(
                  "type-selected-name-user",
                  getPostData?.type?.name
                );
                localStorage.setItem(
                  "type-selected-id-user",
                  getPostData?.type?.id
                );
                localStorage.setItem(
                  "type-selected-slug-user",
                  getPostData?.type?.slug
                );
                localStorage.setItem("last-redirect-page-user", `/user-type/create-content/edit/${id}`);
                navigate(`/user-type/create-content/edit/${id}`);

              }
              else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                localStorage.setItem(
                  "type-selected-name-user",
                  getPostData?.type?.name
                );
                localStorage.setItem(
                  "type-selected-id-user",
                  getPostData?.type?.id
                );
                localStorage.setItem(
                  "type-selected-slug-user",
                  getPostData?.type?.slug
                );
                localStorage.setItem("last-redirect-page-admin-user", `/user-type/create-content/edit/${id}`)
                navigate(`/user-type/create-content/edit/${id}`);

              } else {
                localStorage.setItem("type-selected-name", getPostData?.type?.name);
                localStorage.setItem("type-selected-id", getPostData?.type?.id);
                localStorage.setItem("type-selected-slug", getPostData?.type?.slug);
                localStorage.setItem("last-redirect-page-admin", `/type/edit/${getPostData?.type?.slug}/${id}`)
                navigate(`/type/edit/${getPostData?.type?.slug}/${id}`)
              }
            }}>
              <FontAwesomeIcon color="#FFFFFF" icon={faPencil} />
            </Fab>
          </div>
        )}

        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          <Box className="mb-5">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                {type !== 'Update' && (
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    onClick={() => {
                      localStorage.setItem("type-selected-name-user", getPostData?.type?.name)
                      localStorage.setItem("type-selected-slug-user", getPostData?.type?.slug)
                      localStorage.setItem("type-selected-id-user", getPostData?.type?.id)

                      if (breadcrumbs.length > 0) {
                        localStorage.setItem("selected-folder-item", 0);
                      }

                      if (loggedUser?.user_type === "user") {
                        localStorage.setItem("last-redirect-page-user", `/user-type/${getPostData?.type?.slug}`);
                        navigate(`/user-type/${getPostData?.type?.slug}`);
                      }
                      else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                        localStorage.setItem("last-redirect-page-admin-user", `/user-type/${getPostData?.type?.slug}`)
                        navigate(`/user-type/${getPostData?.type?.slug}`);
                      }
                    }}
                    className="cursor-pointer"
                  >
                    {type}
                  </Link>
                )}
                {breadcrumbs.map((crumb, index) => (
                  <Link
                    underline="hover"
                    key={crumb.id}
                    color="inherit"
                    href="/"
                    component="h3"
                    onClick={() => {
                      localStorage.setItem("type-selected-name-user", crumb.type?.name);
                      localStorage.setItem("type-selected-slug-user", crumb.type?.slug);
                      localStorage.setItem("type-selected-id-user", crumb.type?.id);

                      localStorage.setItem("selected-folder-item", crumb.id);
                      localStorage.setItem("selected-folder-type", JSON.stringify(crumb?.type))

                      if (loggedUser?.user_type === "user") {
                        localStorage.setItem("last-redirect-page-user", `/user-type/${crumb.type?.slug}`);
                        navigate(`/user-type/${crumb.type?.slug}`);
                      } else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                        localStorage.setItem("last-redirect-page-admin-user", `/user-type/${crumb.type?.slug}`);
                        navigate(`/user-type/${crumb.type?.slug}`);
                      }
                    }}
                    className="cursor-pointer"
                  >
                    {crumb.name}
                  </Link>
                ))}
                <Typography key="3" component="h3" color="text.primary">
                  {getPostData?.type?.slug === 'update' ? renderTitleContent(getPostData?.title) : getPostData?.title}
                </Typography>
              </Breadcrumbs>
            </Stack>
            <Divider className="mt-2" style={{ background: "#EEEEEE" }} />
          </Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            className="main-grid-post"
          >
            <Grid
              item
              lg={8}
              sm={12}
              md={12}
              xl={8}
              xs={12}
              className="main-grid-1 pt-0"
            >
              <div className="p-2">
                {type === 'Update' ? (
                  <>
                    {getPostData?.image !== "" && (
                      <div className="news-imag-div">
                        <Image src={getPostData?.image} />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="news-imag-div">
                    <Image src={getPostData?.image} />
                  </div>
                )}

                <div className="p-3" style={{ backgroundColor: "#FFFFFF" }}>
                  {
                    type !== "Update" && (
                      <div className="mt-4 mb-3 p-2">
                        <h3 className="view-news-short-desc text-break">
                          {getPostData?.short_summary}
                        </h3>
                      </div>
                    )
                  }

                  <div className="mt-2 mb-3 p-2">
                    {
                      getPostData?.type?.slug === 'update' ? (
                        <Typography
                          variant="body1"
                          component="p"
                          className={`view-news-content text-break ${type === 'Update' ? 'text-center' : ''}`}
                        > {renderUpdateContent(getPostData?.editor_content)}</Typography>
                      ) : (
                        <Typography
                          variant="body1"
                          component="p"
                          className={`view-news-content text-break ${type === 'Update' ? 'text-center' : ''}`}
                          dangerouslySetInnerHTML={{
                            __html: processedHTML(getPostData?.editor_content)
                              .innerHTML,
                          }}
                        ></Typography>
                      )
                    }
                    {getPostData?.meta_data && Object.keys(getPostData?.meta_data).length > 0 && <PreviewLink linkData={getPostData?.meta_data} />}
                  </div>
                </div>
                {getPostData?.is_post_comment === true ||
                  getPostData?.is_post_like === true ? (
                  <>
                    <CardActions
                      className="bg-white"
                      sx={{
                        border: "1px solid #EEEEEE",
                        padding: "10px",
                      }}
                      onMouseEnter={() => {
                        handlePopoverClose()
                        handlePopoverCloseForReplyLike()
                      }}
                      onMouseLeave={() => {
                        handlePopoverClose()
                        handlePopoverCloseForReplyLike()
                      }}
                    >
                      {getPostData?.is_post_like == true && (
                        <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-2 cursor-pointer"
                          onClick={() => { handleEngagementModalOpen() }}
                        >
                          {getPostData?.reactions?.map((reaction) => (
                            <FontAwesomeIcon
                              icon={reaction === "love" ? faHeart : reaction === "smile" ? faSmile : reaction === "angry" ? faAngry : reaction === "like" ? faThumbsUp : ""}
                              className={reaction === "love" ? "fa-love-btn" : reaction === "smile" ? "fa-smile-btn" : reaction === "angry" ? "fa-angry-btn" : reaction === "like" ? "fa-like-btn" : ""}
                            />
                          ))}

                          <Box className="like-counts-box">
                            <Typography className="fs-12-action-btn">
                              {getPostData?.like_count}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {getPostData?.is_post_comment == true && (
                        <Box className="w-100 ms-0">
                          <Typography className="fs-12-action-btn d-flex  justify-content-end">
                            {`${getPostData?.comment_count} comments`}
                          </Typography>
                        </Box>
                      )}

                    </CardActions>
                    <CardActions
                      className="bg-white"
                      sx={{
                        border: "1px solid #EEEEEE",
                        padding: "10px",
                      }}
                    >
                      {getPostData?.is_post_like == true && (
                        <Box
                          className="action-like w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                          onClick={() => {
                            toggleReaction(
                              id,
                              getPostData?.user_has_liked === "no" ? "like" : "no",
                              getPostData?.user_has_liked === "no" ? true : false
                            );
                            handlePopoverClose()
                          }}
                          onMouseEnter={(e) => handlePopoverOpen(e, getPostData.id)}
                        >
                          <FontAwesomeIcon
                            icon={getPostData?.reaction === "like" ? faThumbsUp : getPostData?.reaction === "love" ? faHeart : getPostData?.reaction === "smile" ? faSmile : getPostData?.reaction === "angry" ? faAngry : faThumbsUp}
                            className={`fs-12-action-btn ${getPostData?.reaction === "like" ? "liked-post-text" : getPostData?.reaction === "love" ? "love-post-text" : getPostData?.reaction === "smile" ? "smile-post-text" : getPostData?.reaction === "angry" ? "angry-post-text" : ""}`}
                          />
                          <Typography className={`fs-12-action-btn text-capitalize ${getPostData?.reaction === "like" ? "liked-post-text" : getPostData?.reaction === "love" ? "love-post-text" : getPostData?.reaction === "smile" ? "smile-post-text" : getPostData?.reaction === "angry" ? "angry-post-text" : ""}`}>
                            {getPostData?.user_has_liked !== "no" ? getPostData.reaction : "Like"}
                          </Typography>
                        </Box>
                      )}
                      {getPostData?.is_post_comment == true && (
                        <Box
                          className="action-comments w-100 d-flex flex-wrap justify-content-center align-items-center gap-2 cursor-pointer"
                          onClick={() =>
                            handleCommentToggle(id)
                          }
                          onMouseEnter={() => {
                            handlePopoverClose()
                            handlePopoverCloseForReplyLike()
                          }}
                          onMouseLeave={() => {
                            handlePopoverClose()
                            handlePopoverCloseForReplyLike()
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faComments}
                            className="fs-12-action-btn"
                          />
                          <Typography className="fs-12-action-btn">
                            Comments
                          </Typography>
                        </Box>
                      )}

                    </CardActions>
                    {getPostData?.is_post_comment == true && (
                      <CardActions
                        className="bg-white card-action-2"
                        sx={{
                          border: "1px solid #EEEEEE",
                          padding: "15px",
                        }}
                        onMouseEnter={() => {
                          handlePopoverClose()
                        }}
                        onMouseLeave={() => {
                          handlePopoverClose()
                        }}
                      >
                        {getPostData?.is_post_comment == true && (
                          <>
                            {getPostData?.commentToggle == true && (
                              <Box className="w-100" onMouseEnter={() => {
                                handlePopoverClose()
                                handlePopoverCloseForReplyLike()
                              }}
                                onMouseLeave={() => {
                                  handlePopoverClose()
                                  handlePopoverCloseForReplyLike()
                                }}>
                                <Box className="d-flex justify-content-start align-items-center gap-2">
                                  <Avatar
                                    alt={loggedUser?.name}
                                    className="author-avtar-creator"
                                    src={loggedUser?.thumbnail}
                                  >
                                    {loggedUser?.name?.charAt(0)}
                                  </Avatar>
                                  <InputGroup
                                    className=""
                                    style={{ height: "32px" }}
                                  >

                                    <MentionsInput
                                      placeholder="Add Comment. Use '@' for mention and '&' for emojis"
                                      value={getPostData?.commentText}
                                      onChange={(event) =>
                                        handleCommentText(
                                          event.target.value,
                                          id
                                        )
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          handleCommentsAPI(
                                            id,
                                            "yes"
                                          );
                                        }
                                      }}
                                      className="input-comments"
                                      singleLine={true}
                                      style={mentionsInputStyle}
                                      a11ySuggestionsListLabel={"Suggested mentions"}
                                      onPaste={handlePaste}
                                    >
                                      <Mention
                                        style={mentionStyle}
                                        className="mention-popup-class"
                                        onAdd={() => setIsSelectingSuggestion(true)} // Set the flag when a suggestion is selected
                                        data={getMentionUsers}
                                        renderSuggestion={(
                                          suggestion,
                                          search,
                                          highlightedDisplay,
                                          index,
                                          focused
                                        ) => (
                                          <div
                                            className="list-class"
                                            key={index}
                                          >
                                            <div className="list-class-div-1">
                                              <img
                                                src={suggestion.photo}
                                                alt={suggestion.display}
                                                className="list-class-div-1-img"
                                              />
                                              <div className="list-class-div-2">
                                                <span style={{ fontWeight: 'bold' }}>{suggestion?.full_name}</span>
                                                <br />
                                                <span>{suggestion?.job_title}</span>
                                              </div>
                                            </div>

                                          </div>
                                        )}
                                      />
                                    </MentionsInput>
                                    <Button
                                      className="search-btn-dash-2"
                                      style={{ height: "100%" }}
                                      onClick={() =>
                                        handleCommentsAPI(
                                          id,
                                          "yes"
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faAdd}
                                      />
                                    </Button>
                                  </InputGroup>
                                </Box>
                              </Box>
                            )}
                          </>
                        )}
                        {getPostData?.comments?.length > 0 && (
                          <>
                            {getPostData?.comments?.map(
                              (comment) => (
                                <>
                                  {comment?.is_parent ===
                                    "yes" && (
                                      <>
                                        <Box id={`comment-${comment?.id}`} key={`comment-${comment?.id}`} className="w-100 ms-0" onMouseEnter={handlePopoverClose}
                                          onMouseLeave={handlePopoverClose}>
                                          <Box className="d-flex justify-content-start align-items-center gap-2">
                                            <Avatar
                                              alt={
                                                comment?.user
                                                  ?.name
                                              }
                                              className="author-avtar-creator mb-auto"
                                              src={
                                                comment?.user
                                                  ?.thumbnail
                                              }
                                            >
                                              {comment?.user
                                                ?.name?.charAt(0)}
                                            </Avatar>
                                            <Box
                                              sx={{
                                                padding: "15px",
                                              }}
                                              className="comment-box w-100"
                                            >
                                              <Typography className="fs-14-700">
                                                {
                                                  comment?.user
                                                    ?.name
                                                }
                                              </Typography>
                                              <Typography className="fs-14 mt-2">
                                                {renderCommentText(comment?.comment)}
                                              </Typography>
                                              <Stack
                                                direction="row"
                                                divider={
                                                  <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                    className="nested-divider"
                                                  />
                                                }
                                                className="mt-2 align-items-center"
                                                spacing={1}
                                              >
                                                <Box>
                                                  <Box className="w-100 d-flex gap-2 align-items-center">
                                                    <Typography className={`fs-10 cursor-pointer ${comment?.reaction === "like" ? "liked-post-text" : comment?.reaction === "love" ? "love-post-text" : comment?.reaction === "smile" ? "smile-post-text" : comment?.reaction === "angry" ? "angry-post-text" : ""}`} onClick={() => {

                                                      toggleLikeForCommentsReaction(
                                                        id,
                                                        comment?.has_user_liked_comment === "no" ? "like" : "no",
                                                        comment?.id,
                                                        comment?.has_user_liked_comment === "no" ? true : false
                                                      );
                                                    }}
                                                      onMouseEnter={(e) => {
                                                        setCurrentPostId()

                                                        setCurrentPostIdForReply(comment?.id)
                                                        handlePopoverOpenForReplyLike(e, getPostData.id, comment?.id)
                                                      }}
                                                      onMouseLeave={() => {
                                                        setCurrentPostId()

                                                        setCurrentPostIdForReply(comment?.id)
                                                      }}
                                                    >
                                                      {comment?.has_user_liked_comment !== "no" ? comment.reaction : "Like"}
                                                    </Typography>
                                                    <Box className="action-like w-100 d-flex flex-wrap align-items-center gap-1 cursor-pointer" onMouseEnter={() => {
                                                      handlePopoverClose()
                                                      handlePopoverCloseForReplyLike()
                                                    }}
                                                      onMouseLeave={() => {
                                                        handlePopoverClose()
                                                        handlePopoverCloseForReplyLike()
                                                      }}
                                                      onClick={() => { handleCommentEngagementModalOpen(comment?.id) }}
                                                    >
                                                      {comment?.user_reactions?.map((reaction) => (
                                                        <FontAwesomeIcon
                                                          icon={reaction === "love" ? faHeart : reaction === "smile" ? faSmile : reaction === "angry" ? faAngry : reaction === "like" ? faThumbsUp : ""}
                                                          className={reaction === "love" ? "fa-love-btn-2" : reaction === "smile" ? "fa-smile-btn-2" : reaction === "angry" ? "fa-angry-btn-2" : reaction === "like" ? "fa-like-btn-2" : ""}
                                                        />
                                                      ))}

                                                      <Box className="like-counts-box">
                                                        <Typography className="fs-10">
                                                          {
                                                            comment?.comment_likes_count
                                                          }
                                                        </Typography>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                                <Box onMouseEnter={() => {
                                                  handlePopoverClose()
                                                  handlePopoverCloseForReplyLike()
                                                }}
                                                  onMouseLeave={() => {
                                                    handlePopoverClose()
                                                    handlePopoverCloseForReplyLike()
                                                  }}>
                                                  <Box>
                                                    <Box className="w-100 d-flex gap-2 align-items-center">
                                                      <Typography className="fs-10 cursor-pointer" onClick={() => handleCommentReplyToggle(id, comment?.id)}>
                                                        Reply
                                                      </Typography>
                                                      <Typography className="fs-10 cursor-pointer">
                                                        {`${comment?.replies_count} reply`}
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              </Stack>


                                            </Box>
                                          </Box>
                                        </Box>
                                        {comment?.commentReplyToggle == true && (
                                          <Box className="w-100 ms-0" sx={{ paddingLeft: "62px" }} onMouseEnter={() => {
                                            handlePopoverClose()
                                            handlePopoverCloseForReplyLike()
                                          }}
                                            onMouseLeave={() => {
                                              handlePopoverClose()
                                              handlePopoverCloseForReplyLike()
                                            }}>
                                            <Box className="w-100 mt-2 mb-1">
                                              <Box className="d-flex justify-content-start align-items-center gap-2">
                                                <Avatar
                                                  alt={loggedUser?.name}
                                                  className="author-avtar-creator-2"
                                                  src={loggedUser?.thumbnail}
                                                >
                                                  {loggedUser?.name?.charAt(0)}
                                                </Avatar>
                                                <InputGroup
                                                  className=""
                                                  style={{ height: "25px" }}
                                                >
                                                  <MentionsInput
                                                    placeholder="Add Comment. Use '@' for mention"
                                                    value={comment?.commentReplyText}
                                                    singleLine={true}
                                                    onChange={(event) => {
                                                      handleCommentReplyText(
                                                        event.target.value,
                                                        id,
                                                        comment?.id
                                                      )
                                                    }
                                                    }
                                                    onKeyDown={(e) => {
                                                      if (e.key === "Enter") {
                                                        e.preventDefault();
                                                        handleCommentsReplyAPI(
                                                          id,
                                                          "no",
                                                          comment?.id
                                                        );
                                                      }
                                                    }}
                                                    className="input-comments"
                                                    style={mentionsInputStyle}
                                                    a11ySuggestionsListLabel={"Suggested mentions"}
                                                    onPaste={handlePaste}
                                                  >
                                                    <Mention
                                                      style={mentionStyle}
                                                      className="mention-popup-class"
                                                      data={getMentionUsers}
                                                      onAdd={() => setIsSelectingSuggestion(true)} // Set the flag when a suggestion is selected
                                                      appendSpaceOnAdd={true}
                                                      renderSuggestion={(
                                                        suggestion,
                                                        search,
                                                        highlightedDisplay,
                                                        index,
                                                        focused
                                                      ) => (
                                                        <div

                                                          className="list-class"
                                                          key={index}
                                                        >
                                                          <div className="list-class-div-1">
                                                            <img
                                                              src={suggestion.photo}
                                                              alt={suggestion.display}
                                                              className="list-class-div-1-img"
                                                            />
                                                            <div className="list-class-div-2">
                                                              <span style={{ fontWeight: 'bold' }}>{suggestion?.full_name}</span>
                                                              <br />
                                                              <span>{suggestion?.job_title}</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )}
                                                    />
                                                  </MentionsInput>

                                                  <Button
                                                    className="search-btn-dash-3"
                                                    style={{ height: "100%" }}
                                                    onClick={() =>
                                                      handleCommentsReplyAPI(
                                                        id,
                                                        "no",
                                                        comment?.id
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faAdd}
                                                    />
                                                  </Button>
                                                </InputGroup>
                                              </Box>
                                            </Box>
                                          </Box>
                                        )}
                                        {comment?.replies?.length > 0 ? (
                                          <>
                                            {comment?.replies?.map((reply) => (
                                              <Box id={`comment-${reply?.id}`} key={`comment-${reply?.id}`} className="w-100 ms-0" sx={{ paddingLeft: "62px" }} onMouseEnter={() => {
                                                handlePopoverClose()
                                                handlePopoverCloseForReplyLike()
                                              }}
                                                onMouseLeave={() => {
                                                  handlePopoverClose()
                                                  handlePopoverCloseForReplyLike()
                                                }}>
                                                <Box className="w-100 mt-2">
                                                  <Box className="d-flex justify-content-start align-items-center gap-2">
                                                    <Avatar
                                                      alt={
                                                        reply
                                                          ?.user
                                                          ?.name
                                                      }
                                                      className="author-avtar-creator-2 mb-auto"
                                                      src={
                                                        reply
                                                          ?.user
                                                          ?.thumbnail
                                                      }
                                                    >
                                                      {reply
                                                        ?.user
                                                        ?.name?.charAt(0)}
                                                    </Avatar>
                                                    <Box
                                                      sx={{
                                                        padding:
                                                          "10px",
                                                      }}
                                                      className="comment-box w-100"
                                                    >
                                                      <Typography className="fs-12-700">
                                                        {
                                                          reply
                                                            ?.user
                                                            ?.name
                                                        }
                                                      </Typography>
                                                      <Typography className="fs-12 mt-1">
                                                        {renderCommentText(reply?.comment)}
                                                      </Typography>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              </Box>
                                            ))}
                                          </>
                                        ) : ""}
                                      </>
                                    )}
                                </>
                              )
                            )}
                            {getPostData?.comments?.length <
                              getPostData?.parent_comment_count && (
                                <Box className="w-100 ms-0 d-flex justify-content-center" onMouseEnter={() => {
                                  handlePopoverClose()
                                  handlePopoverCloseForReplyLike()
                                }}
                                  onMouseLeave={() => {
                                    handlePopoverClose()
                                    handlePopoverCloseForReplyLike()
                                  }}>
                                  <Button
                                    className="w-50 mt-4 btn-load-comments"

                                    onClick={() => {
                                      setFetchAll(true)
                                      fetchLoadMoreCommentsAPI(
                                        id
                                      );
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faComments}
                                    />
                                    Load More Comments
                                  </Button>
                                </Box>
                              )}

                          </>
                        )}
                      </CardActions>
                    )}

                  </>
                ) : ""}
              </div>
            </Grid>
            <Grid item lg={4} sm={12} md={12} xl={4} xs={12} className="p-2">
              {getPostData?.type?.slug === "news" ? (
                <>
                  {getPostData?.is_require_acknowledge == true && (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #F9F9F9",
                          padding: "20px",
                        }}
                        className="mb-4"
                      >
                        <Stack spacing={2}>
                          <div className="main-content-div-1-view-post w-100">
                            <div>
                              <div className="main-div-posted">
                                {getPostData?.acknowledged_on !== "" && (
                                  <Typography className="fs-20 text-center">{((getPostData?.acknowledge_data && (typeof getPostData?.acknowledge_data === "string")) ? `${getPostData?.acknowledge_data}` : ``)}</Typography>
                                )}
                              </div>
                              <Box
                                className={`custom-checkbox mt-3 ${getPostData?.acknowledged_on !== "" &&
                                  "active-checkbox"
                                  }`}
                              >
                                <label className="checkbox-style">
                                  {getPostData?.acknowledged_on !== ""
                                    ? `You acknowledged this on ${onlyDateFormat(getPostData?.acknowledged_on, true, false, true)}`
                                    : ((getPostData?.acknowledge_data && (typeof getPostData?.acknowledge_data === "string")) ? `${getPostData?.acknowledge_data}` : `This requires your acknowledgement`)}
                                  <input
                                    type="checkbox"
                                    checked={getPostData?.acknowledged_on !== ""}
                                    onChange={() => {
                                      if (getPostData?.acknowledge_type === "question") {
                                        setAckQuestions(getPostData?.acknowledge_data)
                                        setPostIdForAckQue(getPostData?.id)
                                        setOpenAckQuePopup(true)
                                      } else if (getPostData?.acknowledge_type === "checkbox") {
                                        handleCheckboxChange(getPostData?.id)
                                      }
                                    }
                                    }
                                    disabled={
                                      getPostData?.acknowledged_on !== ""
                                    }
                                  />
                                  <span className="checkmark"></span>
                                </label>
                              </Box>
                            </div>

                          </div>
                        </Stack>
                      </Box>
                    </>
                  )}

                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #F9F9F9",
                      marginTop: "20px"
                    }}
                  >
                    <Box
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                      className="box-attribute-2"
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        // variant={isTablet && "scrollable"}
                        variant="scrollable"
                        className={"tabs view-type-post-tab"}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#660C60",
                            display: "none"
                          },
                        }}
                      >
                        <Tab
                          label="Details"
                          iconPosition="start"
                          {...a11yProps1(0)}
                        />

                      </Tabs>
                    </Box>
                    <CustomTabPanel value={0} index={value}>
                      <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                        <List container spacing={2}>
                          <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                            <ListItemText className="fs-14-muted">
                              Post Date
                            </ListItemText>
                            <ListItemText className="fs-14 text-end">
                              {onlyDateFormat(getPostData?.published_date, false, true, true)}
                            </ListItemText>
                          </ListItem>
                          <Divider component="li" className="divider-details" />
                          <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                            <ListItemText className="fs-14-muted">
                              Modified On
                            </ListItemText>
                            <ListItemText className="fs-14 text-end">
                              {onlyDateFormat(getPostData?.modified_on, false, true, true)}
                            </ListItemText>
                          </ListItem>
                          <Divider component="li" className="divider-details" />

                          {
                            Object.entries(getPostData?.category_object || {}).map(([key, values], index) => {
                              const additionalCount = values?.length - 2;
                              const remainingValues = values.slice(2);

                              return (
                                <>
                                  <ListItem key={index} className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                    <ListItemText className="fs-14-muted">
                                      {key}
                                    </ListItemText>
                                    <ListItemText className="fs-14 text-end">
                                      {values.slice(0, 2).join(", ")}
                                      {additionalCount > 0 && (
                                        <span className="badge-count"
                                          onClick={(event) => handlePostDetailPopoverOpen(event, remainingValues)}
                                        >
                                          +{additionalCount} more
                                        </span>
                                      )}
                                    </ListItemText>
                                  </ListItem>
                                  <Divider component="li" className="divider-details" />
                                </>
                              )
                            })
                          }

                          <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                            <ListItemText className="fs-14-muted">
                              Created By
                            </ListItemText>
                            <Box>
                              <Stack
                                direction="row"
                                alignContent="center"
                                alignItems="center"
                                spacing={1}
                                className="z-n1"
                              >
                                <Avatar
                                  src={getPostData?.user?.thumbnail}
                                  className="author-avtar-details"
                                >
                                  {getPostData?.user?.name?.charAt(0)}
                                </Avatar>
                                <Typography className="fw-400 fs-14">
                                  {getPostData?.user?.name}
                                </Typography>
                              </Stack>
                            </Box>
                          </ListItem>
                        </List>
                      </Paper>

                      {postDetailPopoverContent &&

                        <Popover
                          id={postdetailPopoverid}
                          open={openPostDetailPopover}
                          anchorEl={anchorElForPostDetail}
                          onClose={handlePostDetailPopoverClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <List sx={{
                            p: 1,
                            maxHeight: 200,
                            overflow: 'auto',
                          }}>
                            {postDetailPopoverContent.map((value, idx) => (
                              <>
                                <ListItem key={idx}>
                                  <Typography variant="body2">{value}</Typography>
                                </ListItem>
                                <Divider />
                              </>
                            ))}
                          </List>
                        </Popover>

                      }
                    </CustomTabPanel>
                  </Box>

                  {(getPostData?.extra_data && Object.keys(getPostData.extra_data).length > 0) && (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #F9F9F9",
                          marginTop: "20px"
                        }}
                      >
                        <Box
                          sx={{ borderBottom: 1, borderColor: "divider" }}
                          className="box-attribute-2"
                        >
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            // variant={isTablet && "scrollable"}
                            variant="scrollable"
                            className={"tabs view-type-post-tab"}
                            TabIndicatorProps={{
                              style: {
                                backgroundColor: "#660C60",
                                display: "none"
                              },
                            }}
                          >
                            <Tab
                              label="Data"
                              iconPosition="start"
                              {...a11yProps1(0)}
                            />

                          </Tabs>
                        </Box>
                        <CustomTabPanel value={0} index={value}>
                          <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                            <List container spacing={2}>


                              {
                                Object.entries(getPostData?.extra_data || {}).map(([key, values], index, array) => {
                                  const additionalCount = values?.length - 2;
                                  const remainingValues = values.slice(2);
                                  const isLastItem = index === array.length - 1;

                                  return (
                                    <>
                                      <ListItem key={index} className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                        <ListItemText className="fs-14-muted">
                                          {key}
                                        </ListItemText>
                                        <ListItemText className="fs-14 text-end">
                                          {values.slice(0, 2).join(", ")}
                                          {additionalCount > 0 && (
                                            <span className="badge-count"
                                              onClick={(event) => handlePostDetailPopoverOpen(event, remainingValues)}
                                            >
                                              +{additionalCount} more
                                            </span>
                                          )}
                                        </ListItemText>
                                      </ListItem>
                                      {!isLastItem && <Divider component="li" className="divider-details" />}
                                    </>
                                  )
                                })
                              }

                            </List>
                          </Paper>

                        </CustomTabPanel>
                      </Box>
                    </>
                  )}


                  {getPostData?.related_posts?.length > 0 && (
                    <Box className="mt-4 mb-3 related-post-div">
                      <Typography className="related-text">
                        Related Posts
                      </Typography>
                    </Box>
                  )}

                  {getPostData?.related_posts?.length > 0 && (
                    <>
                      <Box>
                        <Stack
                          spacing={3}
                          direction="column"
                          divider={<Divider orientation="horizontal" />}
                        >
                          {getPostData?.related_posts?.map((related) => (
                            <Box>
                              <Stack direction={"row"} spacing={1}>
                                <Box className="relate-item-img-div">
                                  <Image src={related?.image} />
                                </Box>
                                <Box>
                                  <Stack spacing={1}>
                                    <Typography className="related-post-title text-break">
                                      {related?.title}
                                    </Typography>
                                    <Link
                                      underline="hover"
                                      key="1"
                                      color="inherit"
                                      href="/"
                                      component="h3"
                                      onClick={() => {
                                        if (loggedUser?.user_type === "user") {
                                          localStorage.setItem("last-redirect-page-user", `/user-type/view/${related?.type?.name}/${related?.id}`);
                                          navigate(`/user-type/view/${related?.type?.name}/${related?.id}`);
                                        }
                                        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                          localStorage.setItem("last-redirect-page-admin-user", `/user-type/view/${related?.type?.name}/${related?.id}`)
                                          navigate(`/user-type/view/${related?.type?.name}/${related?.id}`);
                                        }
                                      }}
                                      className="cursor-pointer related-read-more"
                                    >
                                      Read More{" "}
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="related-read-more"
                                      />
                                    </Link>
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      </Box>
                    </>
                  )}

                  {getPostData?.rating_enable == true && (
                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #F9F9F9",
                        marginTop: "20px"
                      }}
                    >
                      <Box
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                        className="box-attribute-2"
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          // variant={isTablet && "scrollable"}
                          variant="scrollable"
                          className={"tabs view-type-post-tab"}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#660C60",
                              display: "none"
                            },
                          }}
                        >
                          <Tab
                            label="How Useful Was This Document?"
                            iconPosition="start"
                            {...a11yProps1(0)}
                          />

                        </Tabs>
                      </Box>
                      <CustomTabPanel value={0} index={value}>
                        <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                          <Box className="rating-div">
                            <Rating
                              name="simple-controlled"
                              value={ratingValue}
                              onChange={(event, newValue) => {
                                setRattingValue(newValue);
                                handleRatingAPI(newValue)
                              }}
                              size="large"
                            />
                          </Box>
                        </Paper>

                      </CustomTabPanel>

                    </Box>
                  )}
                </>

              ) : getPostData?.type?.slug === "documents" ? (
                <>
                  {(getPostData?.is_require_acknowledge == true || getPostData?.file !== null) && (
                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #F9F9F9",
                        padding: "20px",
                      }}
                      className="mb-4"
                    >
                      <Stack spacing={2}>
                        <div className="main-content-div-1-view-post w-100">

                          <div>
                            {getPostData?.is_require_acknowledge == true && (
                              <>
                                <div className="main-div-posted">
                                  {getPostData?.acknowledged_on !== "" && (
                                    <Typography className="fs-20 text-center">{((getPostData?.acknowledge_data && (typeof getPostData?.acknowledge_data === "string")) ? `${getPostData?.acknowledge_data}` : ``)}</Typography>
                                  )}
                                </div>
                                <Box
                                  className={`custom-checkbox mt-3 ${getPostData?.acknowledged_on !== "" &&
                                    "active-checkbox"
                                    }`}
                                >
                                  <label className="checkbox-style">
                                    {getPostData?.acknowledged_on !== ""
                                      ? `You acknowledged this on ${onlyDateFormat(getPostData?.acknowledged_on, true, false, true)}`
                                      : ((getPostData?.acknowledge_data && (typeof getPostData?.acknowledge_data === "string")) ? `${getPostData?.acknowledge_data}` : `This requires your acknowledgement`)}
                                    <input
                                      type="checkbox"
                                      checked={getPostData?.acknowledged_on !== ""}
                                      onChange={() => {
                                        if (getPostData?.acknowledge_type === "question") {
                                          setAckQuestions(getPostData?.acknowledge_data)
                                          setPostIdForAckQue(getPostData?.id)
                                          setOpenAckQuePopup(true)
                                        } else if (getPostData?.acknowledge_type === "checkbox") {
                                          handleCheckboxChange(getPostData?.id)
                                        }
                                      }
                                      }
                                      disabled={
                                        getPostData?.acknowledged_on !== ""
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Box>
                              </>
                            )}
                          </div>
                          {getPostData?.file !== null && (
                            <div className="w-100">
                              <Button
                                className="btn primary-btn w-100 fs-12-btn"
                                type="submit"
                                onClick={() => {
                                  handleDownload(getPostData?.file)
                                }}
                              >
                                Download {getPostData?.file?.file_title?.toLowerCase() || 'Document'}
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  className="fs-12-btn"
                                />
                              </Button>
                              <Button
                                className="btn w-100 bg-white mt-2 fs-12-btn-2"
                                type="submit"
                                onClick={() => {
                                }}
                              >
                                Share {getPostData?.file?.file_title?.toLowerCase() || 'Document'}
                                <FontAwesomeIcon
                                  icon={faShare}
                                  className="fs-12-btn-2"
                                />
                              </Button>
                            </div>
                          )}
                        </div>
                      </Stack>
                    </Box>
                  )}


                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #F9F9F9",
                      marginTop: "20px"
                    }}
                  >
                    <Box
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                      className="box-attribute-2"
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        // variant={isTablet && "scrollable"}
                        variant="scrollable"
                        className={"tabs view-type-post-tab"}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#660C60",
                            display: "none"
                          },
                        }}
                      >
                        <Tab
                          label="Details"
                          iconPosition="start"
                          {...a11yProps1(0)}
                        />

                      </Tabs>
                    </Box>
                    <CustomTabPanel value={0} index={value}>
                      <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                        <List container spacing={2}>
                          <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                            <ListItemText className="fs-14-muted">
                              Upload Date
                            </ListItemText>
                            <ListItemText className="fs-14 text-end">
                              {onlyDateFormat(getPostData?.file?.created_at, false, true, true)}
                            </ListItemText>
                          </ListItem>
                          <Divider component="li" className="divider-details" />
                          <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                            <ListItemText className="fs-14-muted">
                              Modified On
                            </ListItemText>
                            <ListItemText className="fs-14 text-end">
                              {onlyDateFormat(getPostData?.file?.updated_at, false, true, true)}
                            </ListItemText>
                          </ListItem>
                          <Divider component="li" className="divider-details" />

                          <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                            <ListItemText className="fs-14-muted">
                              File Size
                            </ListItemText>
                            <ListItemText className="fs-14 text-end">
                              {getPostData?.file?.file_size}kb
                            </ListItemText>
                          </ListItem>
                          <Divider component="li" className="divider-details" />

                          <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                            <ListItemText className="fs-14-muted">
                              Document Type
                            </ListItemText>
                            <ListItemText className="fs-14 text-end text-uppercase">
                              {getPostData?.file?.file_type}
                            </ListItemText>
                          </ListItem>
                          <Divider component="li" className="divider-details" />

                          {
                            Object.entries(getPostData?.category_object || {}).map(([key, values], index) => {
                              const additionalCount = values?.length - 2;
                              const remainingValues = values.slice(2);

                              return (
                                <>
                                  <ListItem key={index} className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                    <ListItemText className="fs-14-muted">
                                      {key}
                                    </ListItemText>
                                    <ListItemText className="fs-14 text-end">
                                      {values.slice(0, 2).join(", ")}
                                      {additionalCount > 0 && (
                                        <span className="badge-count"
                                          onClick={(event) => handlePostDetailPopoverOpen(event, remainingValues)}
                                        >
                                          +{additionalCount} more
                                        </span>
                                      )}
                                    </ListItemText>
                                  </ListItem>
                                  <Divider component="li" className="divider-details" />
                                </>
                              )
                            })
                          }

                          <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                            <ListItemText className="fs-14-muted">
                              Created By
                            </ListItemText>
                            <Box>
                              <Stack
                                direction="row"
                                alignContent="center"
                                alignItems="center"
                                spacing={1}
                                className="z-n1"
                              >
                                <Avatar
                                  src={getPostData?.file?.user?.thumbnail}
                                  className="author-avtar-details"
                                >
                                  {getPostData?.file?.user?.name?.charAt(0)}
                                </Avatar>
                                <Typography className="fw-400 fs-14">
                                  {getPostData?.file?.user?.name}
                                </Typography>
                              </Stack>
                            </Box>
                          </ListItem>
                        </List>
                      </Paper>

                      {postDetailPopoverContent &&

                        <Popover
                          id={postdetailPopoverid}
                          open={openPostDetailPopover}
                          anchorEl={anchorElForPostDetail}
                          onClose={handlePostDetailPopoverClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <List sx={{
                            p: 1,
                            maxHeight: 200, // Set the maximum height for the list
                            overflow: 'auto', // Enable scrolling when content exceeds maxHeight
                          }}>
                            {postDetailPopoverContent.map((value, idx) => (
                              <>
                                <ListItem key={idx}>
                                  <Typography variant="body2">{value}</Typography>
                                </ListItem>
                                <Divider />
                              </>
                            ))}
                          </List>
                        </Popover>

                      }
                    </CustomTabPanel>

                  </Box>

                  {(getPostData?.extra_data && Object.keys(getPostData.extra_data).length > 0) && (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #F9F9F9",
                          marginTop: "20px"
                        }}
                      >
                        <Box
                          sx={{ borderBottom: 1, borderColor: "divider" }}
                          className="box-attribute-2"
                        >
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            // variant={isTablet && "scrollable"}
                            variant="scrollable"
                            className={"tabs view-type-post-tab"}
                            TabIndicatorProps={{
                              style: {
                                backgroundColor: "#660C60",
                                display: "none"
                              },
                            }}
                          >
                            <Tab
                              label="Data"
                              iconPosition="start"
                              {...a11yProps1(0)}
                            />

                          </Tabs>
                        </Box>
                        <CustomTabPanel value={0} index={value}>
                          <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                            <List container spacing={2}>


                              {
                                Object.entries(getPostData?.extra_data || {}).map(([key, values], index, array) => {
                                  const additionalCount = values?.length - 2;
                                  const remainingValues = values.slice(2);
                                  const isLastItem = index === array.length - 1;

                                  return (
                                    <>
                                      <ListItem key={index} className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                        <ListItemText className="fs-14-muted">
                                          {key}
                                        </ListItemText>
                                        <ListItemText className="fs-14 text-end">
                                          {values.slice(0, 2).join(", ")}
                                          {additionalCount > 0 && (
                                            <span className="badge-count"
                                              onClick={(event) => handlePostDetailPopoverOpen(event, remainingValues)}
                                            >
                                              +{additionalCount} more
                                            </span>
                                          )}
                                        </ListItemText>
                                      </ListItem>
                                      {!isLastItem && <Divider component="li" className="divider-details" />}
                                    </>
                                  )
                                })
                              }

                            </List>
                          </Paper>

                        </CustomTabPanel>
                      </Box>
                    </>
                  )}

                  {getPostData?.rating_enable == true && (
                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #F9F9F9",
                        marginTop: "20px"
                      }}
                    >
                      <Box
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                        className="box-attribute-2"
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          // variant={isTablet && "scrollable"}
                          variant="scrollable"
                          className={"tabs view-type-post-tab"}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#660C60",
                              display: "none"
                            },
                          }}
                        >
                          <Tab
                            label="How Useful Was This Document?"
                            iconPosition="start"
                            {...a11yProps1(0)}
                          />

                        </Tabs>
                      </Box>
                      <CustomTabPanel value={0} index={value}>
                        <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                          <Box className="rating-div">
                            <Rating
                              name="simple-controlled"
                              value={ratingValue}
                              onChange={(event, newValue) => {
                                setRattingValue(newValue);
                                handleRatingAPI(newValue)
                              }}
                              size="large"
                            />
                          </Box>
                        </Paper>

                      </CustomTabPanel>

                    </Box>
                  )}


                  {getPostData?.related_posts?.length > 0 && (
                    <Box className="mt-4 mb-3 related-post-div">
                      <Typography className="related-text">
                        Related Posts
                      </Typography>
                    </Box>
                  )}

                  {getPostData?.related_posts?.length > 0 && (
                    <>
                      <Box>
                        <Stack
                          spacing={3}
                          direction="column"
                          divider={<Divider orientation="horizontal" />}
                        >
                          {getPostData?.related_posts?.map((related) => (
                            <Box>
                              <Stack direction={"row"} spacing={1}>
                                <Box className="relate-item-img-div">
                                  <Image src={related?.image} />
                                </Box>
                                <Box>
                                  <Stack spacing={1}>
                                    <Typography className="related-post-title text-break">
                                      {related?.title}
                                    </Typography>
                                    <Link
                                      underline="hover"
                                      key="1"
                                      color="inherit"
                                      href="/"
                                      component="h3"
                                      onClick={() => {
                                        if (loggedUser?.user_type === "user") {
                                          localStorage.setItem("last-redirect-page-user", `/user-type/view/${related?.type?.name}/${related?.id}`);
                                          navigate(`/user-type/view/${related?.type?.name}/${related?.id}`);
                                        }
                                        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                          localStorage.setItem("last-redirect-page-admin-user", `/user-type/view/${related?.type?.name}/${related?.id}`)
                                          navigate(`/user-type/view/${related?.type?.name}/${related?.id}`);
                                        }

                                      }}
                                      className="cursor-pointer related-read-more"
                                    >
                                      Read More{" "}
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="related-read-more"
                                      />
                                    </Link>
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <>
                  {(getPostData?.is_require_acknowledge == true || (getPostData?.file?.file && getPostData?.file?.file !== null && getPostData?.file?.id !== "")) && (

                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #F9F9F9",
                        padding: "20px",
                      }}
                      className="mb-4"
                    >
                      <Stack spacing={2}>
                        <div className="main-content-div-1-view-post w-100">

                          <div>
                            {getPostData?.is_require_acknowledge == true && (
                              <>
                                <div className="main-div-posted">
                                  {getPostData?.acknowledged_on !== "" && (

                                    <Typography className="fs-20 text-center">{((getPostData?.acknowledge_data && (typeof getPostData?.acknowledge_data === "string")) ? `${getPostData?.acknowledge_data}` : ``)}</Typography>
                                  )}
                                </div>
                                <Box
                                  className={`custom-checkbox mt-3 ${getPostData?.acknowledged_on !== "" &&
                                    "active-checkbox"
                                    }`}
                                >
                                  <label className="checkbox-style">
                                    {getPostData?.acknowledged_on !== ""
                                      ? `You acknowledged this on ${onlyDateFormat(getPostData?.acknowledged_on, true, false, true)}`
                                      : ((getPostData?.acknowledge_data && (typeof getPostData?.acknowledge_data === "string")) ? `${getPostData?.acknowledge_data}` : `This requires your acknowledgement`)}
                                    <input
                                      type="checkbox"
                                      checked={getPostData?.acknowledged_on !== ""}
                                      onChange={() => {
                                        if (getPostData?.acknowledge_type === "question") {
                                          setAckQuestions(getPostData?.acknowledge_data)
                                          setPostIdForAckQue(getPostData?.id)
                                          setOpenAckQuePopup(true)
                                        } else if (getPostData?.acknowledge_type === "checkbox") {
                                          handleCheckboxChange(getPostData?.id)
                                        }
                                      }
                                      }
                                      disabled={
                                        getPostData?.acknowledged_on !== ""
                                      }
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Box>
                              </>
                            )}
                          </div>
                          {(getPostData?.file?.file && getPostData?.file?.file !== null) && (
                            <div className="w-100">
                              <Button
                                className="btn primary-btn w-100 fs-12-btn"
                                type="submit"
                                onClick={() => {
                                  handleDownload(getPostData?.file)
                                }}
                              >
                                Download {getPostData?.file?.file_title?.toLowerCase() || type}
                                <FontAwesomeIcon
                                  icon={faDownload}
                                  className="fs-12-btn"
                                />
                              </Button>
                              <Button
                                className="btn w-100 bg-white mt-2 fs-12-btn-2"
                                type="submit"
                                onClick={() => {
                                }}
                              >
                                Share {getPostData?.file?.file_title?.toLowerCase() || type}
                                <FontAwesomeIcon
                                  icon={faShare}
                                  className="fs-12-btn-2"
                                />
                              </Button>
                            </div>
                          )}
                        </div>
                      </Stack>
                    </Box>

                  )}

                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFFFFF",
                      border: "1px solid #F9F9F9",
                      marginTop: "20px"
                    }}
                  >
                    <Box
                      sx={{ borderBottom: 1, borderColor: "divider" }}
                      className="box-attribute-2"
                    >
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="basic tabs example"
                        // variant={isTablet && "scrollable"}
                        variant="scrollable"
                        className={"tabs view-type-post-tab"}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#660C60",
                            display: "none"
                          },
                        }}
                      >
                        <Tab
                          label="Details"
                          iconPosition="start"
                          {...a11yProps1(0)}
                        />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={0} index={value}>
                      <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                        <List container spacing={2}>
                          {(getPostData?.file?.file && getPostData?.file?.file !== null && getPostData?.file?.id !== "") ? (
                            <>
                              <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                <ListItemText className="fs-14-muted">
                                  Upload Date
                                </ListItemText>
                                <ListItemText className="fs-14 text-end">
                                  {onlyDateFormat(getPostData?.file?.created_at, false, true, true)}
                                </ListItemText>
                              </ListItem>
                              <Divider component="li" className="divider-details" />
                              <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                <ListItemText className="fs-14-muted">
                                  Modified On
                                </ListItemText>
                                <ListItemText className="fs-14 text-end">
                                  {onlyDateFormat(getPostData?.file?.updated_at, false, true, true)}
                                </ListItemText>
                              </ListItem>
                              <Divider component="li" className="divider-details" />

                              <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                <ListItemText className="fs-14-muted">
                                  File Size
                                </ListItemText>
                                <ListItemText className="fs-14 text-end">
                                  {getPostData?.file?.file_size}kb
                                </ListItemText>
                              </ListItem>
                              <Divider component="li" className="divider-details" />

                              <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                <ListItemText className="fs-14-muted">
                                  Document Type
                                </ListItemText>
                                <ListItemText className="fs-14 text-end text-uppercase">
                                  {getPostData?.file?.file_type}
                                </ListItemText>
                              </ListItem>
                              <Divider component="li" className="divider-details" />
                            </>
                          ) : (
                            <>
                              <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                <ListItemText className="fs-14-muted">
                                  Post Date
                                </ListItemText>
                                <ListItemText className="fs-14 text-end">
                                  {onlyDateFormat(getPostData?.published_date, false, true, true)}
                                </ListItemText>
                              </ListItem>
                              <Divider component="li" className="divider-details" />
                              <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                <ListItemText className="fs-14-muted">
                                  Modified On
                                </ListItemText>
                                <ListItemText className="fs-14 text-end">
                                  {onlyDateFormat(getPostData?.modified_on, false, true, true)}
                                </ListItemText>
                              </ListItem>
                              <Divider component="li" className="divider-details" />
                            </>
                          )}

                          {
                            Object.entries(getPostData?.category_object || {}).map(([key, values], index) => {
                              const additionalCount = values?.length - 2;
                              const remainingValues = values.slice(2);

                              return (
                                <>
                                  <ListItem key={index} className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                    <ListItemText className="fs-14-muted">
                                      {key}
                                    </ListItemText>
                                    <ListItemText className="fs-14 text-end">
                                      {values.slice(0, 2).join(", ")}
                                      {additionalCount > 0 && (
                                        <span className="badge-count"
                                          onClick={(event) => handlePostDetailPopoverOpen(event, remainingValues)}
                                        >
                                          +{additionalCount} more
                                        </span>
                                      )}
                                    </ListItemText>
                                  </ListItem>
                                  <Divider component="li" className="divider-details" />
                                </>
                              )
                            })
                          }

                          <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                            <ListItemText className="fs-14-muted">
                              Created By
                            </ListItemText>
                            <Box>
                              <Stack
                                direction="row"
                                alignContent="center"
                                alignItems="center"
                                spacing={1}
                                className="z-n1"
                              >
                                <Avatar
                                  src={(getPostData?.file?.file) ? (getPostData?.file?.file?.user?.thumbnail) : (getPostData?.user?.thumbnail)}
                                  className="author-avtar-details"
                                >
                                  {(getPostData?.file?.file) ? (getPostData?.file?.file?.user?.name?.charAt(0)) : (getPostData?.user?.name?.charAt(0))}
                                </Avatar>
                                <Typography className="fw-400 fs-14">
                                  {(getPostData?.file?.file) ? (getPostData?.file?.file?.user?.name) : (getPostData?.user?.name)}
                                </Typography>
                              </Stack>
                            </Box>
                          </ListItem>
                        </List>
                      </Paper>

                      {postDetailPopoverContent &&

                        <Popover
                          id={postdetailPopoverid}
                          open={openPostDetailPopover}
                          anchorEl={anchorElForPostDetail}
                          onClose={handlePostDetailPopoverClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <List sx={{
                            p: 1,
                            maxHeight: 200,
                            overflow: 'auto',
                          }}>
                            {postDetailPopoverContent.map((value, idx) => (
                              <>
                                <ListItem key={idx}>
                                  <Typography variant="body2">{value}</Typography>
                                </ListItem>
                                <Divider />
                              </>
                            ))}
                          </List>
                        </Popover>

                      }
                    </CustomTabPanel>
                  </Box>

                  {(getPostData?.extra_data && Object.keys(getPostData.extra_data).length > 0) && (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "#FFFFFF",
                          border: "1px solid #F9F9F9",
                          marginTop: "20px"
                        }}
                      >
                        <Box
                          sx={{ borderBottom: 1, borderColor: "divider" }}
                          className="box-attribute-2"
                        >
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            // variant={isTablet && "scrollable"}
                            variant="scrollable"
                            className={"tabs view-type-post-tab"}
                            TabIndicatorProps={{
                              style: {
                                backgroundColor: "#660C60",
                                display: "none"
                              },
                            }}
                          >
                            <Tab
                              label="Data"
                              iconPosition="start"
                              {...a11yProps1(0)}
                            />

                          </Tabs>
                        </Box>
                        <CustomTabPanel value={0} index={value}>
                          <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                            <List container spacing={2}>


                              {
                                Object.entries(getPostData?.extra_data || {}).map(([key, values], index, array) => {
                                  const additionalCount = values?.length - 2;
                                  const remainingValues = values.slice(2);
                                  const isLastItem = index === array.length - 1;

                                  return (
                                    <>
                                      <ListItem key={index} className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                        <ListItemText className="fs-14-muted">
                                          {key}
                                        </ListItemText>
                                        <ListItemText className="fs-14 text-end">
                                          {values.slice(0, 2).join(", ")}
                                          {additionalCount > 0 && (
                                            <span className="badge-count"
                                              onClick={(event) => handlePostDetailPopoverOpen(event, remainingValues)}
                                            >
                                              +{additionalCount} more
                                            </span>
                                          )}
                                        </ListItemText>
                                      </ListItem>
                                      {!isLastItem && <Divider component="li" className="divider-details" />}
                                    </>
                                  )
                                })
                              }

                            </List>
                          </Paper>

                        </CustomTabPanel>
                      </Box>
                    </>
                  )}


                  {getPostData?.related_posts?.length > 0 && (
                    <Box className="mt-4 mb-3 related-post-div">
                      <Typography className="related-text">
                        Related Posts
                      </Typography>
                    </Box>
                  )}

                  {getPostData?.related_posts?.length > 0 && (
                    <>
                      <Box>
                        <Stack
                          spacing={3}
                          direction="column"
                          divider={<Divider orientation="horizontal" />}
                        >
                          {getPostData?.related_posts?.map((related) => (
                            <Box>
                              <Stack direction={"row"} spacing={1}>
                                <Box className="relate-item-img-div">
                                  <Image src={related?.image} />
                                </Box>
                                <Box>
                                  <Stack spacing={1}>
                                    <Typography className="related-post-title text-break">
                                      {related?.title}
                                    </Typography>
                                    <Link
                                      underline="hover"
                                      key="1"
                                      color="inherit"
                                      href="/"
                                      component="h3"
                                      onClick={() => {
                                        if (loggedUser?.user_type === "user") {
                                          localStorage.setItem("last-redirect-page-user", `/user-type/view/${related?.type?.name}/${related?.id}`);
                                          navigate(`/user-type/view/${related?.type?.name}/${related?.id}`);
                                        }
                                        else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                          localStorage.setItem("last-redirect-page-admin-user", `/user-type/view/${related?.type?.name}/${related?.id}`)
                                          navigate(`/user-type/view/${related?.type?.name}/${related?.id}`);
                                        }

                                      }}
                                      className="cursor-pointer related-read-more"
                                    >
                                      Read More{" "}
                                      <FontAwesomeIcon
                                        icon={faChevronRight}
                                        className="related-read-more"
                                      />
                                    </Link>
                                  </Stack>
                                </Box>
                              </Stack>
                            </Box>
                          ))}
                        </Stack>
                      </Box>
                    </>
                  )}

                  {getPostData?.rating_enable == true && (
                    <Box
                      sx={{
                        width: "100%",
                        backgroundColor: "#FFFFFF",
                        border: "1px solid #F9F9F9",
                        marginTop: "20px"
                      }}
                    >
                      <Box
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                        className="box-attribute-2"
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          // variant={isTablet && "scrollable"}
                          variant="scrollable"
                          className={"tabs view-type-post-tab"}
                          TabIndicatorProps={{
                            style: {
                              backgroundColor: "#660C60",
                              display: "none"
                            },
                          }}
                        >
                          <Tab
                            label="How Useful Was This Document?"
                            iconPosition="start"
                            {...a11yProps1(0)}
                          />

                          {/* <Tab
                          label="Views"
                          icon={<FontAwesomeIcon icon={faEye} />}
                          iconPosition="start"
                          {...a11yProps2(1)}
                        />
                        <Tab
                          label="Data"
                          {...a11yProps3(2)}
                          icon={<FontAwesomeIcon icon={faCheck} />}
                          iconPosition="start"
                        /> */}

                        </Tabs>
                      </Box>
                      <CustomTabPanel value={0} index={value}>
                        <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                          <Box className="rating-div">
                            <Rating
                              name="simple-controlled"
                              value={ratingValue}
                              onChange={(event, newValue) => {
                                setRattingValue(newValue);
                                handleRatingAPI(newValue)
                              }}
                              size="large"
                            />
                          </Box>
                        </Paper>

                      </CustomTabPanel>
                      {/* <CustomTabPanel value={1} index={value}>
                      <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                        <Typography className="text-center">Views</Typography>
                      </Paper>
                    </CustomTabPanel>
                    <CustomTabPanel value={2} index={value}>
                      <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                        <Typography className="text-center">Data</Typography>
                      </Paper>
                    </CustomTabPanel> */}
                    </Box>
                  )}

                </>
              )}
            </Grid>
          </Grid>
          <Popper
            open={openForReaction}
            anchorEl={anchorElForReaction}
            placement="top"
            disablePortal
          >
            <Box className="reaction-options" sx={{ display: 'flex', gap: '12px', padding: '10px', background: 'white', border: '1px solid #EEEEEE', borderRadius: '20px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>


              <Box onClick={() => toggleReaction(currentPostId, "like", true)}>
                <FontAwesomeIcon icon={faThumbsUp} className="reaction-icon fa-like-btn" />
              </Box>
              <Box onClick={() => toggleReaction(currentPostId, "love", true)}>
                <FontAwesomeIcon icon={faHeart} className="reaction-icon fa-love-btn" />
              </Box>
              <Box onClick={() => toggleReaction(currentPostId, "smile", true)}>
                <FontAwesomeIcon icon={faSmile} className="reaction-icon fa-smile-btn" />
              </Box>
              <Box onClick={() => toggleReaction(currentPostId, "angry", true)}>
                <FontAwesomeIcon icon={faAngry} className="reaction-icon fa-angry-btn" />
              </Box>
            </Box>
          </Popper>
          <Popper
            open={openForReactionReply}
            anchorEl={anchorElForReplyReaction}
            placement="top"
            disablePortal
          >
            <Box className="reaction-options" sx={{ display: 'flex', gap: '10px', padding: '7px', background: 'white', border: '1px solid #EEEEEE', borderRadius: '20px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>

              <Box onClick={() => {
                toggleLikeForCommentsReaction(currentPostId, "like", currentPostIdForReply, true)
              }}>
                <FontAwesomeIcon icon={faThumbsUp} className="reaction-icon-2 fa-like-btn" />
              </Box>
              <Box onClick={() => toggleLikeForCommentsReaction(currentPostId, "love", currentPostIdForReply, true)}>
                <FontAwesomeIcon icon={faHeart} className="reaction-icon-2 fa-love-btn" />
              </Box>
              <Box onClick={() => toggleLikeForCommentsReaction(currentPostId, "smile", currentPostIdForReply, true)}>
                <FontAwesomeIcon icon={faSmile} className="reaction-icon-2 fa-smile-btn" />
              </Box>
              <Box onClick={() => toggleLikeForCommentsReaction(currentPostId, "angry", currentPostIdForReply, true)}>
                <FontAwesomeIcon icon={faAngry} className="reaction-icon-2 fa-angry-btn" />
              </Box>
            </Box>
          </Popper>
          {isPopupUserExist == true && (
            <Popover
              open={openForPopupUser}
              anchorEl={anchorElForPopupUser}

              onClose={handlePopoverCloseForMentionUser}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              id={idPopupUser}
            >
              <Card className="people-card-wrapper-3" sx={{ maxWidth: 420 }}>
                <CardContent>
                  <Stack direction="vertical" className="gap-10 h-100 align-items-center">
                    <Box className="main-content-data-box">
                      <Stack
                        direction="horizontal"
                        className="d-flex flex-wrap gap-3 w-100 align-items-center justify-content-center"
                      >
                        <Box className="w-100 d-flex align-items-center justify-content-center">
                          {popupUserDetails?.image ? (
                            <Image src={popupUserDetails?.image} className="user-img text-center cursor-pointer" alt={popupUserDetails?.name} onClick={() => {
                              if (loggedUser?.user_type === "user") {
                                localStorage.setItem("last-redirect-page-user", `/user-type/people/profile/view/${popupUserDetails?.id}`);
                                navigate(`/user-type/people/profile/view/${popupUserDetails?.id}`);
                              }
                              else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                localStorage.setItem("last-redirect-page-admin-user", `/user-type/people/profile/view/${popupUserDetails?.id}`)
                                navigate(`/user-type/people/profile/view/${popupUserDetails?.id}`);
                              }

                            }} />
                          ) : (
                            <Image src={DummyUser} className="user-img text-center cursor-pointer" alt={popupUserDetails?.name} onClick={() => {
                              if (loggedUser?.user_type === "user") {
                                localStorage.setItem("last-redirect-page-user", `/user-type/people/profile/view/${popupUserDetails?.id}`);
                                navigate(`/user-type/people/profile/view/${popupUserDetails?.id}`);
                              }
                              else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                                localStorage.setItem("last-redirect-page-admin-user", `/user-type/people/profile/view/${popupUserDetails?.id}`)
                                navigate(`/user-type/people/profile/view/${popupUserDetails?.id}`);
                              }

                            }} />
                          )}

                        </Box>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        className="d-flex flex-wrap gap-3 align-items-center justify-content-center"
                      >
                        <h4
                          className="news-title dark-text fw-400 cursor-pointer text-break"
                          style={{ height: "28px" }}
                          onClick={() => {
                            if (loggedUser?.user_type === "user") {
                              localStorage.setItem("last-redirect-page-user", `/user-type/people/profile/view/${popupUserDetails?.id}`);
                              navigate(`/user-type/people/profile/view/${popupUserDetails?.id}`);
                            }
                            else if (loggedUser?.user_type === "admin" && adminAccessible == true) {
                              localStorage.setItem("last-redirect-page-admin-user", `/user-type/people/profile/view/${popupUserDetails?.id}`)
                              navigate(`/user-type/people/profile/view/${popupUserDetails?.id}`);
                            }

                          }}
                        >
                          {" "}
                          {popupUserDetails?.name}
                        </h4>
                      </Stack>

                      <Stack
                        direction="horizontal"
                        className="d-flex flex-wrap gap-3 mt-2 align-items-center justify-content-center"
                      >
                        <Box className="d-flex gap-4 justify-content-center align-items-center">
                          <FontAwesomeIcon icon={faPhone} className="fs-12-660C60" />
                          <Typography className="fs-14 opacity-50">{popupUserDetails?.phone ? popupUserDetails?.phone : "--"}</Typography>
                        </Box>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        className="d-flex flex-wrap gap-3 mt-1 align-items-center justify-content-center"
                      >
                        <Box className="d-flex gap-4 justify-content-center align-items-center">
                          <FontAwesomeIcon icon={faEnvelopeOpen} className="fs-12-660C60" />
                          <Typography className="fs-14 opacity-50 text-break">{popupUserDetails?.email ? popupUserDetails?.email : "--"}</Typography>

                        </Box>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        className="d-flex flex-wrap gap-3 mt-1 align-items-center justify-content-center"
                      >
                        <Button
                          className="bg-white btn-view-article d-flex gap-3"
                          variant="outlined"
                          size="large"
                          target="_blank"
                          onClick={() => {
                            handlePopoverCloseForMentionUser()
                            window.open(
                              `/user-type/people/profile/view/${popupUserDetails?.id}`,
                              '_blank'
                            );
                          }}
                        >
                          View Profile
                          <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                      </Stack>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Popover >
          )}
          <BootstrapDialog
            onClose={handleCloseForAckQue}
            aria-labelledby="customized-dialog-title"
            open={openAckQuePopup}
            fullWidth
            maxWidth={"sm"}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              {/* Modal title
           */}
              <div className="d-flex align-items-center gap-2">
                <Avatar
                  alt={loggedUser?.name}
                  className="author-avtar-creator"
                  src={loggedUser?.thumbnail}
                >
                  {loggedUser?.name?.charAt(0)}
                </Avatar>
                <span className="fs-14-700">{loggedUser?.name}</span>
              </div>
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseForAckQue}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              className="alert-close-btn"
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers className="no-border">
              <Box>
                <Typography className="fs-20 mt-2 text-center">Acknowledgement Questions</Typography>
              </Box>
              {ackQuestions?.map((que, index) => (
                <>
                  <Box>
                    <Typography className="fs-14 mt-2">{que?.question}</Typography>
                    <TextField
                      className="input-field mt-2"
                      fullWidth
                      id="outlined-basic short summary"
                      variant="outlined"
                      multiline
                      maxRows={2}
                      placeholder={`write answer for ${que?.question}`}
                      value={que?.ans}
                      onChange={(event) => handleInputChangeForAckQuestions(event, index)}
                    />
                  </Box>
                </>
              ))}
            </DialogContent>
            <DialogActions className="p-3">
              <Button
                className="btn primary-btn w-100"
                type="submit"
                onClick={() => {
                  handleAckQuestionsAPI()
                }}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  className="d-md-block d-sm-none d-none"
                />
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="d-md-none d-sm-block"
                />
                Submit
              </Button>
            </DialogActions>
          </BootstrapDialog>
          <Toaster />

          {openEngagementModal && enagagementModalData[0] && (
            <ModalEngagement
              data={enagagementModalData[0]}
              onClose={handleEngagementModalClose}
            />
          )}

          <Loader isLoading={loading} />
        </Container>
      </div>
    </>
  );
};

export default UserTypeViewPage;
