import React, { useState, useEffect, useRef } from "react";
import { Image as BootstrapImage, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faFolder,
  faFile,
  faMessage
} from "@fortawesome/free-solid-svg-icons";
import logoutHelper from "../../../helpers/logoutHelper";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import api from "../../common/commonFunctionForApi";
import {
  Box, Divider, Tabs,
  Tab,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Popper,
  Typography
} from "@mui/material";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Avatar from "@mui/material/Avatar";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { Toast } from "../../../utils/toast";

import PropTypes from "prop-types";
import TableHeaderForSorting from "../../common/TableHeaderForSorting";
import { columns_global_search_header } from "../../../helpers/theaderColumns/globalSearch/globalSearch";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import NoDataFoundImg from "../../../../src/assets/images/NoDataFound.png"
import Loader from "../loader";


function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box-2">{children}</Box>}
    </div>
  );
}

function GlobalSearch() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [globalSearch, setGlobalSearch] = useState("")
  const [globalSearchData, setGlobalSearchData] = useState()
  const [globalSearchDataTabs, setGlobalSearchDataTabs] = useState([])
  const [globalSearchDataOnly, setGlobalSearchDataOnly] = useState([])
  const [globalSearchFilterData, setGlobalSearchFilterData] = useState([])
  const [value, setValue] = useState("all");
  const [dataOrder, setDataOrder] = useState("asc");

  const [openForGlobalSearch, setOpenforGobalSearch] = useState(false)
  const [anchorElForGlobalSearch, setanchorElForGlobalSearch] = useState(null)


  const openMenuforGlobalSearch = Boolean(anchorElForGlobalSearch)
  const idForGlobalSearch = openMenuforGlobalSearch ? "simple-popover" : undefined

  const debounceTimeout = 500;
  let debounceTimer;

  const descriptionElementRef = useRef(null);

  const userToken = localStorage.getItem("accessToken");


  const handleCloseForGlobalSearch = () => {
    setOpenforGobalSearch(false)
    setanchorElForGlobalSearch(null)
    setValue("all")
    setGlobalSearch("")
    setGlobalSearchData()
    setGlobalSearchDataOnly([])
    setGlobalSearchDataTabs([])
    setGlobalSearchFilterData([])
  }

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(order);
    fetchGlobalSearchData(value, true, order);
  };

  const handleChangeForGlobalSearchTab = (newValue) => {
    setValue(newValue);
  }


  const fetchGlobalSearchData = async (type, loading, order) => {
    let data = {
      search: globalSearch,
      type_id: type,
      order: order
    };
    try {
      setLoading(loading)
      const response = await api.post(`global-search`, data, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      console.log("response for global search::", response)
      if (response?.success == true) {
        setGlobalSearchData(response?.response)
        setGlobalSearchDataTabs(response?.response?.Tabs)
        setGlobalSearchDataOnly(response?.response?.post?.data)
        setGlobalSearchFilterData(response?.response?.post?.data)
        setLoading(false)
        setOpenforGobalSearch(true)
      } else {
        setLoading(false)
      }
    } catch (err) {
      console.log("error in handleReactionAPI::", err);
      setLoading(false);

      if (err?.response?.status === 401) {
        const userToken = localStorage.getItem("accessToken");
        logoutHelper(dispatch, navigate, userToken);
      } else if (err?.response?.status === 422) {
        // handlePopoverClose();
        handleCloseForGlobalSearch();
        const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
      }
    }
  }

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className: value == index ? `selected-tab-attribute` : `non-selected-tab-attribute`,
    };
  }

  useEffect(() => {
    if (globalSearch !== "") {
      if (globalSearch.length >= 3) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => {

          fetchGlobalSearchData(value, true, dataOrder);
        }, debounceTimeout);
      }
      else {
        clearTimeout(debounceTimer);
      }

      return () => clearTimeout(debounceTimer);
    }
  }, [globalSearch]);

  useEffect(() => {
    if (openForGlobalSearch) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openForGlobalSearch]);

  useEffect(() => {
    if (globalSearch !== "" || value !== "all") {
      fetchGlobalSearchData(value, false, dataOrder);
    }
  }, [value]);

  return (
    <>
      <ClickAwayListener onClickAway={handleCloseForGlobalSearch}>
        <Box className="serach-box">
          <InputGroup className="">
            <Form.Control
              placeholder="Search Platform..."
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              className="search-input"
              value={globalSearch}
              onChange={(e) => {
                setGlobalSearch(e.target.value)
                setanchorElForGlobalSearch(e.currentTarget)
                console.log("e.target::", e.currentTarget)
              }}
            />
            <Button className="search-btn">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroup>
          <Popper
            id={idForGlobalSearch}
            open={openForGlobalSearch}
            anchorEl={anchorElForGlobalSearch}
            placement="bottom-start"
            className="global-search-popover bg-white"
          >
            <Box
              sx={{
                width: "100%",
              }}
              className="global-search-popover-box"
            >
              <Box
                sx={{ borderBottom: 1, borderColor: "divider" }}
                className="box-attribute global-search-tab-margin"
              >
                <Tabs
                  value={value}
                  onChange={(event, newValue) => {
                    handleChangeForGlobalSearchTab(newValue)
                  }}
                  aria-label="basic tabs example"
                  variant={"scrollable"}
                  className={"tabs"}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#660C60",
                    },
                  }}
                  scrollButtons="auto"
                >
                  {globalSearchDataTabs?.map((tab, index) => (
                    <Tab
                      label={tab?.name}
                      icon={<FontAwesomeIcon icon={tab?.icon} />}
                      iconPosition="start"
                      value={tab?.id}
                      {...a11yProps1(tab?.id)}
                    />
                  ))}

                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={value}>
                <Paper sx={{ width: "100%" }} className="type-list-paper-global-search">
                  <TableContainer>
                    <Table
                      aria-labelledby="tableTitle"
                      stickyHeader
                    >
                      {globalSearchFilterData?.length > 0 ? (
                        <>
                          <TableHeaderForSorting
                            columns={columns_global_search_header}
                            handleChangeDataOrdr={handleChangeDataOrdr}
                            dataOrder={dataOrder}
                          />
                          <TableBody>
                            {globalSearchFilterData?.map((row, index) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={index + 1}
                                className="cursor-pointer"
                                onClick={() => {
                                  if (row?.type.toLowerCase() === "folder") {
                                    localStorage.setItem("type-selected-name-user", row?.post_type?.type_name)
                                    localStorage.setItem("selected-folder-item", row?.folder_id)
                                    window.open(`/user-type/${row?.post_type?.type_name}`, '_blank');
                                  }
                                  else if (row?.type.toLowerCase() === "people") {
                                    window.open(`/user-type/people/profile/view/${row?.id}`, '_blank');
                                  }
                                  else if (row?.type.toLowerCase() === "conversation") {
                                    localStorage.setItem("selected-chat-room-id", row?.chat_room_id);
                                    window.open(`/user-conversation?chat_room=${row?.chat_room_id}&chat_id=${row?.id}`, '_blank')
                                  }
                                  else {
                                    localStorage.setItem("type-selected-name-user", row?.post_type?.type_name)
                                    window.open(`/user-type/view/${row?.type}/${row?.id}`, '_blank');
                                  }
                                }}
                              >
                                <TableCell
                                  align="left"
                                  className={`tbl-body-cell`}
                                >
                                  {row?.type.toLowerCase() === "folder" ? (
                                    <div className="d-flex flex-warp gap-3 align-items-center">
                                      <FontAwesomeIcon icon={faFolder} className="folder-tbl-cell" />
                                      <p
                                        className={`folder-tbl-cell-2 ${row?.status === "deleted" ? "text-muted fw-700" : ""
                                          }`}
                                      >
                                        {row?.name}
                                      </p>
                                    </div>
                                  ) : row?.type.toLowerCase() === "people" ? (
                                    <div className="d-flex flex-warp gap-3 align-items-center">
                                      <Avatar
                                        alt={
                                          row?.name ||
                                          "Admin"
                                        }
                                        src={
                                          row?.profile ||
                                          "/static/images/avatar/1.jpg"
                                        }
                                        className="user-list-avatar position-unset"
                                      />
                                      <p
                                        className={`folder-tbl-cell-2 ${row?.status === "deleted" ? "text-muted fw-700" : ""
                                          }`}
                                      >
                                        {row?.name}
                                      </p>
                                    </div>
                                  ) : row?.type.toLowerCase() === 'conversation' ? (
                                    <div className="d-flex flex-warp gap-3 align-items-center">
                                      <FontAwesomeIcon icon={faMessage} className="folder-tbl-cell" />

                                      <p
                                        className={`folder-tbl-cell-4 ${row?.status === "deleted" ? "text-muted fw-700" : ""
                                          }`}
                                      >
                                        {row?.name}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="d-flex flex-warp gap-3 align-items-center">
                                      <FontAwesomeIcon icon={faFile} className="folder-tbl-cell-3" />
                                      <p
                                        className={`folder-tbl-cell-4 ${row?.status === "deleted" ? "text-muted fw-700" : ""
                                          }`}
                                      >
                                        {row?.name}
                                      </p>
                                    </div>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="left"
                                  className="tbl-body-cell-3 "
                                >
                                  {row?.type.charAt(0).toUpperCase() + row?.type.slice(1)}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </>
                      ) : (
                        <div className="no-data mt-2">
                          <div>
                            <BootstrapImage
                              src={NoDataFoundImg}
                              className="text-center mx-auto no-data-img-2 mt-4"
                            />
                          </div>
                          <Typography component="h5" variant="h5">
                            No data found
                          </Typography>
                        </div>
                      )}
                    </Table>
                  </TableContainer>
                </Paper>
                {globalSearchData?.post?.data?.length > 0 && (
                  <>
                    {globalSearchData?.post?.links?.next !== null && (
                      <>
                        <Divider component="div" role="presentation" />
                        <Box className="p-3 d-flex justify-content-center">
                          <Button
                            className="view-search-result-btn w-50"

                            onClick={() => {
                              handleCloseForGlobalSearch()
                              navigate("/search-result", { state: { searchString: globalSearch, category: value } });
                            }}
                          >
                            <FontAwesomeIcon icon={faTelegram} />
                            View All Search Results
                          </Button>
                        </Box>
                      </>
                    )}
                  </>
                )}


              </CustomTabPanel>
            </Box>
          </Popper>
        </Box>
      </ClickAwayListener>
      {loading && (<Loader isLoading={loading} />)}
    </>
  )
}

export default GlobalSearch