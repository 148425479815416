import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
  Avatar,
  Paper,
  Button,
  Stack,
  Chip,
  Menu,
  MenuItem,
  DialogContentText,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Image } from "react-bootstrap";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faFloppyDisk,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Form, Formik } from "formik";
import { addNewNewTypevalidationSchema } from "../../helpers/validations/types/addNewTypes";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import { Toast } from "../../utils/toast";
import Loader from "../../components/common/loader";
import { useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import InputGroup from "react-bootstrap/InputGroup";
import TablePagination from "@mui/material/TablePagination";
import { CSVLink } from "react-csv";
import {
  faCircleInfo,
  faGripVertical,
  faSearch,
  faFileCsv,
} from "@fortawesome/free-solid-svg-icons";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import Accordion from "react-bootstrap/Accordion";

import { MuiColorInput } from "mui-color-input";

import { columns_types_changelog_header } from "../../helpers/theaderColumns/types/typeChangelog";
import { AntSwitch } from "../../components/common/switch/antSwitch";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import { columns_types_ack_fields_header } from "../../helpers/theaderColumns/types/typeAcknowledgeField";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import activeType from "../../assets/images/active.png";
import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select";
import { typeTabAction } from "../../redux/slices/type/type";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import isEqual from "lodash/isEqual";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { dateWithTime } from "../../helpers/DateTimeFormat/dateTimeFormat";
import { exportXlsx } from "../../helpers/Exports/FileExport";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";

const processedHTML = (htmlContent) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent || ""; // Ensure there's a default value if htmlContent is undefined
  return tempDiv;
};

const AddNewTypes = () => {
  let { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [typeId, setTypeId] = useState(id ? parseInt(id) : "");
  const [getTypeData, setGetTypeData] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getAllChangeLogs, setGetAllChangeLogs] = useState();
  const [search, setSearch] = useState("");
  const [getChangelogCsvData, setGetChangelogCsvData] = useState([]);

  const [typeIcon, setTypeIcon] = useState({
    prefix: "fas",
    iconName: "check",
    icon: [
      448,
      512,
      [10003, 10004],
      "f00c",
      "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z",
    ],
  });
  const [anchorElForEnable, setAnchorElForEnable] = useState(null);
  const [anchorElForSearch, setAnchorElForSearch] = useState(null);
  const [anchorElForFolders, setAnchorElForFolders] = useState(null);
  const [opnAddattributeModel, setOpenAttributeModel] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [getAllAttributes, setGetAllAttributes] = useState([]);
  const [istype, setIstype] = useState(false);
  const [contentHeight, setContentHeight] = useState(false);
  const [selectAttributes, setSelectedAttributes] = useState([]);
  const [anchorElMenu, setAnchorElForMenu] = useState(null);
  const [attributeID, setAttributeID] = useState();
  const [getAllIcons, setGetAllIcons] = useState([]);
  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [previousSelectedOptions, setPreviousSelectedOptions] = useState([]);

  const debounceTimeout = 500; // Adjust the debounce timeout as needed
  let debounceTimer;

  const openMenuFoEdit = Boolean(anchorElMenu);

  const total_changelog = parseInt(getAllChangeLogs?.meta?.total);

  const {
    open,
    selectedItem,
    nameCollapsable,
    typeCollapsable,
    drawerVariant,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  const userToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");

  const ITEM_HEIGHT = 48;

  const openInfoEnable = Boolean(anchorElForEnable);
  const openInfoSearch = Boolean(anchorElForSearch);
  const openInfoFolder = Boolean(anchorElForFolders);

  const initialValues = {
    title: id ? getTypeData?.name : "",
    typeStatus: id ? getTypeData?.typeStatus : true,
    includeSearch: id ? getTypeData?.includeSearch : true,
    enableFolders: id ? getTypeData?.enableFolders : false,
    iconName: id ? getTypeData?.iconName : "",
    iconColor: id ? getTypeData?.iconColor : "#959595",
    iconBGColor: id ? getTypeData?.iconBGColor : "#660C60",
    rowsData: id ? getTypeData?.rowsData : [],
    selectAttributes: id ? getTypeData?.selectAttributes : [],
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    } else if (newPage < page) {
      setPage(newPage);
      fetchAllChangeLogs(newPage + 1);
    }
  };

  const header_csv = [
    { label: "No", key: "no" },
    { label: "User", key: "user" },
    { label: "Date", key: "date" },
    { label: "Area", key: "area" },
    { label: "Details", key: "details" },
  ];

  const handleopnAddattributeModel = () => {
    setOpenAttributeModel(true);
  };

  const handleCloseAddattributeModel = () => {
    setOpenAttributeModel(false);
  };

  const handleopnAlertDeleteModel = () => {
    setOpenAlertDialog(true);
  };

  const handleCloseAlertDeleteModel = () => {
    setOpenAlertDialog(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setAttributeID(row);
  };
  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const getValueBeforeHyphen = (str) => {
    const parts = str.split(" - "); // Split the string into an array based on the hyphen
    return parts[0]; // Return the first element of the array
  };

  const onSubmit = async (values) => {
    if (typeId) {
      const updatedRows = values?.rowsData
        .filter((row) => {
          // Find rows that have changed or are newly added
          const originalRow = getTypeData?.rowsData.find(
            (original) => original.id === row.id
          );
          return !originalRow || !isEqual(originalRow, row);
        })
        .map((data) => ({
          id: data.id,
          name: data.name,
          attrId: data.attrId,
          status: data.status,
          chip: data.chip,
          is_system: data.is_system,
        }));

      const deletedRowIds = getTypeData?.rowsData
        .filter(
          (originalRow) =>
            !values?.rowsData.some((row) => row.attrId === originalRow.attrId)
        )
        .map((deletedRow) => ({
          id: deletedRow?.id,
          name: deletedRow?.name,
          attrId: deletedRow?.attrId,
          status: "Detach", // Add a flag to indicate the row is deleted
          chip: deletedRow?.chip,
          is_system: deletedRow.is_system,
        }));

      const updatedData = [...updatedRows, ...deletedRowIds];
      let data = {
        name: values?.title,
        id: id,
        in_search: values?.includeSearch ? "enable" : "disable",
        is_folder: values?.enableFolders ? "enable" : "disable",
        icon: values?.iconName,
        colour: values?.iconColor,
        background: values?.iconBGColor,
        status: values?.typeStatus ? "enable" : "disable",
        associate_attribute: updatedData,
      };

      try {
        setLoading(true);
        const response = await api.post(
          `/admin/type/master/update/${id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userToken}`,
            },
          }
        );
        if (response?.success == true) {
          setLoading(false);
          setIsSuccessMessage(true);
          setSuccessMessage(response?.message);
          Toast.success(response?.message);
          setGetTypeData();
          dispatch(drawerMenuAction?.handleFetchNewType(true))
          localStorage.setItem("last-redirect-page", `/types`);

          navigate("/types");
        } else {
          setLoading(false);
          setIsWarningMessage(true);
          setWarningMessage(response?.message);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status == 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status === 422) {
          setLoading(false);
          setIsWarningMessage(true);
          setWarningMessage(err?.response?.data?.errors?.name);
          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status == 429) {
          Toast.error("Too many attemps wait for 2 min.")
        }
      }
    } else {
      let data = {
        name: values?.title,
        in_search: values?.includeSearch == true ? "enable" : "disable",
        is_folder: values?.enableFolders == true ? "enable" : "disable",
        icon: values?.iconName,
        colour: values?.iconColor,
        background: values?.iconBGColor,
        status: values?.typeStatus == true ? "enable" : "disable",
        associate_attribute: values?.rowsData?.map((data) => {
          return {
            id: data?.id,
            name: data?.name,
            attrId: data?.attrId,
            status: data?.status,
            chip: data?.chip,
            is_system: data.is_system,
          };
        }),
      };

      try {
        setLoading(true);
        const response = await api.post("/admin/type/master/store", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userToken}`,
          },
        });
        if (response?.success == true) {
          setLoading(false);
          setIsSuccessMessage(true);
          setSuccessMessage(response?.message);
          setIsSuccessMessage(true);
          setSuccessMessage(response?.message);
          Toast.success(response?.message);
          localStorage.setItem("last-redirect-page", `/types`);
          dispatch(drawerMenuAction?.handleFetchNewType(true))
          dispatch(typeTabAction.handleSelectedTab(0));
          navigate("/types");
        } else if (response?.success == false) {
          setLoading(false);
          dispatch(typeTabAction.handleSelectedTab(0));
          setIsWarningMessage(true);
          setWarningMessage(response?.message);
          Toast.error(response?.message);
        }
      } catch (err) {
        console.log("error in ctreate news api::", err);
        setLoading(false);

        if (err?.response?.status == 401) {
          setLoading(false);
          logoutHelper(dispatch, navigate, userToken);
        }
        if (err?.response?.status == 422) {
          setLoading(false);
          setIsWarningMessage(true);
          setWarningMessage(err?.response?.data?.errors?.name);
          const errorData = Object.values(err?.response?.data?.errors).forEach(value => Toast.error(value));
        }
        if (err?.response?.status == 429) {
          Toast.error("Too many attemps wait for 2 min.")
        }
      }
    }
  };

  const fetchTypeDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/type/master/${parseInt(id)}}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        const news = {
          name: response?.response?.name,
          includeSearch:
            response?.response?.in_search === "enable" ? true : false,
          enableFolders:
            response?.response?.is_folder === "enable" ? true : false,
          iconName: response?.response?.icon,
          iconColor: response?.response?.colour,
          iconBGColor: response?.response?.background,
          typeStatus: response?.response?.status === "enable" ? true : false,
          rowsData: response?.response?.type_high_attributes?.map((data) => {
            return {
              id: data?.fe_highlightId,
              name: data?.name,
              attrId: data?.attrId,
              status: data?.status,
              chip: data?.chip,
              is_system: data.is_system,
            };
          }),
          selectAttributes: response?.response?.type_high_attributes?.map(
            (data) => {
              return {
                value: data?.attrId,
                label: data?.name,
                isdetach: data?.status !== "Detach" ? false : true,
                status: data?.status,
                chip: data?.chip,
                is_system: data.is_system,
              };
            }
          ),
        };
        setPreviousSelectedOptions(
          response?.response?.type_high_attributes?.map((data) => {
            return {
              value: data?.attrId,
              label: data?.name,
              isdetach: data?.status !== "Detach" ? false : true,
              status: data?.status,
              chip: data?.chip,
              is_system: data.is_system,
            };
          })
        );
        setGetTypeData(news);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/types`);

        navigate("/types");
      }
    } catch (err) {
      console.log("error in fetch user api::", err?.response?.data?.message);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);

        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);

        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        setWarningMessage(err?.response?.data?.message);
        Toast.warning(err?.response?.data?.message);
        localStorage.setItem("last-redirect-page", `/types`);

        navigate("/types");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchAllChangeLogs = async (page) => {
    try {
      setLoading(true);
      const response = await api.get(
        `admin/type/master/changelog/${parseInt(
          id
        )}}?per_page=${rowsPerPage}&page=${page}&search=${search}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );
      if (response?.success == true) {
        response.response.data = response?.response?.data.map((log) => ({
          ...log,
          changed_at: dateWithTime(log.changed_at, true, true, true) || log.changed_at,
        }));
        setGetAllChangeLogs(response?.response);

        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  const fetchAllChangeLogsForExportCSV = async (page, pagination) => {
    try {
      setLoading(true);
      const result = await exportXlsx(`admin/type/master/changelog/${parseInt(id
      )}}?per_page=${rowsPerPage}&page=${page}&search=${search}&pagination=no`, 'Type_change_log');
      if (!result) {
        Toast.error("Failed to download file");
      }
    } catch (err) {
      console.error("Error while downloading csv changelog::", err);
      Toast.error("Failed to download file");
    } finally {
      setLoading(false);
    }
  };

  const fetchAllAttributes = async (type, order) => {
    try {
      setLoading(true);
      const response = await api.get(`admin/attribute-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        const data = response?.response?.map((attributeData) => {
          return {
            value: attributeData?.id,
            label: attributeData?.title,
            isdetach: false,
            status: "Mandatory",
            chip: attributeData?.chip,
            is_system: attributeData?.is_system,
          };
        });
        const systemAttributes = data.filter((attr) => attr.is_system === 1);
        const addedAttributes = data.filter((attr) => attr.is_system === 0);

        const allAttributes = [
          { label: "Added Attributes", options: addedAttributes },
          { label: "System Attributes", options: systemAttributes },
        ];
        setGetAllAttributes(allAttributes);
        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        Toast.error(response?.message);
      }
    } catch (err) {
      console.log("err`or in fetch news api::", err);
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const fetchAllIcons = async () => {
    try {
      setLoading(true);
      const response = await api.get(`admin/icon-list`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setGetAllIcons(response?.response);

        setLoading(false);
      } else if (response?.success == false) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
    } catch (err) {
      console.log("error in fetch user api::", err);
      setLoading(false);
      if (err?.response?.status === 401) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.message);
        Toast.warning(err?.response?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status === 403) {
        setLoading(false);
        setIsWarningMessage(true);
        setWarningMessage(err?.response?.data[0]?.message);
        Toast.warning(err?.response?.data[0]?.message);
        localStorage.setItem("last-redirect-page", `/`);

        navigate("/");
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    }
  };

  useEffect(() => {
    if (parseInt(id)) {
      fetchTypeDetails();
      fetchAllAttributes();
      fetchAllIcons();

      fetchAllChangeLogs(page + 1);
      fetchAllAttributes();
    } else {
      fetchAllAttributes();
      fetchAllIcons();
    }
  }, [parseInt(id)]);

  useEffect(() => {
    if (search.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        setPage(0);
        fetchAllChangeLogs(page);
      }, debounceTimeout);
    }
    else if (search === "") {
      setPage(0);
      fetchAllChangeLogs(page);
    }
    else {
      clearTimeout(debounceTimer);
    }

    return () => clearTimeout(debounceTimer);
  }, [search]);

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage == true && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}

          <Box className="">
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href="/"
                  component="h3"
                >
                  Admin
                </Link>
                <Typography
                  key="3"
                  component="h3"
                  color="inherit"
                  className="cursor-pointer"
                  onClick={() => {
                    localStorage.setItem("last-redirect-page", `/types`);

                    navigate("/types");
                  }}
                >
                  Types
                </Typography>
                <Typography key="3" component="h3" color="text.primary">
                  {typeId ? "Edit" : "Add"} Type
                </Typography>
              </Breadcrumbs>
            </Stack>
          </Box>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialValues}
            validationSchema={addNewNewTypevalidationSchema}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {({
              values,
              setFieldValue,
              touched,
              errors,
              handleSubmit,
              handleBlur,
              isValid,
              dirty,
            }) => {
              const handleChangeForIconColor = (newValue) => {
                setFieldValue("iconColor", newValue);
              };

              const handleChangeForIconBGColor = (newValue) => {
                setFieldValue("iconBGColor", newValue);
              };

              const handleAddAttributes = () => {
                const attributes = values?.selectAttributes?.map(
                  (attribute, index) => ({
                    id: index + 1, // Use the id counter for each attribute
                    name: attribute.label,
                    attrId: attribute.value,
                    status: attribute.status || "Mandatory",
                    chip: attribute?.chip,
                    is_system: attribute.is_system,
                  })
                );
                setFieldValue("rowsData", attributes);
                handleCloseAddattributeModel();
              };

              const handleRowDataChange = (
                id,
                field,
                value,
                attId,
                oldStatus
              ) => {
                let updatedRowsData;
                const detchRecords = values?.rowsData?.filter(
                  (rows) => rows?.status !== "Detach"
                );
                handleClose();
                if (detchRecords?.length < 2 && value === "Detach") {
                  setIsWarningMessage(true);
                  setWarningMessage("You need to assign minimum one attribute");
                  return Toast.error(
                    "You need to assign minimum one attribute"
                  );
                }
                if (value === "Detach") {
                  let updatedSelectedAttributes;
                  let updatedRowsData;
                  updatedRowsData = values?.rowsData
                    .filter((row) => row.id !== id)
                    .map((attribute, index) => ({
                      id: index + 1, // Use the id counter for each attribute
                      name: attribute.name,
                      attrId: attribute.attrId,
                      status: attribute.status,
                      chip: attribute?.chip,
                      is_system: attribute.is_system,
                    }));

                  updatedSelectedAttributes = values?.selectAttributes.map(
                    (row, index) =>
                      row.value === attId
                        ? {
                          ...row,
                          isdetach: true,
                          [field]: oldStatus,
                          id: index + 1,
                        }
                        : row
                  );
                  setSelectedAttributes(updatedSelectedAttributes);

                  setFieldValue("rowsData", updatedRowsData);
                  updatedSelectedAttributes = values?.selectAttributes?.filter(
                    (attributes) => attributes?.value !== attId
                  );
                  setFieldValue("selectAttributes", updatedSelectedAttributes);
                  setPreviousSelectedOptions(updatedSelectedAttributes);

                  handleClose();
                  handleCloseAlertDeleteModel();
                  setLoading(false);
                } else {
                  updatedRowsData = values?.rowsData.map((row, index) =>
                    row.id === id ? { ...row, [field]: value } : row
                  );
                  setFieldValue("rowsData", updatedRowsData);
                  let updatedSelectedAttributes;
                  updatedSelectedAttributes = values?.selectAttributes.map(
                    (row, index) =>
                      row.value === attId ? { ...row, [field]: value } : row
                  );
                  setFieldValue("selectAttributes", updatedSelectedAttributes);
                  setPreviousSelectedOptions(updatedSelectedAttributes);
                  setLoading(false);
                  handleClose();
                  handleCloseAlertDeleteModel();
                }
                handleClose();
                handleCloseAlertDeleteModel();
              };

              const handleDragEnd = (result) => {
                if (!result.destination) {
                  return;
                }

                const updatedRowsData = Array.from(values.rowsData);
                const [removed] = updatedRowsData.splice(
                  result.source.index,
                  1
                );
                updatedRowsData.splice(result.destination.index, 0, removed);

                // Update the IDs based on the new positions
                const updatedRowsWithNewIds = updatedRowsData.map(
                  (row, index) => ({
                    ...row,
                    id: index + 1, // Assign new IDs based on the index position (+1 to start from 1)
                  })
                );

                setFieldValue("rowsData", updatedRowsWithNewIds);
              };

              return (
                <Form>
                  <Row className="mt-1">
                    <Col lg="9" md="7">
                      <Stack direction="row" className="gap-30">
                        <Accordion
                          defaultActiveKey={["0", "1", "2"]}
                          alwaysOpen
                          className="w-100"
                        >
                          <Accordion.Item
                            eventKey="0"
                            className="bg-white card-wrapper w-100"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text">
                              Content
                            </Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <Col lg="12">
                                  <Typography className="fw-400 dark-text">
                                    Type Title
                                    <span className="required">*</span>
                                  </Typography>
                                  <TextField
                                    className="input-field mt-2"
                                    fullWidth
                                    id="outlined-basic username"
                                    variant="outlined"
                                    size="small"
                                    name="title"
                                    placeholder="Enter title here..."
                                    value={values?.title}
                                    onChange={(event) => {
                                      setFieldValue(
                                        "title",
                                        event.target.value
                                      );
                                    }}
                                    helperText={touched.title && errors.title}
                                    error={
                                      touched.title && Boolean(errors.title)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="1"
                            className="bg-white card-wrapper w-100 mt-4"
                          >
                            <Accordion.Header className="bg-white fw-700 primary-text ">
                              Highlighted Attributes
                            </Accordion.Header>
                            <Accordion.Body>
                              {" "}
                              <Row className="">
                                <TableContainer className="z-0">
                                  <Table
                                    aria-labelledby="tableTitle"
                                    stickyHeader
                                  >
                                    <TableHeaderForSorting
                                      columns={columns_types_ack_fields_header}
                                    />
                                    <DragDropContext onDragEnd={handleDragEnd}>
                                      <Droppable droppableId="rowsData">
                                        {(provided) => (
                                          <TableBody
                                            style={{
                                              width: "100%",
                                              top: 0,
                                              left: 0,
                                            }}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                          >
                                            {values?.rowsData?.length > 0 && (
                                              <>
                                                {values?.rowsData?.map(
                                                  (rows, index) => (
                                                    <Draggable
                                                      key={rows.id}
                                                      draggableId={rows.id.toString()}
                                                      index={index}
                                                    >
                                                      {(provided) => (
                                                        <>
                                                          {rows?.status !==
                                                            "Detach" && (
                                                              <>
                                                                <TableRow
                                                                  hover
                                                                  role="checkbox"
                                                                  tabIndex={-1}
                                                                  key={index}
                                                                  className="cursor-pointer tbl-row-attribute-row"
                                                                  ref={
                                                                    provided.innerRef
                                                                  }
                                                                  {...provided.draggableProps}
                                                                  {...provided.dragHandleProps}
                                                                >
                                                                  <TableCell
                                                                    align="left"
                                                                    className={` tbl-border-left tbl-cell-attribute-2`}
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      top: 0,
                                                                      left: 0,
                                                                    }}
                                                                  >
                                                                    <div className="main-div-icon-text d-flex align-items-center gap-2">
                                                                      <FontAwesomeIcon
                                                                        icon={
                                                                          faGripVertical
                                                                        }
                                                                        className="attribute-td-icon"
                                                                      />
                                                                      <div className="d-flex flex-wrap gap-2 ps-1">
                                                                        <Typography className="fs-14">
                                                                          {
                                                                            rows?.name
                                                                          }
                                                                        </Typography>
                                                                        <Chip
                                                                          label={
                                                                            rows?.chip
                                                                          }
                                                                          className="chip-label-div-2"
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </TableCell>
                                                                  <TableCell
                                                                    align="right"
                                                                    className={` tbl-border-right tbl-cell-attribute-2`}
                                                                    style={{
                                                                      width:
                                                                        "100%",
                                                                      top: 0,
                                                                      left: 0,
                                                                    }}
                                                                  >
                                                                    <div className="d-flex fex-wrap justify-content-between align-items-center gap-2 w-100">
                                                                      {rows?.status ===
                                                                        "Mandatory" ? (
                                                                        <Image
                                                                          src={
                                                                            activeType
                                                                          }
                                                                          width={
                                                                            "10px"
                                                                          }
                                                                          height={
                                                                            "10px"
                                                                          }
                                                                          className="ms-5"
                                                                        />
                                                                      ) : (
                                                                        <FontAwesomeIcon
                                                                          icon={
                                                                            faXmark
                                                                          }
                                                                          className={`cross-icon ms-5`}
                                                                        />
                                                                      )}

                                                                      <IconButton
                                                                        aria-label="delete"
                                                                        className="action-btn-icon-btn"
                                                                        color="error"
                                                                        aria-controls={
                                                                          openMenuFoEdit
                                                                            ? "long-menu"
                                                                            : undefined
                                                                        }
                                                                        aria-expanded={
                                                                          openMenuFoEdit
                                                                            ? "true"
                                                                            : undefined
                                                                        }
                                                                        aria-haspopup="true"
                                                                        onClick={(
                                                                          e
                                                                        ) =>
                                                                          handleClick(
                                                                            e,
                                                                            rows
                                                                          )
                                                                        }
                                                                      >
                                                                        <MoreVertIcon className="action-btn" />
                                                                      </IconButton>
                                                                    </div>
                                                                  </TableCell>
                                                                </TableRow>
                                                                <TableRow className="extra-row"></TableRow>
                                                              </>
                                                            )}
                                                        </>
                                                      )}
                                                    </Draggable>
                                                  )
                                                )}
                                              </>
                                            )}
                                            {errors?.rowsData &&
                                              touched.rowsData && (
                                                <p
                                                  style={{ color: "#d32f2f" }}
                                                  className="error-msg"
                                                >
                                                  {errors?.rowsData}
                                                </p>
                                              )}
                                          </TableBody>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                  </Table>
                                </TableContainer>
                              </Row>
                              <div className="mt-4 d-flex flex-wrap justify-content-end">
                                <Button
                                  variant="outlined"
                                  startIcon={<AddIcon />}
                                  className="add-option-btn"
                                  onClick={handleopnAddattributeModel}
                                >
                                  Add Attributes
                                </Button>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          {id && (
                            <Accordion.Item
                              eventKey="2"
                              className="bg-white card-wrapper mt-4"
                            >
                              <Accordion.Header className="bg-white fw-700 primary-text">
                                Changelog
                              </Accordion.Header>
                              <Accordion.Body className="p-0 changelog-body">
                                <Grid
                                  container
                                  spacing={1}
                                  className="changelog-div"
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={9}
                                    xl={9}
                                  >
                                    <Box className="">
                                      <InputGroup className="">
                                        <TextField
                                          className="search-changelog-input"
                                          fullWidth
                                          id="outlined-basic username"
                                          variant="outlined"
                                          size="small"
                                          name="attributeTitle"
                                          value={search}
                                          placeholder="search changelog"
                                          onChange={(e) => {
                                            setIstype(true);

                                            setSearch(e.target.value);
                                            if (e.target.value === "") {
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                          onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                              e.preventDefault();
                                              fetchAllChangeLogs(page);
                                            }
                                          }}
                                        />
                                        <Button
                                          className="search-changelog"
                                          onClick={() => {
                                            fetchAllChangeLogs(page);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faSearch}
                                            className="search-changelog-icon"
                                          />
                                        </Button>
                                      </InputGroup>
                                    </Box>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sm={12}
                                    lg={3}
                                    xl={3}
                                  >
                                    <div className="d-flex flex-wrap w-100 h-100 align-items-center">
                                      <Button
                                        variant="outlined"
                                        startIcon={
                                          <FontAwesomeIcon icon={faFileCsv} />
                                        }
                                        className="export-csv-btn d-flex justify-content-center"
                                        onClick={fetchAllChangeLogsForExportCSV}
                                      >
                                        Export as CSV
                                      </Button>
                                    </div>
                                  </Grid>
                                </Grid>
                                <Paper
                                  sx={{ width: "100%" }}
                                  className="changelog-table-div mt-4"
                                >
                                  <TableContainer className="z-0">
                                    <Table
                                      sx={{ minWidth: 750 }}
                                      aria-labelledby="tableTitle"
                                      stickyHeader
                                    >
                                      <TableHeaderForSorting
                                        columns={columns_types_changelog_header}
                                      />
                                      <TableBody>
                                        {getAllChangeLogs?.data?.map((data) => (
                                          <>
                                            <TableRow
                                              hover
                                              role="checkbox"
                                              tabIndex={-1}
                                              className=" tbl-changelog mt-2"
                                            >
                                              <TableCell
                                                align="left"
                                                className="tbl-border-left tbl-cell-5"
                                              >
                                                <Box>
                                                  <Stack
                                                    direction="row"
                                                    alignContent="center"
                                                    alignItems="center"
                                                    spacing={1}
                                                    className="z-n1"
                                                  >
                                                    <Avatar
                                                      alt={data?.changed_by}
                                                      src="/static/images/avatar/1.jpg"
                                                      className="author-avtar"
                                                    />
                                                    <Typography className="fw-400 fs-14 ">
                                                      {data?.changed_by}
                                                    </Typography>
                                                  </Stack>
                                                </Box>
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className="text-nowrap"
                                              >
                                                {data?.changed_at}
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className="text-nowrap"
                                              >
                                                {data?.area}
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                className="tbl-border-right"
                                              >
                                                {data?.description}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow className="extra-row"></TableRow>
                                          </>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </Paper>
                                {total_changelog > rowsPerPage && (
                                  <TablePagination
                                    component="div"
                                    count={total_changelog}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                    labelDisplayedRows={({
                                      from,
                                      to,
                                      count,
                                    }) => {
                                      const currentPage = page + 1;
                                      const start =
                                        currentPage * rowsPerPage -
                                        rowsPerPage +
                                        1;
                                      const end = Math.min(
                                        currentPage * rowsPerPage,
                                        count
                                      );
                                      return (
                                        <>
                                          {`Showing ${start} to ${end} of `}
                                          <span>{count}</span>
                                          {" records"}
                                        </>
                                      );
                                    }}
                                  />
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </Stack>
                    </Col>
                    <Col lg="3" md="5">
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        gap={2}
                        flexWrap="wrap"
                        className="publish-div"
                      >
                        <Card className="bg-white card-wrapper w-100">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Publish
                          </Card.Header>
                          <Card.Body className="p-15 card-publish">
                            <Button
                              className="btn primary-btn w-100"
                              type="submit"
                            >
                              <FontAwesomeIcon icon={faBullhorn} />
                              Publish
                            </Button>
                            <div>
                              <div className="mt-3 switch-items-1">
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  gap={2}
                                  flexWrap="wrap"
                                >
                                  <div className="d-flex flex-wrap align-items-center gap-3">
                                    <Typography
                                      className="fw-400 dark-text font-inter "
                                      whiteSpace="nowrap"
                                    >
                                      Enable Type
                                    </Typography>
                                    <BootstrapTooltip
                                      title="You can enable/disable type."
                                      placement="bottom"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="mandatory-icon fw-900"
                                        aria-owns={
                                          openInfoEnable
                                            ? "mouse-over-popover"
                                            : undefined
                                        }
                                        aria-haspopup="true"
                                      />
                                    </BootstrapTooltip>
                                  </div>

                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    className="h-100"
                                  >
                                    <AntSwitch
                                      name="typeStatus"
                                      checked={values?.typeStatus}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "typeStatus",
                                          event.target.checked
                                        );
                                      }}
                                      inputProps={{
                                        "aria-label": "ant design",
                                      }}
                                    />
                                  </Stack>
                                </Stack>
                              </div>
                              <div className="mt-3 switch-items">
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  gap={2}
                                  flexWrap="wrap"
                                >
                                  <div className="d-flex flex-wrap align-items-center gap-3">
                                    <Typography
                                      className="fw-400 dark-text font-inter "
                                      whiteSpace="nowrap"
                                    >
                                      Include in Search
                                    </Typography>
                                    <BootstrapTooltip
                                      title="You can allow type for searching."
                                      placement="bottom"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="mandatory-icon fw-900"
                                        aria-owns={
                                          openInfoSearch
                                            ? "mouse-over-popover"
                                            : undefined
                                        }
                                        aria-haspopup="true"
                                      />
                                    </BootstrapTooltip>
                                  </div>

                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    className="h-100"
                                  >
                                    <AntSwitch
                                      name="includeSearch"
                                      checked={values?.includeSearch}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "includeSearch",
                                          event.target.checked
                                        );
                                      }}
                                      inputProps={{
                                        "aria-label": "ant design",
                                      }}
                                    />
                                  </Stack>
                                </Stack>
                              </div>
                              <div className="mt-3 switch-items">
                                <Stack
                                  direction="row"
                                  justifyContent="space-between"
                                  gap={2}
                                  flexWrap="wrap"
                                >
                                  <div className="d-flex flex-wrap align-items-center gap-3">
                                    <Typography
                                      className="fw-400 dark-text font-inter "
                                      whiteSpace="nowrap"
                                    >
                                      Enable Folders
                                    </Typography>
                                    <BootstrapTooltip
                                      title="You can enable/disable folders."
                                      placement="bottom"
                                    >
                                      <FontAwesomeIcon
                                        icon={faCircleInfo}
                                        className="mandatory-icon fw-900"
                                        aria-owns={
                                          openInfoFolder
                                            ? "mouse-over-popover"
                                            : undefined
                                        }
                                        aria-haspopup="true"
                                      />
                                    </BootstrapTooltip>
                                  </div>

                                  <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    className="h-100"
                                  >
                                    <AntSwitch
                                      name="enableFolders"
                                      checked={values?.enableFolders}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "enableFolders",
                                          event.target.checked
                                        );
                                      }}
                                      inputProps={{
                                        "aria-label": "ant design",
                                      }}
                                    />
                                  </Stack>
                                </Stack>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                        <Card className="bg-white card-wrapper">
                          <Card.Header className="bg-white fw-700 primary-text">
                            Customization
                          </Card.Header>
                          <Card.Body className="p-15">
                            <Typography component="p" className="fs-14">
                              Icon
                            </Typography>
                            <Stack
                              direction="row"
                              className="d-flex flex-wrap gap-1 mt-2"
                            >
                              {getAllIcons?.map((icon, index) => (
                                <div
                                  className={`icon-div d-flex justify-content-center align-items-center icons-div ${values?.iconName === icon?.id &&
                                    "selected-icon"
                                    }`}
                                  key={index}
                                  onClick={() => {
                                    setTypeIcon(icon);
                                    setFieldValue("iconName", icon?.id);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={icon?.icon_value}
                                    className="icon"
                                  />
                                </div>
                              ))}
                              {errors?.iconName && touched.iconName && (
                                <p
                                  style={{ color: "#d32f2f" }}
                                  className="error-msg"
                                >
                                  {errors?.iconName}
                                </p>
                              )}
                            </Stack>
                            <div className="mt-3">
                              <Stack className="customization-icon-color">
                                <Typography
                                  className="fw-400 dark-text font-inter "
                                  whiteSpace="nowrap"
                                >
                                  Color
                                </Typography>

                                <MuiColorInput
                                  format="hex"
                                  value={values?.iconColor}
                                  onChange={handleChangeForIconColor}
                                  className="icon-color-picker"
                                  fullWidth
                                  id="outlined-basic username"
                                  variant="outlined"
                                  size="small"
                                  name="iconColor"
                                />
                              </Stack>
                              {errors?.iconColor && touched.iconColor && (
                                <p
                                  style={{ color: "#d32f2f" }}
                                  className="error-msg"
                                >
                                  {errors?.iconColor}
                                </p>
                              )}
                            </div>
                            <div className="mt-3">
                              <Stack className="customization-icon-color">
                                <Typography
                                  className="fw-400 dark-text font-inter "
                                  whiteSpace="nowrap"
                                >
                                  Background
                                </Typography>

                                <MuiColorInput
                                  format="hex"
                                  value={values?.iconBGColor}
                                  onChange={handleChangeForIconBGColor}
                                  className="icon-color-picker2"
                                  fullWidth
                                  id="outlined-basic username"
                                  variant="outlined"
                                  size="small"
                                  name="iconBGColor"
                                />
                              </Stack>
                              {errors?.iconBGColor && touched.iconBGColor && (
                                <p
                                  style={{ color: "#d32f2f" }}
                                  className="error-msg"
                                >
                                  {errors?.iconBGColor}
                                </p>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </Stack>
                    </Col>
                  </Row>
                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={opnAddattributeModel}
                    maxWidth="sm"
                    fullWidth={true}
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                      className="dialog-title"
                    >
                      Add Attribute
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseAddattributeModel}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      className="alert-close-btn"
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent
                      dividers
                      className={`${contentHeight == true && "model-div-height"
                        }`}
                    >
                      <Select
                        closeMenuOnSelect={false}
                        options={getAllAttributes}
                        isMulti
                        onMenuOpen={() => setContentHeight(true)}
                        onMenuClose={() => setContentHeight(false)}
                        menuPlacement="bottom"
                        name="IRNotViewUsers"
                        placeholder="Select attributes..."
                        menuPosition="fixed"
                        onChange={(selectedOption) => {
                          // If id is present, merge new selection with previous selection
                          if (id) {
                            const mergedOptions = [
                              ...previousSelectedOptions, // Retain previous selection
                              ...selectedOption, // Add newly selected options
                            ];
                            // Filter out duplicate options by value
                            const uniqueOptions = mergedOptions.filter(
                              (option, index, self) =>
                                index ===
                                self.findIndex((o) => o.value === option.value)
                            );
                            // Update state with merged and unique options
                            setFieldValue("selectAttributes", uniqueOptions);
                          } else {
                            // If id is not present, update state with new selection only
                            setFieldValue("selectAttributes", selectedOption);
                          }
                        }}
                        value={values?.selectAttributes}
                        className=""
                        classNamePrefix="select"
                        styles={{
                          option: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isSelected
                              ? "#660c605c"
                              : provided.backgroundColor,
                            color: state.isSelected
                              ? "var(--dark-color)"
                              : provided.color,
                          }),
                          multiValueLabel: (provided, state) => {
                            return {
                              ...provided,
                              color:
                                state.data?.is_system === 1
                                  ? "rgb(0, 135, 90)"
                                  : "rgb(0, 82, 204)",
                            };
                          },
                        }}
                      />
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                      <Button
                        className="btn delete-btn-model"
                        onClick={handleCloseAddattributeModel}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                        Cancel
                      </Button>
                      <Button
                        className="btn primary-btn"
                        onClick={handleAddAttributes}
                      >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        Save Changes
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    aria-labelledby="customized-dialog-title"
                    open={openAlertDialog}
                    maxWidth="sm"
                    fullWidth={true}
                  >
                    <DialogTitle
                      sx={{ m: 0, p: 2 }}
                      id="customized-dialog-title"
                      className="dialog-title"
                    >
                      Are you Sure ?
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={handleCloseAlertDeleteModel}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                      className="alert-close-btn"
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent
                      dividers
                      className={`${contentHeight == true && "model-div-height"
                        }`}
                    >
                      <DialogContentText id="alert-dialog-description">
                        Are you sure want to detached the attribute ?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
                      <Button
                        className="btn delete-btn-model"
                        onClick={handleCloseAlertDeleteModel}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                        No
                      </Button>
                      <Button
                        className="btn primary-btn"
                        onClick={() =>
                          handleRowDataChange(
                            attributeID?.id,
                            "status",
                            "Detach",
                            attributeID?.attrId,
                            attributeID?.status
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faFloppyDisk} />
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Menu
                    anchorEl={anchorElMenu}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    open={openMenuFoEdit}
                    anchorOrigin={"right"}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: "20ch",
                        zIndex: "1",
                      },
                    }}
                  >
                    {attributeID?.status == "Mandatory" && (
                      <MenuItem
                        onClick={() => {
                          handleRowDataChange(
                            attributeID?.id,
                            "status",
                            "Optional",
                            attributeID?.attrId,
                            attributeID?.status
                          );
                        }}
                      >
                        Optional
                      </MenuItem>
                    )}
                    {attributeID?.status == "Optional" && (
                      <MenuItem
                        onClick={() =>
                          handleRowDataChange(
                            attributeID?.id,
                            "status",
                            "Mandatory",
                            attributeID?.attrId,
                            attributeID?.status
                          )
                        }
                      >
                        Mandatory
                      </MenuItem>
                    )}
                    {id && (
                      <MenuItem
                        onClick={() => {
                          const detchRecords = values?.rowsData?.filter(
                            (rows) => rows?.status !== "Detach"
                          );
                          handleClose();
                          if (detchRecords?.length < 2) {
                            setIsWarningMessage(true);
                            setWarningMessage(
                              "You need to assign minimum one attribute"
                            );
                            handleClose();
                            return Toast.error(
                              "You need to assign minimum one attribute"
                            );
                          }
                          handleopnAlertDeleteModel();
                        }}
                      >
                        Detach
                      </MenuItem>
                    )}
                  </Menu>
                </Form>
              );
            }}
          </Formik>
        </Container>
      </div>

      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default AddNewTypes;
