import { Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import LinksList from "../pages/links";

const linkRoutes = [
    {
        path: "/links",
        element: (
            <Suspense>
                <AuthGuard>
                    <AuthGuardForSuperAdmin>
                        <LinksList />
                    </AuthGuardForSuperAdmin>
                </AuthGuard>
            </Suspense>
        ),
    },
];

export default linkRoutes;
