import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, Image, Toast } from "react-bootstrap";
import {
    TextField,
    Typography,
    Tabs,
    Tab,
    Box,
    IconButton,
    Breadcrumbs,
    Link,
    Divider,
    Stack,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Grid,
    Button,

} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Loader from "../../components/common/loader";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import { pageAction } from "../../redux/slices/page/page";


const PreviewPage = () => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {
        open,
        selectedItem,
        nameCollapsable,
        typeCollapsable,
        drawerVariant,
        usersCollapsable,
    } = useSelector((state) => state?.drawerMenu);

    const [loading, setLoading] = useState(false)
    const [userMenuLists, setUserMenuLists] = useState([])
    const [expandedMenus, setExpandedMenus] = useState({});
    const [inksData, setLinksData] = useState([])

    const { html, css, js, style, menu, link } = useSelector((state) => state?.page);

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);
    const userToken = localStorage.getItem("accessToken");


    const fetchUserMenuList = async (menuID) => {
        console.log("line 58")
        setLoading(true)
        try {
            const response = await api.post(
                `user/admin-menu`,
                { menu_id: menuID },
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );

            if (response?.success) {
                console.log("Menu details fetched successfully");
                const flattenedArray = [
                    ...new Map(
                        response?.response?.flat().map((item) => [item.id, item])
                    ).values(),
                ];
                console.log("Flattened Menu Items:", flattenedArray);

                // Return updated menu items
                setUserMenuLists(flattenedArray)
                setLoading(false)

            } else {
                console.error("Failed to fetch menu details:", response?.error);
                Toast.error(response?.message);
                return []; // Return empty array on failure
            }
        } catch (err) {
            console.error("Error fetching menu details:", err);
            return []; // Return empty array on error
        }
    }

    const fetchTemplate = async () => {
        setLoading(true)
        try {
            const response = await api.get(`${loggedUser?.user_type === "admin" ? "admin" : "user"}/page/view-page/${slug} `, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            console.log("line 105:::", response?.response?.link)
            if (response?.success === true) {
                const htmlData = response?.response?.editor_html
                const cssData = response?.response?.editor_css
                const jsData = response?.response?.editor_js
                const componentData = response?.response?.editor_components
                const styleData = response?.response?.editor_styles
                const menuData = response?.response?.menu ? JSON.parse(response?.response?.menu) : {};
                const linkdata = response?.response?.link ? JSON.parse(response?.response?.link) : {};

                console.log("menuData:", menuData);
                console.log("linkdata:", linkdata);

                dispatch(pageAction?.setTemplateData({ htmlData, cssData, jsData, componentData, styleData }))
                dispatch(pageAction?.setMenuID(menuData))
                dispatch(pageAction?.setLinkData(linkdata))
                setLoading(false)
            }
        } catch (err) {
            if (err?.response?.status == 401) {
                setLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchLinkData = async (link) => {
        setLoading(true);
        try {
            const response = await api.get(`admin/link/link-list/${link}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                const data = response?.response?.data
                setLinksData(data)
                setLoading(false);
            } else if (response?.success === false) {
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

            }
        } catch (err) {
            console.log("error in fetch links list api::", err);
            setLoading(false);
            if (err?.response?.status === 401) {
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/links`);
            }
            if (err?.response?.status === 403) {
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/links`);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        } finally {
            setLoading(false);
        }
    };

    const generateMenuItems = (menuItems) =>
        menuItems.map((item) => {
            const { id, link, title, icon, icon_colour, sub_menu } = item;
            const iconData = icon?.icon_value;

            return `
                <li class="MuiListItem-root" key="${id}">
                 <div class="MuiButtonBase-root MuiListItemButton-root MuiListItemButton-gutters">
                    <a href="${link}" class="custom-menu-link">
                            <div class="MuiListItemIcon-root" style="color: ${icon_colour}">
                                <svg 
                                    aria-hidden="true" 
                                    focusable="false" 
                                    data-prefix="${iconData.prefix}" 
                                    data-icon="${iconData.iconName}" 
                                    class="svg-inline--fa fa-${iconData.iconName} custom-menu-icon" 
                                    role="img" xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 ${iconData.icon[0]} ${iconData.icon[1]}">
                                    <path fill="currentColor" d="${iconData.icon[4]}"></path>
                                </svg>
                            </div>
                            <div class="MuiListItemText-root menu-name">
                                <span>${title}</span>
                            </div>
                        </a>
                          ${sub_menu?.length
                    ? `<div class="submenu-toggle-wrapper" data-id="${id}">
                            <svg 
                                aria-hidden="true" 
                                focusable="false" 
                                data-prefix="fas" 
                                data-icon="chevron-down" 
                                class="svg-inline--fa fa-chevron-down menu-icon toggle-icon" 
                                role="img" xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 512 512">
                                <path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>
                            </svg>
                        </div>`
                    : ""
                }
                    </div>
                    ${sub_menu?.length
                    ? `<ul class="sub-menu hidden" id="submenu-${id}">
                                ${generateMenuItems(sub_menu)}
                            </ul>`
                    : ""
                }
                </li>`;
        }).join("");

    const generateLinkBlocks = (linksData) => {
        return linksData?.map((data) => {
            const { background, colour, icon, label, link } = data;
            const iconData = icon?.icon_value;

            return `
            <div 
                class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-6 MuiGrid-grid-md-4 link-list-item-grid css-11l5t4l-MuiGrid-root">
                 <a href="${link}" target="_blank">
                <div 
                    class="py-3 link-icon-box MuiBox-root css-15bwb4e" 
                    style="background-color: ${background}; color: ${colour}">
                    <div class="icon-content MuiBox-root css-0">
                        <div class="icon-box MuiBox-root css-0">
                            <svg 
                                aria-hidden="true" 
                                focusable="false" 
                                data-prefix="${iconData?.prefix}" 
                                data-icon="${iconData?.iconName}" 
                                class="svg-inline--fa fa-${iconData?.iconName} icon-value" 
                                role="img" 
                                xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 ${iconData?.icon[0]} ${iconData?.icon[1]}">
                                <path fill="currentColor" d="${iconData?.icon[4]}"></path>
                            </svg>
                        </div>
                        <div class="link-label-text MuiBox-root css-0">
                            <p 
                                class="MuiTypography-root MuiTypography-body1 link-label-box css-ahj2mt-MuiTypography-root">
                                ${label}
                            </p>
                        </div>
                    </div>
                </div>
                </a>
            </div>
        `;
        }).join("");
    };


    const submenuToggleScript = `
    document.addEventListener("DOMContentLoaded", () => {
        document.querySelectorAll(".submenu-toggle-wrapper").forEach((toggleWrapper) => {
            toggleWrapper.addEventListener("click", (e) => {
                const menuId = toggleWrapper.getAttribute("data-id");
                const submenu = document.getElementById("submenu-" + menuId);
                const toggleIcon = toggleWrapper.querySelector(".toggle-icon");

                if (submenu) {
                    submenu.classList.toggle("hidden");

                    // Toggle the SVG icon
                    if (submenu.classList.contains("hidden")) {
                        toggleIcon.setAttribute("data-icon", "chevron-down");
                        toggleIcon.innerHTML = '<path fill="currentColor" d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"></path>';
                    } else {
                        toggleIcon.setAttribute("data-icon", "chevron-up");
                        toggleIcon.innerHTML = '<path fill="currentColor" d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"></path>';
                    }
                }
            });
        });
    });
`;

    // const updateMenuItemsInHTML = (html, menuItems, position) => {
    //     console.log("menuItems:::", menuItems);

    //     const menuItemsHTML = generateMenuItems(menuItems);

    //     // Determine the appropriate class based on position
    //     const positionClass = position === "vertical" ? "MuiList-root-vertical" : "MuiList-root-horizontal";

    //     // Construct the regex dynamically based on position
    //     const regex = /<ul class="(?:MuiList-root-horizontal|MuiList-root-vertical) menu-items">.*?<\/ul>/s;

    //     // Replace the content of the matched UL
    //     return html.replace(
    //         regex,
    //         `<ul class="${positionClass} menu-items">${menuItemsHTML}</ul>`
    //     );
    // };

    const updateHTMLContent = (html, menuItems, linksItem, position) => {
        console.log("menuItems:::", menuItems);
        console.log("linksItem:::", linksItem);

        // Generate menu items HTML
        const menuItemsHTML = generateMenuItems(menuItems);

        // Determine the appropriate class based on position
        const positionClass = position === "vertical" ? "MuiList-root-vertical" : "MuiList-root-horizontal";

        // Replace menu items block
        const menuRegex = /<ul class="(?:MuiList-root-horizontal|MuiList-root-vertical) menu-items">.*?<\/ul>/s;
        html = html.replace(
            menuRegex,
            `<ul class="${positionClass} menu-items">${menuItemsHTML}</ul>`
        );

        // Generate links block HTML
        const linksBlockHTML = generateLinkBlocks(linksItem);

        // Replace links block
        const linksRegex = /<div class="cust-cms-link-block">.*?<\/div>/s;
        html = html.replace(
            linksRegex,
            `<div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 link-list-container css-mhc70k-MuiGrid-root mt-4">${linksBlockHTML}</div>`
        );

        return html;
    };

    const updatedHTML = updateHTMLContent(html, userMenuLists, inksData, menu?.position);

    // const previewContent = `
    //     <html>
    //       <head>
    //         <style>
    //             .custom-menu {
    //                         padding: 16px;
    //                         background-color: #f9f9f9;
    //                         border: 1px solid #ddd;
    //                         border-radius: 8px;
    //                         max-width: 320px;
    //                     }

    //                     .custom-menu-list {
    //                         list-style: none;
    //                         margin: 0;
    //                         padding: 0;
    //                     }

    //                     .custom-menu-item {
    //                         margin-bottom: 8px;
    //                     }

    //                     .custom-menu-link {
    //                         text-decoration: none;
    //                         display: flex;
    //                         align-items: center;
    //                         gap: 8px;
    //                         color: #333;
    //                         font-size: 16px;
    //                     }

    //                     .custom-menu-icon {
    //                         font-size: 18px;
    //                         color: #660C60;
    //                         height: 18px;
    //                     }

    //                     .custom-menu-name {
    //                         font-weight: 400;
    //                     }

    //                     * {
    //                     font-family: "Inter" !important;
    //                     //   background-color: #fff;
    //                 }
    //                     [data-gjs-type="cell"] {
    //     position: relative !important;
    // }
    //                         table{
    //                             position: relative;
    //                         }
    //                         .MuiPaper-root {
    //                             width: auto;
    //                             transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    //                             overflow-x: hidden;

    //                             padding-top: 125px;
    //                             padding-bottom: 30px;
    //                             height: 100vh;

    //                              padding: 30px;

    //                                  background-color: #fff;
    //                                 color: rgba(0, 0, 0, 0.87);
    //                                 box-shadow: none;
    //                                 overflow-y: auto;
    //                                 display: flex;
    //                                 flex-direction: column;
    //                                 position: absolute;
    //                                 top: 0;
    //                                 outline: 0;
    //                                 left: 0;
    //                                 border-right: 1px solid rgba(0, 0, 0, 0.12);
    //                                 white-space:nowrap;
    //                             }
    //                                 .MuiList-root{
    //                                 list-style: none;
    //                                 margin: 0;
    //                                 padding: 0;
    //                                 position: relative;

    //                                 display: flex;
    //                                 flex-direction: column;
    //                                 gap: 10px;
    //                                 width: 100%;
    //                                 padding-top: 0 !important;
    //                                 padding-bottom: 0 !important;
    //                                 white-space:nowrap;
    //                         }
    //                         .MuiList-root-vertical{
    //                                 list-style: none;
    //                                 margin: 0;
    //                                 padding: 0;
    //                                 position: relative;

    //                                 display: flex;
    //                                 flex-direction: column;
    //                                 gap: 10px;
    //                                 width: 100%;
    //                                 padding-top: 0 !important;
    //                                 padding-bottom: 0 !important;
    //                                 white-space:nowrap;
    //                         }
    //                                 .MuiList-root-horizontal{
    //                                  list-style: none;
    //                                 margin: 0;
    //                                 padding: 0;
    //                                 position: relative;

    //                                 display: flex;
    //                                 flex-direction: row;
    //                                 gap: 10px;
    //                                 width: 100%;
    //                                 padding-top: 0 !important;
    //                                 padding-bottom: 0 !important;
    //                                 white-space:nowrap;
    //                                 }
    //                                 .MuiListItem-root{

    //                                         -webkit-box-pack: start;
    //                                         justify-content: flex-start;
    //                                         -webkit-box-align: center;
    //                                         align-items: center;
    //                                         position: relative;
    //                                         text-decoration: none;
    //                                         width: 100%;
    //                                         box-sizing: border-box;
    //                                         text-align: left;
    //                                         display: block;

    //                                         padding-top: 8px;
    //                                         padding-bottom: 8px;
    //                                         padding-left: 16px;
    //                                         padding-right: 16px;
    //                                 }
    //                                 .MuiButtonBase-root{
    //                                         -webkit-tap-highlight-color: transparent;
    //                                         background-color: transparent;
    //                                         outline: 0;
    //                                         border: 0;
    //                                         margin: 0;
    //                                         border-radius: 0;
    //                                         padding: 0;
    //                                         cursor: pointer;
    //                                         user-select: none;
    //                                         vertical-align: middle;
    //                                         -webkit-appearance: none;
    //                                         -webkit-text-decoration: none;
    //                                         color: inherit;
    //                                         display: flex;
    //                                         -webkit-box-flex: 1;
    //                                         align-items: center;
    //                                         position: relative;
    //                                         text-decoration: none;
    //                                         min-width: 0;
    //                                         box-sizing: border-box;
    //                                         text-align: left;
    //                                         padding-top: 8px;
    //                                         padding-bottom: 8px;
    //                                         transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    //                                         min-height: 48px;
    //                                         -webkit-box-pack: initial;
    //                                         justify-content: space-between;
    //                                         align-items: center;
    //                                         padding-left: 20px;
    //                                         padding-right: 20px;
    //                                 }
    //                                 .MuiListItemIcon-root{
    //                                         color: rgba(0, 0, 0, 0.54);
    //                                         flex-shrink: 0;
    //                                         display: inline-flex;
    //                                         min-width: 0;
    //                                         margin-right: 16px;
    //                                         -webkit-box-pack: center;
    //                                         justify-content: center;
    //                                 }
    //                                         .MuiListItemIcon-root svg{
    //                                                 overflow: visible;
    //                                                 box-sizing: content-box;
    //                                                     font-size: 15px;
    //                                                     color: #660C60;
    //                                                     font-weight: 400;
    //                                                     line-height: 15px;
    //                                         }
    //                                                     .svg-inline--fa {
    //                                                     display: var(--fa-display, inline-block);
    //                                                     height: 1em;
    //                                                     overflow: visible;
    //                                                     vertical-align: -.125em;
    //                                                 }
    //                                                     .menu-icon {
    //                                                         font-size: 15px;
    //                                                         color: #660C60;
    //                                                         font-weight: 400;
    //                                                         line-height: 15px;
    //                                                     }
    //                                         .MuiListItemText-root{
    //                                                 flex: 1 1 auto;
    //                                                 min-width: 0;
    //                                                 margin-top: 4px;
    //                                                 margin-bottom: 4px;
    //                                                 opacity: 1;
    //                                         }
    //                                         .MuiTypography-root{
    //                                                 font-size: 15px;
    //                                                 font-weight: 400;
    //                                                 line-height: 15px;
    //                                                 opacity: 50%;
    //                                         }
    //                                                 .menu-name span {
    //                                                     font-size: 15px;
    //                                                     font-weight: 400;
    //                                                     line-height: 15px;
    //                                                     opacity: 50%;
    //                                                 }
    //                                         .selected-menu-item .menu-name span {
    //                                             color: white !important;
    //                                             opacity: inherit;
    //                                         }
    //                                             .selected-menu-item {
    //                                             background-color: #660C60 !important;
    //                                             border-radius: 6px;
    //                                             color: white !important;
    //                                         }
    //                                             .selected-menu-item .menu-icon {
    //                                                 color: white !important;
    //                                             }

    //                                             .vertical-menu {
    //                                                 display: flex;
    //                                                 flex-direction: column;
    //                                             }

    //                                             .horizontal-menu {
    //                                                 display: flex;
    //                                                 flex-direction: row;
    //                                                 gap: 20px;
    //                                             }

    //                                             .horizontal-item {
    //                                                 display: inline-block;
    //                                             }
    //                                                 .hidden {
    //                                                     display: none !important;
    //                                                     }

    //                                                      .mt-4 {
    //                             margin-top: 1.5rem!important;
    //                         }
    //                     .link-list-container{
    //                             box-sizing: border-box;
    //                             display: -ms-flexbox;
    //                             display: flex;
    //                             -webkit-box-flex-wrap: wrap;
    //                             -ms-flex-wrap: wrap;
    //                             flex-wrap: wrap;
    //                             -ms-flex-direction: row;
    //                             flex-direction: row;
    //                             width: calc(100% + 16px);
    //                             margin-left: -16px;
    //                     }
    //                     .link-list-item-grid{
    //                         padding-left: 16px;
    //                         padding-top: 16px;

    //                             box-sizing: border-box;
    //                             margin: 0;
    //                             -ms-flex-direction: row;
    //                             flex-direction: row;
    //                             -ms-flex-preferred-size: 100%;
    //                             flex-basis: 100%;
    //                             -webkit-box-flex: 0;
    //                             -ms-flex-positive: 0;
    //                             flex-grow: 0;
    //                             max-width: 100%;

    //                         @media(min-width: 1536px){
    //                                 -ms-flex-preferred-size: 33.333333%;
    //                                 flex-basis: 33.333333%;
    //                                 -webkit-box-flex: 0;
    //                                 -ms-flex-positive: 0;
    //                                 flex-grow: 0;
    //                                 max-width: 33.333333%;
    //                         }
    //                         @media(min-width: 1200px){
    //                             -ms-flex-preferred-size: 33.333333%;
    //                             flex-basis: 33.333333%;
    //                             -webkit-box-flex: 0;
    //                             -ms-flex-positive: 0;
    //                             flex-grow: 0;
    //                             max-width: 33.333333%;
    //                         }
    //                         @media(min-width: 900px){
    //                                 -ms-flex-preferred-size: 33.333333%;
    //                                 flex-basis: 33.333333%;
    //                                 -webkit-box-flex: 0;
    //                                 -ms-flex-positive: 0;
    //                                 flex-grow: 0;
    //                                 max-width: 33.333333%;
    //                         }
    //                         @media(min-width: 600px){
    //                                 -ms-flex-preferred-size: 50%;
    //                                 flex-basis: 50%;
    //                                 -webkit-box-flex: 0;
    //                                 -ms-flex-positive: 0;
    //                                 flex-grow: 0;
    //                                 max-width: 50%;
    //                         }
    //                     }

    //                     .link-icon-box{
    //                         cursor: pointer;
    //                         position: relative;
    //                         overflow: hidden;
    //                         display: flex;
    //                         gap: 12px;
    //                         border-radius: 6px;
    //                         padding: 15px;
    //                         padding-top: 1rem!important;
    //                         padding-bottom: 1rem!important;
    //                     }
    //                     .icon-content {
    //                         display: flex;
    //                         gap: 12px;
    //                         overflow: hidden;
    //                     }
    //                     .icon-box{
    //                         display: block;
    //                         box-sizing: border-box;
    //                     }
    //                     .icon-value{
    //                         overflow: visible;
    //                         box-sizing: content-box;
    //                         display: inline-block;
    //                         height: 1em;
    //                         vertical-align: -.125em;
    //                     }
    //                     .link-label-text {
    //                         overflow: hidden;
    //                         white-space: nowrap;
    //                         text-overflow: ellipsis;
    //                         height: 22px;
    //                     }
    //                     .link-label-box{
    //                         margin: 0;
    //                         font-weight: 400;
    //                         font-size: 1rem;
    //                         line-height: 1.5;
    //                         letter-spacing: 0.00938em;
    //                     }

    //      ${css}
    //         </style>
    //         <script>
    //         ${js}
    //         ${submenuToggleScript}  
    //         </script>
    //       </head>
    //       <body>
    //       ${updatedHTML}
    //       </body>
    //     </html>
    //   `;

    const previewContent = `
        <html>
          <head>
            <style>
                        * {
                        font-family: "Inter" !important;
                    }

         ${css}
            </style>
            <script>
            ${js}
            </script>
          </head>
          <body>
          ${html}
          </body>
        </html>
      `;

    useEffect(() => {
        const apiCall = async () => {
            await fetchTemplate()
        }
        apiCall()
    }, [])

    // useEffect(() => {
    //     fetchUserMenuList(menu?.type)
    // }, [menu])

    // useEffect(() => {
    //     if (link?.selectLink !== "") {
    //         fetchLinkData(link?.selectLink)
    //     }
    // }, [link])

    // useEffect(() => {
    //     const handleSubmenuToggle = (e) => {
    //         const menuId = e.target.getAttribute("data-id");
    //         const submenu = document.getElementById(`submenu-${menuId}`);
    //         if (submenu) {
    //             submenu.classList.toggle("hidden");
    //             e.target.textContent = submenu.classList.contains("hidden") ? "▼" : "▲";
    //         }
    //     };

    //     document.querySelectorAll(".submenu-toggle").forEach((toggle) => {
    //         toggle.addEventListener("click", handleSubmenuToggle);
    //     });

    //     return () => {
    //         document.querySelectorAll(".submenu-toggle").forEach((toggle) => {
    //             toggle.removeEventListener("click", handleSubmenuToggle);
    //         });
    //     };
    // }, [updatedHTML]);

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
                    }  ${window.innerWidth <= 768 && "small-screen"}`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    <div style={{ margin: "0 auto", width: "auto" }}>

                        <iframe
                            title="Preview"
                            style={{ width: '100%', height: '700px', border: 'none' }}
                            srcDoc={previewContent}
                        ></iframe>

                    </div>
                </Container>
                <Loader isLoading={loading} />
                <Toaster />
            </div>
        </>
    )
}

export default PreviewPage