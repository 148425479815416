import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import '../dashboard/PreviewLink.scss'
import { Toast } from '../../../utils/toast';
import api from '../commonFunctionForApi';

function PreviewLink({ linkData }) {

  const [displayData, setDisplayData] = useState(linkData);

  const fetchLinkPreview = async (urls) => {
    try {
      const userToken = localStorage.getItem("accessToken");
      const response = await api.post(`user/generate-link-preview`, { urls }, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setDisplayData(response?.response);
      } else if (response?.success == false) {
        Toast.warning(response?.message);
      }
    } catch (err) {
      console.log("error while fetching generate link preview api::", err);
    }
  }

  const generateLinkPreview = async () => {
    const urls = [];

    Object.entries(displayData).forEach(([link, data]) => {
      if (!data?.success) {
        urls.push(data?.link);
      }
    });

    if (urls?.length > 0) {
      await fetchLinkPreview(urls);
    }
  }



  useEffect(() => {
    generateLinkPreview();
  }, []);

  return (
    <>
      {Object.entries(displayData).map(([link, data], index) => (
        data?.success && (
          <a href={data?.link} key={index} className='text-decoration-none' target='_blank'>
            <Card className='mt-4 card-preview'>
              {data?.image && (
                <CardMedia
                  sx={{
                    width: 100
                  }}
                  image={
                    data?.image ?? ''
                  }
                />
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography component="div" variant="h5">
                    {data?.title ? (data?.title.length > 40 ? `${data?.title.substring(0, 40)}...` : data?.title) : (data?.link.length > 59 ? `${data?.link.substring(0, 59)}...` : data?.link)}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    component="div"
                    sx={{ color: 'text.secondary' }}
                  >
                    {data?.link.length > 59 ? `${data?.link.substring(0, 59)}...` : data?.link}
                  </Typography>
                </CardContent>
              </Box>

            </Card>
          </a>
        )
      ))}
    </>
  )
}

export default PreviewLink