import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import {
  faHouse,
  faUsers,
  faFolder,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@mui/material/List";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import Collapse from "@mui/material/Collapse";


export const UserMenu = (props) => {
  let {
    selectedItem,
    open,
    openOperation,
    handleClickForOperationsMenu,
    dispatch,
    navigate,
    location,
    adminAccessible,
    getAllTypesForUsers,
    loggedUser,
    userMenuLists,
    setExpandedMenuForUser,
    expandedMenuForUser
  } = props;

  const handleToggle = (id) => {
    setExpandedMenuForUser((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const SubMenu = ({ menu, open, handleToggle, expandedMenuForUser, navigate, location, adminAccessible, loggedUser }) => {
    const handleItemClick = (item) => {
      if (item.sub_menu.length > 0) {
        handleToggle(item.id);
      } else {
        const userKey = loggedUser?.user_type === "admin" && adminAccessible ? "admin-user" : "user";
        localStorage.setItem(`type-selected-name-${userKey}`, item?.title);
        localStorage.setItem(`type-selected-id-${userKey}`, item?.id);
        localStorage.setItem(`type-selected-slug-${userKey}`, item?.slug);
        localStorage.setItem(`last-redirect-page-${userKey}`, item?.link);
        if (item?.link?.startsWith("http")) {
          // External link, open in new tab
          window.open(item?.link, "_blank");
        } else {
          // Internal link, use navigate
          navigate(item?.link);
        }
      }
    };

    return (
      <div key={menu?.id}>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          className={location.pathname === menu?.link || expandedMenuForUser[menu?.id] ? `selected-menu-item w-48px ${open === false && "open-selected-menu"}` : `non-selected-menu-item`}
        >
          <ListItemButton
            onClick={() => handleItemClick(menu)}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : "auto", justifyContent: "center" }}>
              <FontAwesomeIcon icon={menu?.icon?.icon_value} className="menu-icon" />
            </ListItemIcon>
            <ListItemText primary={menu?.title} sx={{ opacity: open ? 1 : 0 }} className="menu-name" />
            {open && menu?.sub_menu?.length > 0 && (
              <FontAwesomeIcon icon={expandedMenuForUser[menu?.id] ? faChevronUp : faChevronDown} sx={{ opacity: open ? 1 : 0 }} className="menu-icon" />
            )}
          </ListItemButton>
        </ListItem>
        {menu?.sub_menu?.length > 0 && (
          <Collapse in={expandedMenuForUser[menu?.id]} timeout="auto" unmountOnExit className={`${expandedMenuForUser[menu?.id] && "p-1"}`}>
            <List component="div" disablePadding>
              {menu?.sub_menu.map((submenu) => (
                <SubMenu
                  key={submenu?.id}
                  menu={submenu}
                  open={open}
                  handleToggle={handleToggle}
                  expandedMenuForUser={expandedMenuForUser}
                  navigate={navigate}
                  location={location}
                  adminAccessible={adminAccessible}
                  loggedUser={loggedUser}
                />
              ))}
            </List>
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <List className="list-item-menu">
      {userMenuLists.map((menu) => (
        <SubMenu
          key={menu.id}
          menu={menu}
          open={open}
          handleToggle={handleToggle}
          expandedMenuForUser={expandedMenuForUser}
          navigate={navigate}
          location={location}
          adminAccessible={adminAccessible}
          loggedUser={loggedUser}
        />
      ))}
    </List>
  );
};
