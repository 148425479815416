import { createSlice } from "@reduxjs/toolkit";
const initialData = {
  selectedLabelId: 0,
};

const link = createSlice({
  name: "links",
  initialState: initialData,
  reducers: {
    setSelectedLabelId(state, action) {
      state.selectedLabelId = action?.payload
    },
  }
})

export const links = link.actions;
export default link.reducer;