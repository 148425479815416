import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import Collapse from "@mui/material/Collapse";
import {
  faHouse,
  faGears,
  faUsers,
  faFile,
  faList,
  faEnvelopeOpen,
  faStar,
  faChevronDown,
  faChevronUp,
  faGear,
  faUser,
  faUserGroup,
  faMessage,
  faFolder,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@mui/material/List";

export const AdminMenu = (props) => {
  let {
    selectedItem,
    open,
    openNamingClassification,
    handleClickForNamingClassificationMenu,
    dispatch,
    navigate,
    location,
    getAllTypes,
    setOpenNamingClassification,
    openTypes,
    handleClickForTypesMenu,
    activeType,
    setOpenTypes,
    handleClickForUsersMenu,
    openUsers,
    setOpenUsers,
    userMenuLists,
    setExpandedMenuForAdmin,
    expandedMenuForAdmin,
    loggedUser
  } = props;

  const handleToggle = (id) => {
    setExpandedMenuForAdmin((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const SubMenu = ({ menu, open, handleToggle, expandedMenuForUser, navigate, location, adminAccessible, loggedUser }) => {
    const handleItemClick = (item) => {
      if (item.sub_menu.length > 0) {
        handleToggle(item.id);
      } else {
        localStorage.setItem(`type-selected-name`, item?.title);
        localStorage.setItem(`type-selected-id`, item?.id);
        localStorage.setItem(`type-selected-slug`, item?.slug);
        localStorage.setItem(`last-redirect-page`, item?.link);
        if (item?.link?.startsWith("http")) {
          // External link, open in new tab
          window.open(item?.link, "_blank");
        } else {
          // Internal link, use navigate
          navigate(item?.link);
        }
      }
    };

    return (
      <div key={menu.id}>
        <ListItem
          disablePadding
          sx={{ display: 'block' }}
          className={location.pathname === menu?.link || expandedMenuForUser[menu?.id] ? `selected-menu-item w-48px ${open === false && "open-selected-menu"}` : `non-selected-menu-item`}
        >
          <ListItemButton
            onClick={() => handleItemClick(menu)}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
          >
            <ListItemIcon sx={{ minWidth: 0, mr: open ? 2 : "auto", justifyContent: "center" }}>
              <FontAwesomeIcon icon={menu?.icon?.icon_value} className="menu-icon" />
            </ListItemIcon>
            <ListItemText primary={menu?.title} sx={{ opacity: open ? 1 : 0 }} className="menu-name" />
            {open && menu?.sub_menu?.length > 0 && (
              <FontAwesomeIcon icon={expandedMenuForUser[menu?.id] ? faChevronUp : faChevronDown} sx={{ opacity: open ? 1 : 0 }} className="menu-icon" />
            )}
          </ListItemButton>
        </ListItem>
        {menu?.sub_menu?.length > 0 && (
          <Collapse in={expandedMenuForUser[menu?.id]} timeout="auto" unmountOnExit className={`${expandedMenuForUser[menu?.id] && "p-1"}`}>
            <List component="div" disablePadding>
              {menu?.sub_menu.map((submenu) => (
                <SubMenu
                  key={submenu?.id}
                  menu={submenu}
                  open={open}
                  handleToggle={handleToggle}
                  expandedMenuForUser={expandedMenuForUser}
                  navigate={navigate}
                  location={location}
                  adminAccessible={adminAccessible}
                  loggedUser={loggedUser}
                />
              ))}
            </List>
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <List className="list-item-menu">
      {open === true && (
        <ListItem>
          <div className="d-flex flex-wrpa gap-2 admin-view-div">
            <div className=" admin-view-div-icon">
              <FontAwesomeIcon icon={faGear} />
            </div>
            <div className="admin-view-div-p">
              <p className="text-wrap ">
                You are currently viewing platform in administrative mode.
              </p>
            </div>
          </div>
        </ListItem>
      )}

      {userMenuLists.map((menu) => (
        <SubMenu
          key={menu?.id}
          menu={menu}
          open={open}
          handleToggle={handleToggle}
          expandedMenuForUser={expandedMenuForAdmin}
          navigate={navigate}
          location={location}
          loggedUser={loggedUser}
        />
      ))}
    </List>
  );
};
