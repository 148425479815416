import { Box, Divider, Skeleton } from '@mui/material'
import React from 'react'

function UserListSkleton() {
  return (
    <>
      <Box className='d-flex align-items-center gap-2 p-2'>
        <Skeleton variant="circular" height={35} width={35} />
        <Skeleton variant="rectangular" height={45} width={100} />
      </Box>
      <Divider variant="fullWidth" />
      <Box className='d-flex align-items-center gap-2 p-2'>
        <Skeleton variant="circular" height={35} width={35} />
        <Skeleton variant="rectangular" height={45} width={100} />
      </Box>
      <Divider variant="fullWidth" />
      <Box className='d-flex align-items-center gap-2 p-2'>
        <Skeleton variant="circular" height={35} width={35} />
        <Skeleton variant="rectangular" height={45} width={100} />
      </Box>
      <Divider variant="fullWidth" />
      <Box className='d-flex align-items-center gap-2 p-2'>
        <Skeleton variant="circular" height={35} width={35} />
        <Skeleton variant="rectangular" height={45} width={100} />
      </Box>
      <Divider variant="fullWidth" />
      <Box className='d-flex align-items-center gap-2 p-2'>
        <Skeleton variant="circular" height={35} width={35} />
        <Skeleton variant="rectangular" height={45} width={100} />
      </Box>
      <Divider variant="fullWidth" />
    </>
  )
}

export default UserListSkleton