import React, { useEffect, useState } from "react";
import Header from "../../components/layout/header";
import DrawerMenu from "../../components/layout/drawer";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { SuccessAlert } from "../../components/common/alert/successAlert";
import { WarningAlert } from "../../components/common/alert/warningAlert";
import {
  Box,
  Stack,
  Breadcrumbs,
  Link,
  Typography,
  Divider,
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Avatar,
  TableBody,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  IconButton,
  AvatarGroup,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  CircularProgress,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/common/loader";
import { Toaster } from "react-hot-toast";
import "../../styles/categories.scss";
import PropTypes from "prop-types";
import {
  faList,
  faPlay,
  faPause,
  faTrash,
  faXmark,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import { groupTabAction } from "../../redux/slices/groups/groups";
import { columns_user_groups_header } from "../../helpers/theaderColumns/userGroups/userGroup";
import TableHeaderForSorting from "../../components/common/TableHeaderForSorting";
import activeType from "../../assets/images/active.png";
import inactiveType from "../../assets/images/inactive.png";
import deleteType from "../../assets/images/delete.png";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import "../../styles/groups.scss";
import api from "../../components/common/commonFunctionForApi";
import logoutHelper from "../../helpers/logoutHelper";
import { Toast } from "../../utils/toast";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import { BootstrapTooltip } from "../../components/common/tooltip/bootstrapTooltip";
import StatusIcon from "../../components/common/StatusIcon";

function CustomTabPanel(props) {
  const { children, value, className, index, errorTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
      {...other}
    >
      {value === index && <Box className="tab-box">{children}</Box>}
    </div>
  );
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Groups = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { selectedGroupTab } = useSelector((state) => state?.groupTab);

  const ITEM_HEIGHT = 48;

  const [isWarningMessage, setIsWarningMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [value, setValue] = useState(selectedGroupTab);
  const [dataOrder, setDataOrder] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [openConfirmDeleteAlert, setOpenConfirmDeleteAlert] = useState(false);

  //states for paginations
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [nextPage, setNextPage] = useState(false);
  const [isDataFetching, setIsDataFetching] = useState(false);


  const [icon, setIcon] = useState({
    prefix: "fas",
    iconName: "users",
    icon: [
      640,
      512,
      [],
      "f0c0",
      "M144 0a80 80 0 1 1 0 160A80 80 0 1 1 144 0zM512 0a80 80 0 1 1 0 160A80 80 0 1 1 512 0zM0 298.7C0 239.8 47.8 192 106.7 192h42.7c15.9 0 31 3.5 44.6 9.7c-1.3 7.2-1.9 14.7-1.9 22.3c0 38.2 16.8 72.5 43.3 96c-.2 0-.4 0-.7 0H21.3C9.6 320 0 310.4 0 298.7zM405.3 320c-.2 0-.4 0-.7 0c26.6-23.5 43.3-57.8 43.3-96c0-7.6-.7-15-1.9-22.3c13.6-6.3 28.7-9.7 44.6-9.7h42.7C592.2 192 640 239.8 640 298.7c0 11.8-9.6 21.3-21.3 21.3H405.3zM224 224a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zM128 485.3C128 411.7 187.7 352 261.3 352H378.7C452.3 352 512 411.7 512 485.3c0 14.7-11.9 26.7-26.7 26.7H154.7c-14.7 0-26.7-11.9-26.7-26.7z",
    ],
  });
  const [icon2, setIcon2] = useState({
    prefix: "fas",
    iconName: "image",
    icon: [
      512,
      512,
      [],
      "f03e",
      "M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z",
    ],
  });
  const [icon3, setIcon3] = useState({
    prefix: "fas",
    iconName: "gavel",
    icon: [
      512,
      512,
      ["legal"],
      "f0e3",
      "M318.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-120 120c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l4-4L325.4 293.4l-4 4c-12.5 12.5-12.5 32.8 0 45.3l16 16c12.5 12.5 32.8 12.5 45.3 0l120-120c12.5-12.5 12.5-32.8 0-45.3l-16-16c-12.5-12.5-32.8-12.5-45.3 0l-4 4L330.6 74.6l4-4c12.5-12.5 12.5-32.8 0-45.3l-16-16zm-152 288c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l48 48c12.5 12.5 32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-1.4-1.4L272 285.3 226.7 240 168 298.7l-1.4-1.4z",
    ],
  });

  const data = [
    {
      id: 1,
      name: "Admin",
      icon: icon,
      members: "3,234",
      date: "8:43pm 12/4/24",
      type: "Admin",
      manager: "Dustin Quitzon",
      creator: "Admin",
      status: "enable",
      iconBG: "#F8961E",
      iconColor: "#FFFFFF",
      isSystem: true,
    },
    {
      id: 2,
      name: "Content Group",
      icon: icon2,
      members: "23",
      date: "8:43pm 12/4/24",
      type: "User",
      manager: "Megan Reichel",
      creator: "Admin",
      status: "enable",
      iconBG: "#1D3557",
      iconColor: "#FFFFFF",
      isSystem: true,
    },
    {
      id: 3,
      name: "Departments",
      icon: icon3,
      members: "509",
      date: "8:43pm 12/4/24",
      type: "User",
      manager: "Dr. Dana Weber",
      creator: "Admin",
      status: "enable",
      iconBG: "#F94144",
      iconColor: "#FFFFFF",
      isSystem: false,
    },
  ];

  const [allUserGroups, setallUserGroups] = useState([]);
  const [categoryId, setCategoryId] = useState();

  const [anchorElMenu, setAnchorElForMenu] = useState(null);

  const openMenuFoEdit = Boolean(anchorElMenu);

  const user = localStorage.getItem("user");
  const userToken = localStorage.getItem("accessToken");

  const handleOpenWarningMessage = () => {
    setIsWarningMessage(true);
    setIsWarningMessage("");
  };

  const handleCloseWarningMessage = () => {
    setIsWarningMessage(false);

    setWarningMessage("");
  };

  const handleOpenSuccessMessage = () => {
    setIsSuccessMessage(true);
    setIsSuccessMessage("");
  };

  const handleCloseSuccessMessage = () => {
    setIsSuccessMessage(false);
    setIsSuccessMessage("");
  };

  const handleClick = (event, row) => {
    setAnchorElForMenu(event.currentTarget);
    setCategoryId(row);
  };

  const handleChangeDataOrdr = () => {
    let order = dataOrder === "asc" ? "desc" : "asc";
    setDataOrder(order);

    setPage(1);
    setallUserGroups([]);

    fetchAllGroups(
      value == 0
        ? "all"
        : value == 1
          ? "enable"
          : value == 2
            ? "disable"
            : "deleted",
      order, 1
    );
  };

  const handleClose = () => {
    setAnchorElForMenu(null);
  };

  const handleCloseDeleteAlertModel = () => {
    setOpenDeleteAlert(false);
  };

  const handleCloseConfirmDeleteAlertModel = () => {
    setOpenConfirmDeleteAlert(false);
  };

  const handleChange = (event, newValue) => {
    dispatch(groupTabAction.handleSelectedTab(newValue));
    setValue(newValue);
    let value =
      newValue == 0
        ? "all"
        : newValue == 1
          ? "enable"
          : newValue == 2
            ? "disable"
            : "deleted";
    setPage(1);
    setallUserGroups([]);
    fetchAllGroups(value, dataOrder, 1);
  };

  const {
    open,
    selectedItem,
    nameCollapsable,
    drawerVariant,
    typeCollapsable,
    usersCollapsable,
  } = useSelector((state) => state?.drawerMenu);

  function a11yProps1(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps2(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps3(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }
  function a11yProps4(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className:
        value == index
          ? `selected-tab-attribute`
          : `non-selected-tab-attribute`,
    };
  }

  const fetchAllGroups = async (type, order, page) => {
    try {
      if (page == 1) {
        setLoading(true);
      }
      const userToken = localStorage.getItem("accessToken");
      const response = await api.get(
        `admin/group/${type}${order ? `?order=${order}` : ""}&page=${page ?? 1}`,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        }
      );

      const newGroups = response?.response?.data;
      setallUserGroups((prevPosts) => {
        const uniquePosts = newGroups.filter(
          (newPost) =>
            !prevPosts.some((post) => post.id === newPost.id)
        );
        return [...prevPosts, ...uniquePosts];
      });

      setTotalPages(response?.response?.meta.last_page);
      if (page + 1 <= response?.response?.meta.last_page) {
        setNextPage(true);
      }
    } catch (err) {
      console.log("error in fetch posts api::", err);
      if (err?.response?.status === 401) {
        localStorage.setItem("last-redirect-page", `/groups`);
        logoutHelper(dispatch, navigate, userToken);
      }

      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
    } finally {
      setIsDataFetching(false); // Reset fetching state after the API call
      setLoading(false);
    }
  };

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const handleScroll = async () => {
    const scrollableHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;
    const remainingScrollThreshold = scrollableHeight * 0.3;

    if (
      scrollableHeight - scrollPosition < remainingScrollThreshold &&
      !isDataFetching &&
      page < totalPages
    ) {
      setIsDataFetching(true);
      const nextPageNum = page + 1;
      setPage(nextPageNum);
      await fetchAllGroups(value == 0
        ? "all"
        : value == 1
          ? "enable"
          : value == 2
            ? "disable"
            : "deleted", dataOrder, nextPageNum);
    }
  };

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 200);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () =>
      window.removeEventListener("scroll", debouncedHandleScroll);
  }, [isDataFetching, page, totalPages]);



  const changeGroupStatus = async (id, status) => {
    try {
      let data = {
        status: status,
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/group/status `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (value === 0) {
          setallUserGroups((prevGroups) =>
            prevGroups.map((group) =>
              group.id === id ? { ...group, status: status } : group
            )
          );
        } else {
          setallUserGroups((prevGroups) =>
            prevGroups.filter((group) => group.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const RestoreGroup = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/group/restore `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (value === 0) {
          setallUserGroups((prevGroups) =>
            prevGroups.map((group) =>
              group.id === id ? { ...group, status: response?.response?.status } : group
            )
          );
        } else {
          setallUserGroups((prevGroups) =>
            prevGroups.filter((group) => group.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        setLoading(false);
        handleClose();
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const deleteGroupAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/group/delete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        if (value === 0) {
          setallUserGroups((prevGroups) =>
            prevGroups.map((group) =>
              group.id === id ? { ...group, status: 'deleted' } : group
            )
          );
        } else {
          setallUserGroups((prevGroups) =>
            prevGroups.filter((group) => group.id !== id)
          );
        }
        setLoading(false);
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseDeleteAlertModel();
        setLoading(false);
        setAnchorElForMenu(null);
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  const permanantlyDeleteGroupAPI = async (id) => {
    try {
      let data = {
        id: id,
      };
      setLoading(true);
      const response = await api.post(`admin/group/forcedelete `, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response?.success == true) {
        setIsSuccessMessage(true);
        setSuccessMessage(response?.message);
        Toast.success(response?.message);
        handleCloseConfirmDeleteAlertModel();
        setLoading(false);
        setAnchorElForMenu(null);

        setallUserGroups([]);
        setPage(1);

        fetchAllGroups(
          value == 0
            ? "all"
            : value == 1
              ? "enable"
              : value == 2
                ? "disable"
                : "deleted",
          dataOrder, 1
        );
      } else if (response?.success == false) {
        setIsWarningMessage(true);
        setWarningMessage(response?.message);
        Toast.warning(response?.message);
        handleCloseConfirmDeleteAlertModel();

        setLoading(false);
        setAnchorElForMenu(null);
      }
    } catch (err) {
      if (err?.response?.status == 401) {
        setLoading(false);
        logoutHelper(dispatch, navigate, userToken);
      }
      if (err?.response?.status == 429) {
        Toast.error("Too many attemps wait for 2 min.")
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllGroups(
      selectedGroupTab == 0
        ? "all"
        : selectedGroupTab == 1
          ? "enable"
          : selectedGroupTab == 2
            ? "disable"
            : "deleted",
      dataOrder, 1
    );

    setValue(selectedGroupTab);
  }, []); //run only once after page load

  return (
    <>
      <div
        className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
          }  ${window.innerWidth <= 768 && "small-screen"}`}
      >
        <Container
          className={`${window.innerWidth <= 768 && "small-screen-container"}`}
        >
          {isSuccessMessage == true && (
            <SuccessAlert
              message={successMessage}
              onClose={handleCloseSuccessMessage}
            />
          )}

          {isWarningMessage == true && (
            <WarningAlert
              message={warningMessage}
              onClose={handleCloseWarningMessage}
            />
          )}
          <div className="main-div-2-types">
            <Box className="">
              <Stack spacing={2}>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                >
                  <Link
                    underline="hover"
                    key="1"
                    color="inherit"
                    href="/"
                    component="h3"
                    className="cursor-pointer"
                  >
                    Admin
                  </Link>
                  <Typography key="3" component="h3" color="text.primary">
                    User Groups
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Box>
            <Button
              className="btn primary-btn"
              onClick={() => {
                localStorage.setItem("last-redirect-page", `/add/groups`);

                navigate("/add/groups");
              }}
            >
              Add New
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <Divider className="mt-4" style={{ background: "#EEEEEE" }} />
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{ borderBottom: 1, borderColor: "divider" }}
              className="box-attribute"
            >
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                // variant={isTablet && "scrollable"}
                variant="scrollable"
                className={"tabs"}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#660C60",
                  },
                }}
              >
                <Tab
                  icon={<FontAwesomeIcon icon={faList} />}
                  label="All User Groups"
                  iconPosition="start"
                  {...a11yProps1(0)}
                />

                <Tab
                  label="Active User Groups"
                  icon={<FontAwesomeIcon icon={faPlay} />}
                  iconPosition="start"
                  {...a11yProps2(1)}
                />
                <Tab
                  label="Inactive User Groups"
                  {...a11yProps3(2)}
                  icon={<FontAwesomeIcon icon={faPause} />}
                  iconPosition="start"
                />
                <Tab
                  label="Deleted User Groups"
                  {...a11yProps4(3)}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                  iconPosition="start"
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={value}>
              <Paper sx={{ width: "100%" }} className="type-list-paper">
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    stickyHeader
                  >
                    {allUserGroups?.length > 0 && (
                      <>
                        <TableHeaderForSorting
                          columns={columns_user_groups_header}
                          handleChangeDataOrdr={handleChangeDataOrdr}
                          dataOrder={dataOrder}
                        />
                        <TableBody>
                          {allUserGroups?.map((row, index) => (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={index + 1}
                              className="cursor-pointer"
                            >
                              <TableCell
                                align="left"
                                className={`tbl-body-cell tbl-border-left ${row?.status === "deleted" && "text-muted"
                                  }`}
                              >
                                <div
                                  className="d-flex flex-warp gap-3 align-items-center"
                                  style={{ fontSize: "14px" }}
                                >
                                  <div
                                    className={`icon-div-user-group d-flex justify-content-center align-items-center icons-div`}
                                    key={index}
                                    style={{ backgroundColor: row?.background }}
                                  >
                                    <FontAwesomeIcon
                                      icon={row?.icon}
                                      className="icon-user-group"
                                      style={{ color: row?.colour }}
                                    />
                                  </div>
                                  <p
                                    className={`${row?.status === "deleted" &&
                                      "text-muted fw-700"
                                      }`}
                                  >
                                    {row?.title}
                                  </p>
                                  {row?.groupType === "system Group" && (
                                    <Chip
                                      label="System Group"
                                      className="chip-label-div"
                                    />
                                  )}
                                </div>
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 "
                              >
                                {row?.members}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 "
                              >
                                {row?.created_at}
                              </TableCell>
                              <TableCell
                                align="left"
                                className="tbl-body-cell-3 text-capitalize"
                              >
                                {row?.user_type}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell"
                              >
                                {row?.manager?.length > 0 ? (
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <List>
                                          {row?.manager?.map(
                                            (manager, index) => (
                                              <>
                                                <ListItem>
                                                  <ListItemAvatar>
                                                    <Avatar
                                                      alt={manager?.user}
                                                      src="/static/images/avatar/1.jpg"
                                                      className="author-avtar-creator"
                                                    />
                                                  </ListItemAvatar>
                                                  <ListItemText
                                                    primary={manager?.user}
                                                  />
                                                </ListItem>
                                                {index !==
                                                  row?.manager?.length - 1 && (
                                                    <Divider component="li" />
                                                  )}{" "}
                                                {/* Conditionally render the divider */}
                                              </>
                                            )
                                          )}
                                        </List>
                                      </React.Fragment>
                                    }
                                    placement="left-start"
                                  >
                                    <AvatarGroup max={3} className="managers">
                                      {row?.manager?.map((manager, index) => (
                                        <Avatar
                                          alt={manager?.user}
                                          src="/static/images/avatar/1.jpg"
                                          className="author-avtar-creator"
                                        />
                                      ))}
                                    </AvatarGroup>
                                  </HtmlTooltip>
                                ) : (
                                  <Typography className="fs-14">
                                    Manager not assigned yet.
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell-creator text-center"
                              >
                                <BootstrapTooltip
                                  title={row?.created_by}
                                  placement="left-start"
                                >
                                  <Avatar
                                    alt={row?.created_by}
                                    src="/static/images/avatar/1.jpg"
                                    className="author-avtar-creator mx-auto"
                                  />
                                </BootstrapTooltip>
                              </TableCell>
                              <TableCell
                                align="center"
                                className="tbl-body-cell-2 tbl-border-right"
                              >
                                <div>
                                  <Stack
                                    direction="row"
                                    alignContent="center"
                                    alignItems="center"
                                    spacing={1}
                                    className="gap-1"
                                  >
                                    <StatusIcon status={row?.status} />
                                    <IconButton
                                      aria-label="more"
                                      id="long-button"
                                      // aria-controls={
                                      //   openMenuFoEdit ? "long-menu" : undefined
                                      // }
                                      // aria-expanded={
                                      //   openMenuFoEdit ? "true" : undefined
                                      // }
                                      aria-haspopup="true"
                                      onClick={(e) => handleClick(e, row)}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  </Stack>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
                {isDataFetching && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: 2
                    }}
                  >
                    <CircularProgress sx={{ color: "#660C60" }} />
                  </Box>
                )}
              </Paper>
              {allUserGroups?.length < 1 && (
                <div className="no-data mt-5">
                  <div>
                    <Image
                      src={NoDataFoundImg}
                      className="text-center mx-auto no-data-img mt-4"
                    />
                  </div>
                  <Typography component="h5" variant="h5">
                    No data found
                  </Typography>
                </div>
              )}
            </CustomTabPanel>
          </Box>
        </Container>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to delete user group ?
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => deleteGroupAPI(categoryId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          aria-labelledby="customized-dialog-title"
          open={openConfirmDeleteAlert}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="dialog-title"
          >
            Are you sure?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseConfirmDeleteAlertModel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            className="alert-close-btn"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers className="">
            {" "}
            Are you sure want to permanantly delete user group ? Once you
            permanatly delete you can not restore it again.
          </DialogContent>
          <DialogActions className="d-flex flex-wrap justify-content-end gap-2 p-3 z-0 action-btn-model">
            <Button
              className="btn delete-btn-model"
              onClick={handleCloseConfirmDeleteAlertModel}
            >
              <FontAwesomeIcon icon={faXmark} />
              Cancel
            </Button>
            <Button
              className="btn primary-btn"
              onClick={() => permanantlyDeleteGroupAPI(categoryId?.id)}
            >
              <FontAwesomeIcon icon={faFloppyDisk} />
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Menu
          anchorEl={anchorElMenu}
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          open={openMenuFoEdit}
          anchorOrigin={"right"}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
              zIndex: "1",
            },
          }}
        >
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                localStorage.setItem(
                  "last-redirect-page",
                  `/group/edit//${categoryId?.id}`
                );

                navigate(`/group/edit/${categoryId?.id}`);
              }}
            >
              Edit
            </MenuItem>
          )}

          {categoryId?.status !== "enable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeGroupStatus(categoryId?.id, "enable");
                }}
              >
                Active
              </MenuItem>
            )}
          {categoryId?.status !== "disable" &&
            categoryId?.status !== "deleted" && (
              <MenuItem
                onClick={() => {
                  changeGroupStatus(categoryId?.id, "disable");
                }}
              >
                Inactive
              </MenuItem>
            )}
          {categoryId?.status !== "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenDeleteAlert(true);
                // deleteGroupAPI(categoryId?.id);
                // setCategoryId(categoryId)
              }}
            >
              Delete
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                RestoreGroup(categoryId?.id);
              }}
            >
              Restore
            </MenuItem>
          )}
          {categoryId?.status === "deleted" && (
            <MenuItem
              onClick={() => {
                handleClose();
                setOpenConfirmDeleteAlert(true);
                // permanantlyDeleteGroupAPI(categoryId?.id);
              }}
            >
              Permanantly Delete
            </MenuItem>
          )}
        </Menu>
      </div>
      <Loader isLoading={loading} />
      <Toaster />
    </>
  );
};

export default Groups;
