import { createSlice } from "@reduxjs/toolkit";
const initialData = {
    anchorElForChat: null,
    anchorElForNotification: null,
    notificationData: [],
    notificationFetched: false,
    pusherInitialized: false,
    pusherMessage: null,
    updatedPusherMessage: null,
    deletePusherMessage: null,
    reactionPusherMessage: null,
};

const header = createSlice({
    name: "headerDetails",
    initialState: initialData,
    reducers: {
        setAnchorElForChat(state, action) {
            state.anchorElForChat = action?.payload
        },
        setAnchorElForNotification(state, action) {
            state.anchorElForNotification = action.payload;
        },
        setNotificationData(state, action) {
            state.notificationData = [...action.payload];
            state.notificationFetched = true;
        },
        setNotificationFetched(state, action) {
            state.notificationFetched = action.payload;
        },
        setAppendNotificationData(state, action) {
            state.notificationData = [...action.payload, ...state.notificationData,];
        },
        setPusherInitialized(state, action) {
            state.pusherInitialized = action?.payload
        },
        setPusherMessage(state, action) {
            state.pusherMessage = action?.payload
        },
        setUpdatedPusherMessage(state, action) {
            state.updatedPusherMessage = action?.payload
        },
        setDeletePusherMessage(state, action) {
            state.deletePusherMessage = action?.payload
        },
        setReactionPusherMessage(state, action) {
            state.reactionPusherMessage = action?.payload
        },
    }
})

export const headerAction = header.actions;
export default header.reducer;