import React from "react";
import Conversation from "../../components/common/chat/Conversation";


const UserConversation = () => {

    return (<Conversation />)

}

export default UserConversation