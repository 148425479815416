import api from "../../components/common/commonFunctionForApi";


export const exportXlsx = async (url, fileName) => {
  const userToken = localStorage.getItem("accessToken");
  try {
    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      responseType: 'blob'
    });
    console.log("response::", response);
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return true;
    } else {
      console.log("Not geeting change log file", response);
      return false;

    }

  } catch (error) {
    console.error("Error while downloading csv changelog::", error);
    return false;
  }
}
