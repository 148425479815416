import React, { useEffect, useState } from "react";

import {
    Typography,
    Box,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Collapse,
    TablePagination
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import NoDataFoundImg from "../../../assets/images/NoDataFound.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFolder,
    faGear,
    faUser,
    faDownload,
    faImage,
    faEye,
    faFilePdf,
    faFile,
    faFileExcel,
    faVideo,
    faXmark,
    faFloppyDisk,
    faTrash,
    faPen
} from "@fortawesome/free-solid-svg-icons";

import { Card, Image, Button } from "react-bootstrap";


const MyMediaSubFolders = ({ folder, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, level, openFolders, toggleFolder }) => {
    const isOpen = openFolders.includes(folder?.id); // Check if folder is open

    return (
        <>
            <ListItem
                key={folder?.id}
                disablePadding
                sx={{ display: "block" }}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                        justifyContent: "initial",
                        ml: `${level * 20}px` // Indentation based on the folder level
                    }}
                    onClick={() => {
                        toggleFolder(folder?.id); // Toggle open/close state of the folder
                        setSelectedMediaType("my-media");
                        setSelectedMediaFolderID(folder?.id);
                        setSelectedMediaFile("");
                        setSelectedMediaFileDetails();
                        setPageForMedia(0);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            ml: 2,
                            justifyContent: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faFolder}
                            className={`${selectedMediaFolderID == folder?.id ? "menu-icon-media-selected" : "menu-icon-media"}`}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={folder?.title}
                        className={`${selectedMediaFolderID == folder?.id ? "menu-name-media-selected" : "menu-name-media"}`}
                    />
                </ListItemButton>

                {/* Conditionally render the child folders only if the folder is open */}
                {folder?.child_folders?.length > 0 && (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        {folder?.child_folders.map((childFolder) => (
                            <MyMediaSubFolders
                                key={childFolder.id}
                                folder={childFolder}
                                setSelectedMediaType={setSelectedMediaType}
                                setSelectedMediaFolderID={setSelectedMediaFolderID}
                                setSelectedMediaFile={setSelectedMediaFile}
                                setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                                setPageForMedia={setPageForMedia}
                                selectedMediaFolderID={selectedMediaFolderID}
                                level={level + 1} // Increase the level for child folders
                                openFolders={openFolders}
                                toggleFolder={toggleFolder}
                            />
                        ))}
                    </Collapse>
                )}
            </ListItem>
        </>
    );
};

const SystemMediaSubFolders = ({ folder, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, level, openFolders, toggleFolder }) => {
    const isOpen = openFolders.includes(folder?.id); // Check if folder is open

    return (
        <>
            <ListItem
                key={folder?.id}
                disablePadding
                sx={{ display: "block" }}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                        justifyContent: "initial",
                        ml: `${level * 20}px` // Indentation based on the folder level
                    }}
                    onClick={() => {
                        toggleFolder(folder?.id); // Toggle open/close state of the folder
                        setSelectedMediaType("system");
                        setSelectedMediaFolderID(folder?.id);
                        setSelectedMediaFile("");
                        setSelectedMediaFileDetails();
                        setPageForMedia(0);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            ml: 2,
                            justifyContent: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faFolder}
                            className={`${selectedMediaFolderID == folder?.id ? "menu-icon-media-selected" : "menu-icon-media"}`}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={folder?.title}
                        className={`${selectedMediaFolderID == folder?.id ? "menu-name-media-selected" : "menu-name-media"}`}
                    />
                </ListItemButton>

                {/* Conditionally render the child folders only if the folder is open */}
                {folder?.child_folders?.length > 0 && (
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        {folder?.child_folders.map((childFolder) => (
                            <SystemMediaSubFolders
                                key={childFolder.id}
                                folder={childFolder}
                                setSelectedMediaType={setSelectedMediaType}
                                setSelectedMediaFolderID={setSelectedMediaFolderID}
                                setSelectedMediaFile={setSelectedMediaFile}
                                setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                                setPageForMedia={setPageForMedia}
                                selectedMediaFolderID={selectedMediaFolderID}
                                level={level + 1} // Increase the level for child folders
                                openFolders={openFolders}
                                toggleFolder={toggleFolder}
                            />
                        ))}
                    </Collapse>
                )}
            </ListItem>
        </>
    );
};

const MyMediaFolderList = ({ myMediaFolderList, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, selectedMediaType }) => {
    const [openFolders, setOpenFolders] = useState([]); // Track open folders by their ID

    // Function to toggle the open/close state of a folder
    const toggleFolder = (id) => {
        setOpenFolders((prevOpenFolders) =>
            prevOpenFolders.includes(id)
                ? prevOpenFolders.filter((folderId) => folderId !== id) // Remove from open list if it's open
                : [...prevOpenFolders, id] // Add to open list if it's closed
        );
    };

    // Function to find the parent folder hierarchy for a given folder ID
    const findParentFolders = (folderList, folderId) => {
        for (const folder of folderList) {
            if (folder.id === folderId) {
                return [folder.id];
            }
            if (folder.child_folders?.length > 0) {
                const childResult = findParentFolders(folder.child_folders, folderId);
                if (childResult.length > 0) {
                    return [folder.id, ...childResult];
                }
            }
        }
        return [];
    };

    useEffect(() => {
        if (selectedMediaFolderID) {
            // Find the parent folders and open them
            const parentFolders = findParentFolders(myMediaFolderList, selectedMediaFolderID);
            setOpenFolders(parentFolders);
        }
    }, [selectedMediaFolderID, myMediaFolderList]);

    return (
        <>
            <ListItem
                key={"my-media"}
                disablePadding
                sx={{ display: "block" }}
                className={`${selectedMediaType == "my-media" && "media-selected-type-border-left"}`}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                    }}
                    onClick={() => {
                        setSelectedMediaType("my-media");
                        setSelectedMediaFolderID(0);
                        setSelectedMediaFile("");
                        setSelectedMediaFileDetails();
                        setPageForMedia(0);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faUser}
                            className={`${selectedMediaType == "my-media" ? "menu-icon-media-selected" : "menu-icon-media"}`}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="My Media"
                        className={`${selectedMediaType == "my-media" ? "menu-name-media-selected" : "menu-name-media"}`}
                    />
                </ListItemButton>
            </ListItem>

            {/* Render the top-level folders */}
            <Collapse
                in={selectedMediaType == "my-media"}
                timeout="auto"
                unmountOnExit>
                {myMediaFolderList?.map((folder) => (
                    <MyMediaSubFolders
                        key={folder.id}
                        folder={folder}
                        setSelectedMediaType={setSelectedMediaType}
                        setSelectedMediaFolderID={setSelectedMediaFolderID}
                        setSelectedMediaFile={setSelectedMediaFile}
                        setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                        setPageForMedia={setPageForMedia}
                        selectedMediaFolderID={selectedMediaFolderID}
                        level={1} // Start the level at 1 for top-level folders
                        openFolders={openFolders} // Pass open folders state
                        toggleFolder={toggleFolder} // Pass the function to toggle folders
                    />
                ))}
            </Collapse>
        </>
    );
};

const SystemMediaFolderList = ({ systemMediaFolderList, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, selectedMediaType }) => {
    const [openFolders, setOpenFolders] = useState([]); // Track open folders by their ID

    // Function to toggle the open/close state of a folder
    const toggleFolder = (id) => {
        setOpenFolders((prevOpenFolders) =>
            prevOpenFolders.includes(id)
                ? prevOpenFolders.filter((folderId) => folderId !== id) // Remove from open list if it's open
                : [...prevOpenFolders, id] // Add to open list if it's closed
        );
    };

    // Function to find the parent folder hierarchy for a given folder ID
    const findParentFolders = (folderList, folderId) => {
        for (const folder of folderList) {
            if (folder.id === folderId) {
                return [folder.id];
            }
            if (folder.child_folders?.length > 0) {
                const childResult = findParentFolders(folder.child_folders, folderId);
                if (childResult.length > 0) {
                    return [folder.id, ...childResult];
                }
            }
        }
        return [];
    };

    useEffect(() => {
        if (selectedMediaFolderID) {
            // Find the parent folders and open them
            const parentFolders = findParentFolders(systemMediaFolderList, selectedMediaFolderID);
            setOpenFolders(parentFolders);
        }
    }, [selectedMediaFolderID, systemMediaFolderList]);

    return (
        <>
            <ListItem
                key={"system"}
                disablePadding
                sx={{ display: "block" }}
                className={`${selectedMediaType == "system" && "media-selected-type-border-left"}`}
            >
                <ListItemButton
                    sx={{
                        minHeight: 48,
                        px: 2.5,
                    }}
                    onClick={() => {
                        setSelectedMediaType("system");
                        setSelectedMediaFolderID(0);
                        setSelectedMediaFile("");
                        setSelectedMediaFileDetails();
                        setPageForMedia(0);
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: 2,
                            justifyContent: "center",
                        }}
                    >
                        <FontAwesomeIcon
                            icon={faUser}
                            className={`${selectedMediaType == "system" ? "menu-icon-media-selected" : "menu-icon-media"}`}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary="System Media"
                        className={`${selectedMediaType == "system" ? "menu-name-media-selected" : "menu-name-media"}`}
                    />
                </ListItemButton>
            </ListItem>

            {/* Render the top-level folders */}
            <Collapse
                in={selectedMediaType == "system"}
                timeout="auto"
                unmountOnExit>
                {systemMediaFolderList?.map((folder) => (
                    <SystemMediaSubFolders
                        key={folder.id}
                        folder={folder}
                        setSelectedMediaType={setSelectedMediaType}
                        setSelectedMediaFolderID={setSelectedMediaFolderID}
                        setSelectedMediaFile={setSelectedMediaFile}
                        setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                        setPageForMedia={setPageForMedia}
                        selectedMediaFolderID={selectedMediaFolderID}
                        level={1} // Start the level at 1 for top-level folders
                        openFolders={openFolders} // Pass open folders state
                        toggleFolder={toggleFolder} // Pass the function to toggle folders
                    />

                ))}
            </Collapse>
        </>
    );
};

const AllFoldersList = ({ myMediaFolderList, setSelectedMediaType, setSelectedMediaFolderID, setSelectedMediaFile, setSelectedMediaFileDetails, setPageForMedia, selectedMediaFolderID, selectedMediaType, systemMediaFolderList }) => {
    return (
        <List className="list-item-menu">
            <SystemMediaFolderList
                systemMediaFolderList={systemMediaFolderList}
                setSelectedMediaType={setSelectedMediaType}
                setSelectedMediaFolderID={setSelectedMediaFolderID}
                setSelectedMediaFile={setSelectedMediaFile}
                setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                setPageForMedia={setPageForMedia}
                selectedMediaFolderID={selectedMediaFolderID}
                selectedMediaType={selectedMediaType}
            />
            <MyMediaFolderList
                myMediaFolderList={myMediaFolderList}
                setSelectedMediaType={setSelectedMediaType}
                setSelectedMediaFolderID={setSelectedMediaFolderID}
                setSelectedMediaFile={setSelectedMediaFile}
                setSelectedMediaFileDetails={setSelectedMediaFileDetails}
                setPageForMedia={setPageForMedia}
                selectedMediaFolderID={selectedMediaFolderID}
                selectedMediaType={selectedMediaType}
            />
        </List>
    );
};

const UploadMediaModelAtt = (props) => {
    let {
        openMediaUpload,
        handleCloseForUploadMedia,
        selectedMediaTypeForImage,
        selectedMediaTypeForDoc,
        setSelectedMediaTypeForImage,
        setSelectedMediaTypeForDoc,
        setSelectedMediaFolderIDForImage,
        setSelectedMediaFolderIDForDoc,
        systemMediaFolderList,
        selectedMediaFolderIDForImage,
        selectedMediaFolderIDFordoc,
        myMediaFolderList,
        downloadFileForImage,
        downloadFileFordoc,
        selectedMediaFileDetailsForImage,
        setSelectedMediaFileDetailsForImage,
        selectedMediaFileForImage,
        setSelectedMediaFileForImage,
        selectedMediaFileDetailsForDoc,
        setSelectedMediaFileDetailsForDoc,
        selectedMediaFileForDoc,
        setSelectedMediaFileForDoc,
        mediaList,
        total_mediaFiles,
        pageForMedia,
        setPageForMedia,
        handleChangePageForMedia,
        rowsPerPageForMedia,
        handleChangeRowsPerPageForMedia,
        media_Pagination_options,
        mediaDataFor,
        handleImageFileValueCustAttForAtt,
        handleCloseMediaModelAddMedia,
        setSelectedFileMedia,
        selectedFileMedia,
        isEditMedia,
        setIsEditMedia,
        setSelectedMedia,
        selectedMedia,
        deleteMedia,
        setOpenRenameModel,
        setIsRenameMedia
    } = props

    const modifiedSelectedMediaFile = mediaDataFor == "image" ? selectedMediaFileForImage : selectedMediaFileForDoc;
    const modifiedSelectedMediaFileDetails = mediaDataFor == "image" ? selectedMediaFileDetailsForImage : selectedMediaFileDetailsForDoc

    const modifiedSetSelectedMediaFile = mediaDataFor == "image" ? setSelectedMediaFileForImage : setSelectedMediaFileForDoc
    const modifiedSetSelectedMediaFileDetails = mediaDataFor == "image" ? setSelectedMediaFileDetailsForImage : setSelectedMediaFileDetailsForDoc

    const modifiedSelectedMediaFolderID = mediaDataFor == "image" ? selectedMediaFolderIDForImage : selectedMediaFolderIDFordoc
    const modifiedSetSelectedMediaFolderID = mediaDataFor == "image" ? setSelectedMediaFolderIDForImage : setSelectedMediaFolderIDForDoc

    const modifiedSetSelectedMediaType = mediaDataFor == "image" ? setSelectedMediaTypeForImage : setSelectedMediaTypeForDoc
    const modifiedSelectedMediaType = mediaDataFor == "image" ? selectedMediaTypeForImage : selectedMediaTypeForDoc

    const modifiedDownload = mediaDataFor == "image" ? downloadFileForImage : downloadFileFordoc

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openMediaUpload}
            maxWidth="sm"
            fullWidth={true}
            className="media-dialog"
        >
            <DialogTitle
                sx={{ m: 0, p: 2 }}
                id="customized-dialog-title"
                className="dialog-title"
            >
                Media Upload
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloseForUploadMedia}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                className="alert-close-btn"
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers className="media-preview">
                <Grid container spacing={2}>
                    <Grid item xs={4} className="media-folder-list-grid">
                        <AllFoldersList
                            myMediaFolderList={myMediaFolderList}
                            setSelectedMediaType={modifiedSetSelectedMediaType}
                            setSelectedMediaFolderID={modifiedSetSelectedMediaFolderID}
                            setSelectedMediaFile={modifiedSetSelectedMediaFile}
                            setSelectedMediaFileDetails={modifiedSetSelectedMediaFileDetails}
                            setPageForMedia={setPageForMedia}
                            selectedMediaFolderID={modifiedSelectedMediaFolderID}
                            selectedMediaType={modifiedSelectedMediaType}
                            systemMediaFolderList={systemMediaFolderList}
                        />
                    </Grid>
                    <Grid item xs={8} className="media-list-grid">
                        <Box className={`${modifiedSelectedMediaFile !== "" ? "main-upload-media-div-2" : "main-upload-media-div"}`}>
                            {modifiedSelectedMediaFile !== "" && (
                                <>
                                    <Box className="btns-upload-media-div">
                                        <Box className="btn-upload-media-div cursor-pointer"
                                            onClick={() => {
                                                modifiedDownload()
                                            }}>
                                            <FontAwesomeIcon icon={faDownload} className="media-upload-icon" />
                                            <Typography className="media-upload-text">Download</Typography>
                                        </Box>
                                        <Box className="btn-upload-media-div cursor-pointer"
                                            onClick={() => {
                                                setSelectedMedia(modifiedSelectedMediaFileDetails?.id)
                                                deleteMedia(modifiedSelectedMediaFileDetails?.id)
                                            }}>
                                            <FontAwesomeIcon icon={faTrash} className="media-upload-icon" />
                                            <Typography className="media-upload-text">Delete</Typography>
                                        </Box>
                                        <Box className="btn-upload-media-div cursor-pointer"
                                            onClick={() => {
                                                setIsRenameMedia(true)
                                                setSelectedMedia(modifiedSelectedMediaFileDetails?.id)
                                            }}>
                                            <FontAwesomeIcon icon={faPen} className="media-upload-icon" />
                                            <Typography className="media-upload-text">Rename</Typography>
                                        </Box>
                                        <Box className="btn-upload-media-div cursor-pointer"
                                            onClick={() => {
                                                // Open the file in a new tab using direct_url
                                                window.open(modifiedSelectedMediaFileDetails?.file_path, '_blank');
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faEye} className="media-upload-icon" />
                                            <Typography className="media-upload-text">View</Typography>
                                        </Box>
                                        <Box className="btn-upload-media-div cursor-pointer"
                                            onClick={() => {
                                                setIsEditMedia(true)
                                                setSelectedMedia(modifiedSelectedMediaFileDetails?.id)
                                            }}>
                                            <FontAwesomeIcon icon={faImage} className="media-upload-icon" />
                                            <Typography className="media-upload-text">Edit</Typography>
                                        </Box>
                                    </Box>

                                </>
                            )}
                            <Box className="upload-btn-div">
                                <Button
                                    className="btn primary-btn"
                                    onClick={() => {
                                        handleCloseMediaModelAddMedia()
                                    }}
                                >
                                    <FontAwesomeIcon icon={faImage} />
                                    Upload
                                </Button>
                            </Box>
                        </Box>

                        <Box className="media-img-lists">
                            <Grid container spacing={2}>
                                {mediaList?.length > 0 ? (
                                    <>
                                        {mediaList?.map((data, index) =>
                                            data?.type === "png" || data?.type === "jpg" || data?.type === "jpeg" || data?.type === "gif" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <Image
                                                                src={
                                                                    data?.file_path
                                                                }
                                                                alt={data?.title}
                                                                className="media-img-2"
                                                            />

                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "pdf" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFilePdf
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "docx" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFile
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "pptx" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFile
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "xlsx" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faFileExcel
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : data?.type === "mp4" ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    lg={2}
                                                    className="media-grid"
                                                >
                                                    <Card
                                                        key={index}
                                                        variant="outlined"
                                                        sx={{ p: 2, mb: 2 }}
                                                        className={`${modifiedSelectedMediaFile == data?.id && "selected-media-file-2"} card-media-2`}
                                                        onClick={() => {
                                                            modifiedSetSelectedMediaFile(data?.id)
                                                            modifiedSetSelectedMediaFileDetails(data)
                                                        }}
                                                    >
                                                        <Box
                                                            height={174}
                                                            display="flex"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            className="img-container-2"
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faVideo
                                                                }
                                                                color="#660C60"
                                                                className="folder-icon"
                                                            />
                                                        </Box>
                                                        <Divider />
                                                        <Box
                                                            className="media-item-name"
                                                        >
                                                            {data?.title}
                                                        </Box>
                                                    </Card>
                                                </Grid>
                                            ) : ""
                                        )}
                                    </>
                                ) : (
                                    <div className="no-data-media mt-5">
                                        <div>
                                            <Image
                                                src={NoDataFoundImg}
                                                className="text-center mx-auto no-data-img mt-4"
                                            />
                                        </div>
                                        <Typography component="h5" variant="h5">
                                            No data found
                                        </Typography>
                                    </div>
                                )}

                            </Grid>
                        </Box>
                        <Box className={"media-pagination-main-div"}>
                            <TablePagination
                                component="div"
                                className="media-pagination"
                                count={total_mediaFiles}
                                page={pageForMedia}
                                onPageChange={handleChangePageForMedia}
                                rowsPerPage={rowsPerPageForMedia}
                                onRowsPerPageChange={
                                    handleChangeRowsPerPageForMedia
                                }
                                labelDisplayedRows={({
                                    from,
                                    to,
                                    count,
                                }) => {
                                    const currentPage = pageForMedia + 1;
                                    const start =
                                        currentPage * rowsPerPageForMedia -
                                        rowsPerPageForMedia +
                                        1;

                                    const end = Math.min(
                                        currentPage * rowsPerPageForMedia,
                                        count
                                    );
                                    return (
                                        <>
                                            {`Showing ${start} to ${end} of `}
                                            <span>{count}</span>
                                            {" records"}
                                        </>
                                    );
                                }}
                                rowsPerPageOptions={media_Pagination_options}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                <Button
                    className="btn delete-btn-model"
                    onClick={handleCloseForUploadMedia}
                >
                    <FontAwesomeIcon icon={faXmark} />
                    Cancel
                </Button>
                <Button
                    className="btn primary-btn"
                    onClick={() => {
                        if (mediaDataFor === "image") {
                            handleImageFileValueCustAttForAtt()
                        } else if (mediaDataFor === "doc") {
                            handleImageFileValueCustAttForAtt()
                        }
                        handleCloseForUploadMedia()
                    }}
                >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                    Select Media
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default UploadMediaModelAtt