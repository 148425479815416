import React, { useEffect, useRef, useState, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faXmark,
    faFloppyDisk,
    faList,
    faPause,
    faPlay,
} from "@fortawesome/free-solid-svg-icons";
import {
    Box,
    Stack,
    Divider,
    Paper,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    Avatar,
    TableBody,
    Button,
    IconButton,
    Typography,
    TextField,
    Checkbox,
    Tabs,
    Tab,
    List,
    ListItem,
    ListItemText,
    Grid
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "react-select";
import PropTypes from "prop-types";
import TableHeaderForSorting from "../TableHeaderForSorting";
import { AntSwitch } from "../switch/antSwitch";

function CustomTabPanel(props) {
    const { children, value, className, index, errorTab, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{ border: errorTab === index ? "2px solid red" : "" }} // Apply border for error tab
            {...other}
        >
            {value === index && <Box className="tab-box-2">{children}</Box>}
        </div>
    );
}


const AddFolder = (props) => {
    let { openAddFolder,
        isEdit,
        handleCloseAddFolderModel,
        valueForFolderTab,
        handleChangeForFolder,
        newFolderName,
        setNewFolderName,
        loggedUser,
        isSystemTypeFolder,
        handleChangeForSystemFolder,
        isParentFolderSystemType,
        getAllGroupsAndUsersForFolder,
        permissionsForFolder,
        setPermissionsForFolder,
        isAdvancePermissionForFolder,
        setIsAdvancePermissionForFolder,
        columns_atttributes_headers,
        columns_advace_atttributes_headers,
        handleToggleForFolder,
        responseForFolder,
        storeFolder,
    } = props

    function a11yProps1(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                valueForFolderTab == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }
    function a11yProps2(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                valueForFolderTab == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }
    function a11yProps3(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            className:
                valueForFolderTab == index
                    ? `selected-tab-attribute`
                    : `non-selected-tab-attribute`,
        };
    }

    return (
        <Dialog
            aria-labelledby="customized-dialog-title"
            open={openAddFolder}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle
                sx={{ m: 0, p: 2 }}
                id="customized-dialog-title"
                className="dialog-title"
            >
                {`${isEdit == true ? "Edit Folder" : "Add Folder"}`}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleCloseAddFolderModel}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                className="alert-close-btn"
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers className="add-folder-model-content">
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{ borderBottom: 1, borderColor: "divider" }}
                        className="box-attribute-2"
                    >
                        <Tabs
                            value={valueForFolderTab}
                            onChange={handleChangeForFolder}
                            aria-label="basic tabs example"
                            variant="scrollable"
                            className={"tabs"}
                            TabIndicatorProps={{
                                style: {
                                    backgroundColor: "#660C60",
                                },
                            }}
                        >
                            <Tab
                                label="Properties"
                                icon={<FontAwesomeIcon icon={faList} />}
                                iconPosition="start"
                                {...a11yProps1(0)}
                            />
                            {/* <Tab
                                label="Access"
                                {...a11yProps2(1)}
                                icon={<FontAwesomeIcon icon={faPlay} />}
                                iconPosition="start"
                            /> */}
                            {isEdit == true && (
                                <Tab
                                    label="Info"
                                    {...a11yProps3(2)}
                                    icon={<FontAwesomeIcon icon={faPause} />}
                                    iconPosition="start"
                                />
                            )}

                        </Tabs>
                    </Box>
                    <CustomTabPanel value={0} index={valueForFolderTab}>
                        <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                            <Typography className="fs-14">Title</Typography>
                            <TextField
                                className="input-field mt-2"
                                fullWidth
                                id="outlined-basic short summary"
                                variant="outlined"
                                placeholder={`write folder name`}
                                value={newFolderName}
                                onChange={(event) => setNewFolderName(event?.target?.value)}
                            />
                            {loggedUser?.user_type === "admin" && !isEdit && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                isSystemTypeFolder === true
                                            }
                                            name="showOnUserPage"
                                            onChange={handleChangeForSystemFolder}
                                            disabled={isParentFolderSystemType}
                                        />
                                    }
                                    label="System type"
                                    className="font-inter mt-2"
                                />
                            )}

                        </Paper>

                    </CustomTabPanel>
                    {/* <CustomTabPanel value={1} index={valueForFolderTab}>
                        <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                            <Row>
                                <Col lg="12" className="">
                                    <Typography className="fw-400 fs-14 dark-text">
                                        Select Users
                                    </Typography>
                                    <Select
                                        closeMenuOnSelect={false}
                                        options={getAllGroupsAndUsersForFolder}
                                        menuPlacement="bottom"
                                        name="users"
                                        value={permissionsForFolder}
                                        placeholder="Select users..."
                                        isMulti
                                        menuPosition="fixed"
                                        onChange={(selectedOption) => {
                                            const users = selectedOption.map(
                                                (option) => option
                                            );
                                            setPermissionsForFolder(users);
                                        }}
                                        className={`muilt-select-field mt-2 `}
                                        classNamePrefix="select"
                                        styles={{
                                            option: (provided, state) => ({
                                                ...provided,
                                                backgroundColor: state.isSelected
                                                    ? "#660c605c"
                                                    : provided.backgroundColor,
                                                color: state.isSelected
                                                    ? "var(--dark-color)"
                                                    : provided.color,
                                            }),
                                            multiValueLabel: (provided, state) => {
                                                return {
                                                    ...provided,
                                                    color:
                                                        state.data?.type === "group"
                                                            ? "rgb(0, 135, 90)"
                                                            : "rgb(0, 82, 204)",
                                                };
                                            },
                                        }}
                                    />
                                </Col>
                                <Col lg="12" className="repeatable-col">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isAdvancePermissionForFolder == true}
                                                name="allowEdit"
                                                onChange={() =>
                                                    setIsAdvancePermissionForFolder(
                                                        !isAdvancePermissionForFolder
                                                    )
                                                }
                                            />
                                        }
                                        label="Show Advanced Permissions"
                                        className="font-inter fs-14"
                                    />
                                </Col>
                                <Col lg="12" className="repeatable-col">
                                    <TableContainer className="z-0">
                                        <Table
                                            // sx={{ minWidth: 750 }}
                                            aria-labelledby="tableTitle"
                                            stickyHeader
                                        >
                                            <TableHeaderForSorting
                                                columns={
                                                    isAdvancePermissionForFolder == false
                                                        ? columns_atttributes_headers
                                                        : columns_advace_atttributes_headers
                                                }
                                            />
                                            <TableBody>
                                                {permissionsForFolder?.length > 0 && (
                                                    <>
                                                        {permissionsForFolder?.map(
                                                            (permission, index) => (
                                                                <>
                                                                    <TableRow
                                                                        hover
                                                                        role="checkbox"
                                                                        tabIndex={-1}
                                                                        key={permission?.value}
                                                                        className="cursor-pointer tbl-row-attribute-row"
                                                                    >
                                                                        <TableCell
                                                                            align="left"
                                                                            className={` tbl-border-left tbl-cell-attribute-2`}
                                                                            style={{
                                                                                width: "100%",
                                                                                top: 0,
                                                                                left: 0,
                                                                            }}
                                                                        >
                                                                            <Typography className="fs-14">
                                                                                {permission?.label}
                                                                            </Typography>
                                                                        </TableCell>

                                                                        <TableCell
                                                                            align="right"
                                                                            className={`tbl-cell-attribute-2`}
                                                                            style={{
                                                                                width: "100%",
                                                                                top: 0,
                                                                                left: 0,
                                                                            }}
                                                                        >
                                                                            <AntSwitch
                                                                                name="view"
                                                                                checked={
                                                                                    permission?.view
                                                                                }
                                                                                onChange={() =>
                                                                                    handleToggleForFolder(
                                                                                        permission?.value,
                                                                                        "view"
                                                                                    )
                                                                                }
                                                                                inputProps={{
                                                                                    "aria-label":
                                                                                        "ant design",
                                                                                }}
                                                                            />
                                                                        </TableCell>
                                                                        <TableCell
                                                                            align="right"
                                                                            className={`tbl-cell-attribute-2`}
                                                                            style={{
                                                                                width: "100%",
                                                                                top: 0,
                                                                                left: 0,
                                                                            }}
                                                                        >
                                                                            <AntSwitch
                                                                                name="deny"
                                                                                checked={
                                                                                    permission?.deny
                                                                                }
                                                                                onChange={() =>
                                                                                    handleToggleForFolder(
                                                                                        permission?.value,
                                                                                        "deny"
                                                                                    )
                                                                                }
                                                                                inputProps={{
                                                                                    "aria-label":
                                                                                        "ant design",
                                                                                }}
                                                                            />
                                                                        </TableCell>

                                                                        {isAdvancePermissionForFolder && (
                                                                            <>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    className={`tbl-cell-attribute-2`}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                    }}
                                                                                >
                                                                                    <AntSwitch
                                                                                        name="edit"
                                                                                        checked={
                                                                                            permission?.edit
                                                                                        }
                                                                                        onChange={() =>
                                                                                            handleToggleForFolder(
                                                                                                permission?.value,
                                                                                                "edit"
                                                                                            )
                                                                                        }
                                                                                        inputProps={{
                                                                                            "aria-label":
                                                                                                "ant design",
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    className={`tbl-cell-attribute-2`}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                    }}
                                                                                >
                                                                                    <AntSwitch
                                                                                        name="delete"
                                                                                        checked={
                                                                                            permission?.delete
                                                                                        }
                                                                                        onChange={() =>
                                                                                            handleToggleForFolder(
                                                                                                permission?.value,
                                                                                                "delete"
                                                                                            )
                                                                                        }
                                                                                        inputProps={{
                                                                                            "aria-label":
                                                                                                "ant design",
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell
                                                                                    align="right"
                                                                                    className={`tbl-border-right tbl-cell-attribute-2`}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        top: 0,
                                                                                        left: 0,
                                                                                    }}
                                                                                >
                                                                                    <AntSwitch
                                                                                        name="redacted"
                                                                                        checked={
                                                                                            permission?.redacted
                                                                                        }
                                                                                        onChange={() =>
                                                                                            handleToggleForFolder(
                                                                                                permission?.value,
                                                                                                "redacted"
                                                                                            )
                                                                                        }
                                                                                        inputProps={{
                                                                                            "aria-label":
                                                                                                "ant design",
                                                                                        }}
                                                                                    />
                                                                                </TableCell>
                                                                            </>
                                                                        )}
                                                                    </TableRow>
                                                                    <TableRow className="extra-row"></TableRow>
                                                                </>
                                                            )
                                                        )}
                                                    </>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Col>
                            </Row>
                        </Paper>

                    </CustomTabPanel> */}
                    <CustomTabPanel value={1} index={valueForFolderTab}>
                        <Paper sx={{ width: "100%" }} className="type-list-paper-2">
                            <List container spacing={2}>
                                <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                    <ListItemText className="fs-14-muted">
                                        Creation Date
                                    </ListItemText>
                                    <ListItemText className="fs-14 text-end">
                                        {responseForFolder?.created_at}
                                    </ListItemText>
                                </ListItem>
                                <Divider component="li" className="divider-details" />

                                <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                    <ListItemText className="fs-14-muted">
                                        Modified On
                                    </ListItemText>
                                    <ListItemText className="fs-14 text-end">
                                        {responseForFolder?.updated_at}
                                    </ListItemText>
                                </ListItem>
                                <Divider component="li" className="divider-details" />

                                <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                    <ListItemText className="fs-14-muted">
                                        Total Media Items
                                    </ListItemText>
                                    <ListItemText className="fs-14 text-end">
                                        {responseForFolder?.media_count}
                                    </ListItemText>
                                </ListItem>
                                <Divider component="li" className="divider-details" />

                                <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                    <ListItemText className="fs-14-muted">
                                        Size
                                    </ListItemText>
                                    <ListItemText className="fs-14 text-end">
                                        {responseForFolder?.folder_size}
                                    </ListItemText>
                                </ListItem>
                                <Divider component="li" className="divider-details" />

                                <ListItem className="d-flex justify-content-between" style={{ paddingLeft: 0 }}>
                                    <ListItemText className="fs-14-muted">
                                        Created By
                                    </ListItemText>
                                    <Box>
                                        <Stack
                                            direction="row"
                                            alignContent="center"
                                            alignItems="center"
                                            spacing={1}
                                            className="z-n1 created-by-folder"
                                        >
                                            <Avatar
                                                src={responseForFolder?.created_by?.thumbnail}
                                                className="author-avtar-details"
                                            >
                                                {responseForFolder?.created_by?.name?.charAt(0)}
                                            </Avatar>
                                            <Typography className="fw-400 fs-14">
                                                {responseForFolder?.created_by?.name}
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </ListItem>
                                <Divider component="li" className="divider-details" />

                            </List>
                        </Paper>

                    </CustomTabPanel>

                </Box>
            </DialogContent>
            <DialogActions className="d-flex flex-wrap justify-content-between gap-2 p-3 z-0 action-btn-model">
                <Button
                    className="btn delete-btn-model"
                    onClick={handleCloseAddFolderModel}
                >
                    <FontAwesomeIcon icon={faXmark} />
                    Cancel
                </Button>
                <Button
                    className="btn primary-btn"
                    onClick={storeFolder}
                >
                    <FontAwesomeIcon icon={faFloppyDisk} />
                    Save Changes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddFolder