import * as Yup from "yup";

export const addNewNewsvalidationSchema = Yup.object().shape({
  title: Yup.string("Please enter your post title.").required(
    "Post title is required"
  ),
  content: Yup.string("Please enter your content.").required(
    "Post content is required"
  ),
  postType: Yup.string("Please select post type.").required(
    "Post type is required"
  ),

  image: Yup.mixed().when('typeSlug', {
    is: (val) => val.toLowerCase() !== 'documents',
    then: (schema) => schema.required("Post image is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  file: Yup.mixed().when('typeSlug', {
    is: 'documents',
    then: (schema) => schema.required("File is required for document type"),
    otherwise: (schema) => schema.notRequired(),
  })

});
