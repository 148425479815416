import { Box, Skeleton } from '@mui/material'
import React from 'react'

function ChatSkleton() {
  return (
    <>
      <Box sx={{ padding: "15px" }} className="d-flex justify-content-between align-items-center user-info-div" >
        <Box className="d-flex gap-3 justify-content-center align-items-center">
          <Skeleton variant="circular" width={35} height={35} />
          <Skeleton variant="text" width={130} />
          <Skeleton variant="rounded" width={110} height={30} />

        </Box>
      </Box>
      <Box sx={{ padding: "15px" }} className="user-chats-div-con">

        <Box className="chat-data-con">
          <Box className="chat-message-con left">

            <Skeleton variant="text" width={119} height={15} />

            <Box className=" d-flex gap-2">
              <Skeleton variant="circular" width={30} height={30} />
              <Box>
                <Skeleton variant="rounded" width={80} height={50} />
              </Box>
            </Box>
          </Box>
          <Box className="chat-message-con" sx={{ alignItems: 'end' }}>

            <Skeleton variant="text" width={80} height={15} />

            <Box className=" d-flex gap-2">
              <Box>
                <Skeleton variant="rounded" width={80} height={50} />
              </Box>
            </Box>
          </Box>
          <Box className="chat-message-con left">

            <Skeleton variant="text" width={119} height={15} />

            <Box className=" d-flex gap-2">
              <Skeleton variant="circular" width={30} height={30} />
              <Box>
                <Skeleton variant="rounded" width={80} height={50} />
              </Box>
            </Box>
          </Box>
          <Box className="chat-message-con" sx={{ alignItems: 'end' }}>

            <Skeleton variant="text" width={80} height={15} />

            <Box className=" d-flex gap-2">
              <Box>
                <Skeleton variant="rounded" width={80} height={50} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "15px" }} className="user-chat-send-div">
        <Box className="serach-box w-100">
          <Box className="rounded" sx={{ borderRadius: '35px !important', overflow: 'hidden' }}>

            <Skeleton variant="rounded" height={45} />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ChatSkleton