import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, Image, Toast } from "react-bootstrap";
import ReactDOM from "react-dom/client";
import ReactDOMServer from "react-dom/server";
import {
    TextField,
    Typography,
    Tabs,
    Tab,
    Box,
    IconButton,
    Breadcrumbs,
    Link,
    Divider,
    Stack,
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Grid,
    Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import 'grapesjs/dist/css/grapes.min.css';
import grapesjs from 'grapesjs';
import gjsPresetWebpage from 'grapesjs-preset-webpage';
import gjsNavbar from 'grapesjs-navbar';
import gjsPresetNewsLetter from 'grapesjs-preset-newsletter';
import gjsMJML from 'grapesjs-mjml'
// import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css'
// import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js'
import PreviewPage from "./pagePreview";
import context from "react-bootstrap/esm/AccordionContext";
import { pageAction } from "../../redux/slices/page/page";
import addUserMenuBlock from "../../components/common/editor-blocks/addUserMenuBlock";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { drawerMenuAction } from "../../redux/slices/drawer/drawer";
import {
    faHouse,
    faUsers,
    faFolder,
    faChevronDown,
    faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@mui/material/List";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import Collapse from "@mui/material/Collapse";
import api from "../../components/common/commonFunctionForApi";
import Loader from "../../components/common/loader";
import addUserMenuBlock2, { CustomMenu } from "../../components/common/editor-blocks/addUserMenuBlock2";
import logoutHelper from "../../helpers/logoutHelper";
import LinkBlock, { RenderLinkBlock } from "../../components/common/editor-blocks/linkBlock";


const TemplatePage = () => {
    const {
        open,
        selectedItem,
        nameCollapsable,
        typeCollapsable,
        drawerVariant,
        usersCollapsable,
    } = useSelector((state) => state?.drawerMenu);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { html, css, js, styles, components, menu, link, pageSlug } = useSelector((state) => state?.page);
    const { menuFor,
        adminAccessible } = useSelector((state) => state?.drawerMenu);

    const [userMenuLists, setUserMenuLists] = useState([])
    const [selectedLinkData, setSelectedLinkData] = useState([])
    // const [isLoading, setIsLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const [expandedMenuForUser, setExpandedMenuForUser] = useState({});
    const [getAllMenus, setGetAllMenus] = useState([])
    const [listingLabels, setListingLabels] = useState([]);
    const [linksData, setLinksData] = useState([])


    const userToken = localStorage.getItem("accessToken");

    const user = localStorage.getItem("user");
    const loggedUser = JSON.parse(user);

    const fetchAllMenus = async () => {
        try {
            setIsLoading(true);
            const response = await api.post(
                `admin/menu/all`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                }
            );
            setGetAllMenus(response?.data);
        } catch (err) {
            console.log("err`or in fetch menu api::", err);
            if (err?.response?.status == 401) {
                setIsLoading(false);
                logoutHelper(dispatch, navigate, userToken);
            }
            if (err?.response?.status == 429) {
                Toast.error("Too many attemps wait for 2 min.");
            }
        }
    }

    const fetchAllLabels = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`admin/link/label-list`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            setIsLoading(false)
            if (response?.success === true) {
                const labels = response?.response?.labels;
                setListingLabels(labels);
                if (pageSlug === "") {
                    dispatch(pageAction?.setLinkData({ selectLink: response?.response?.labels[0]?.value }))
                }
                await fetchLinkData(link?.selectLink === "" ? response?.response?.labels[0]?.value : link?.selectLink)
                setIsLoading(false);

            } else if (response?.success === false) {
                setIsLoading(false);
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
        } catch (err) {
            console.log("error in fetch user api::", err);
            setIsLoading(false);
            if (err?.response?.status === 401) {
                setIsLoading(false);
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
            if (err?.response?.status === 403) {
                setIsLoading(false);
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/links`);

                navigate("/");
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        }
    }

    const fetchLinkData = async () => {
        setIsLoading(true);
        try {
            const response = await api.get(`admin/link/link-list/${link?.selectLink}`, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response?.success === true) {
                const data = response?.response?.data
                setLinksData(data)
                setIsLoading(false);
            } else if (response?.success === false) {
                Toast.warning(response?.message);
                localStorage.setItem("last-redirect-page", `/links`);

            }
        } catch (err) {
            console.log("error in fetch links list api::", err);
            setIsLoading(false);
            if (err?.response?.status === 401) {
                Toast.warning(err?.response?.message);
                localStorage.setItem("last-redirect-page", `/links`);
            }
            if (err?.response?.status === 403) {
                Toast.warning(err?.response?.data[0]?.message);
                localStorage.setItem("last-redirect-page", `/links`);
            }
            if (err?.response?.status === 429) {
                Toast.error("Too many attemps wait for 2 min.")
            }
        } finally {
            setIsLoading(false);
        }
    };

    // useEffect(() => {
    //     const apiCall = async () => {
    //         await fetchAllMenus()
    //         await fetchAllLabels()
    //         // await fetchLinkData()
    //     }
    //     apiCall()
    // }, []);

    useEffect(() => {

        if (isLoading) return;

        const editor = grapesjs.init({
            container: '#gjs',
            height: '700px',
            width: '100%',

            // plugins: [gjsNavbar, gjsPresetNewsLetter, (editor) => addUserMenuBlock2(editor, {
            //     menuItems: userMenuLists,
            //     menuList: getAllMenus,
            //     setUserMenuLists: setUserMenuLists,
            //     userToken: userToken,
            //     dispatch: dispatch,
            //     menu: menu
            // }), (editor) => LinkBlock(editor, {
            //     linksData: linksData,
            //     listingLabels: listingLabels,
            //     userToken: userToken,
            //     dispatch: dispatch,
            //     link: link
            // })],

            plugins: [gjsNavbar, gjsPresetNewsLetter],

            storageManager: {
                id: 'gjs-',
                type: 'local',
                // autosave: true,
                autoload: false,
                storeComponents: true,
                storeStyles: true,
                storeHtml: true,
                storeCss: true,
            },
            deviceManager: {
                devices:
                    [
                        {
                            id: 'desktop',
                            name: 'Desktop',
                            width: '',
                        },
                        {
                            id: 'tablet',
                            name: 'Tablet',
                            width: '768px',
                            widthMedia: '992px',
                        },
                        {
                            id: 'mobilePortrait',
                            name: 'Mobile portrait',
                            width: '320px',
                            widthMedia: '575px',
                        },
                    ]
            },
            pluginsOpts: {
                [gjsNavbar]: {

                },
                [gjsPresetNewsLetter]: {

                },

            },

        })

        // Add or reinitialize the custom menu block whenever `userMenuLists` changes
        const reinitializeMenuBlock = () => {
            console.log("line 230")
            addUserMenuBlock2(editor, { menuItems: userMenuLists, menuList: getAllMenus, setUserMenuLists: setUserMenuLists, userToken: userToken, dispatch: dispatch, menu: menu });
            LinkBlock(editor, {
                linksData: linksData,
                listingLabels: listingLabels,
                userToken: userToken,
                dispatch: dispatch,
                link: link
            })
            // Optionally remove and re-add the block to ensure updates
            editor.BlockManager.remove('user-menu');
            editor.BlockManager.add('user-menu', {
                label: 'Custom Menu',
                category: 'Custom Components',
                content: {
                    type: 'react-component',
                    components: ReactDOMServer.renderToStaticMarkup(
                        <CustomMenu menuItems={userMenuLists} position={menu?.position} />
                    ),
                    tagName: 'div',
                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>',

            });
            editor.BlockManager.remove('cust-link-block');

            editor.BlockManager.add("cust-link-block", {
                label: "Cstom Link Block",
                category: "Custom Components",
                content: {
                    type: "custom-link-block-component", // Custom type for React
                    components: ReactDOMServer.renderToStaticMarkup(
                        <RenderLinkBlock link={linksData} />
                    ),
                    tagName: "div", // Wrap the menu in a div
                },
                media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z"/></svg>',
            });
        };


        const blockManager = editor.BlockManager;

        editor.on("load", () => {
            // reinitializeMenuBlock();
            console.log('Blocks:', editor.BlockManager.getAll());
            const iframe = editor.Canvas.getFrameEl();

            if (iframe) {
                const doc = iframe.contentDocument || iframe.document;

                // Inject Material-UI CSS into the iframe head
                const muiCssLink = document.createElement("link");
                muiCssLink.rel = "stylesheet";
                muiCssLink.href = "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"; // Example URL
                doc.head.appendChild(muiCssLink);
            }
        });

        blockManager.remove('list-items'); // Example: removes "list items"
        blockManager.remove('grid-items');

        // Add a custom video block
        // blockManager.add('custom-video', {
        //     label: 'Video',
        //     content: {
        //         type: 'video',
        //         provider: 'html5', // Default provider
        //         src: '', // Default source
        //     },
        //     category: 'Media',
        //     media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 128C0 92.7 28.7 64 64 64l256 0c35.3 0 64 28.7 64 64l0 256c0 35.3-28.7 64-64 64L64 448c-35.3 0-64-28.7-64-64L0 128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2l0 256c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1l0-17.1 0-128 0-17.1 14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/></svg>'
        // });

        const domComponents = editor?.DomComponents;

        // Extend the default video component
        // const defaultVideo = domComponents?.getType('video');

        // Add a Save button to the panel
        editor.Panels.addButton('options', {
            id: 'save-button',
            className: 'fa fa-save',
            command: 'save-data', // Custom command
            attributes: { title: 'Save Template' },
        });

        // Define the save command
        editor?.Commands?.add('save-data', {
            run: () => {
                const htmlData = editor.getHtml();
                console.log("html data::", htmlData)
                const cssData = editor.getCss();
                const jsData = editor.getJs()
                const componentData = JSON.stringify(editor.getComponents())
                const styleData = JSON.stringify(editor.getStyle())
                dispatch(pageAction?.setTemplateData({ htmlData, cssData, jsData, componentData, styleData }))
                // navigate("/page/preview")
                // Clear the editor canvas
                editor.DomComponents.clear(); // Clear all components
                editor.CssComposer.clear();   // Clear all styles

                // Optionally reset the editor storage
                // Clear local storage (if using local storage manager)
                if (editor.StorageManager.getConfig().type === 'local') {

                    // const storageId = editor.StorageManager.getConfig().id || 'gjs-';
                    const storageId = 'gjsProject';

                    console.log("storageId:::", storageId)

                    Object.keys(localStorage).forEach((key) => {
                        if (key.startsWith(storageId)) {
                            console.log("line 555 template")
                            localStorage.removeItem(key);
                        }
                    });
                }
                alert('Template saved!');

                window.history.back();
                // navigate("/page/preview")
                editor.destroy()
            },
        });

        if (html || css || js) {
            editor.DomComponents.clear();
            editor.CssComposer.clear();
            console.log("line 431 template")

            editor.setComponents(JSON.parse(components) || ''); // Import the HTML into GrapesJS
            editor.setStyle(JSON.parse(styles) || '');      // Import the CSS into GrapesJS
            if (js) {
                // To handle JavaScript, you can use custom logic if needed
                const existingScriptTag = document.getElementById('grapesjs-js');
                if (existingScriptTag) {
                    existingScriptTag.remove(); // Remove any existing script to avoid duplicates
                }
                const scriptTag = document.createElement('script');
                scriptTag.id = 'grapesjs-js';
                scriptTag.innerHTML = js;
                document.body.appendChild(scriptTag);
            }
        }

        return () => editor.destroy();

    }, [html, css, js, isLoading])

    return (
        <>
            <div
                className={`main-content-wrapper-2 ${open == true ? "drawer-open" : "drawer-close"
                    }`}
            >
                <Container
                    className={`${window.innerWidth <= 768 && "small-screen-container"}`}
                >
                    <div id="gjs"></div>
                </Container>
                <Loader isLoading={isLoading} />
            </div>
        </>
    )
}

export default TemplatePage