/**
 * Converts a UTC datetime string to a formatted local time string.
 *
 * @param {string} utcDateTime - The UTC datetime string to be converted.
 * @param {boolean} [numeric_month=false] - If true, the month will be formatted as a number (MM). Defaults to false for abbreviated/full month name.
 * @param {boolean} [full_month=false] - If true and numeric_month is false, the month will be formatted as a full name. Defaults to false for abbreviated month name.
 * @param {boolean} [year_include=false] - If true, the year will be included in the formatted string, even if it is the current year. Defaults to false.
 * 
 * @returns {string} The formatted date string in local time or an empty string if no valid datetime is provided.
 *
 * @example
 * // Example usage:
 * dateWithTime('2024-09-04 12:34:56', false, true, true);
 * // Output: "04 September 24, 12:34PM"
 * 
 * dateWithTime('2024-09-04 12:34:56', false, false, true);
 * // Output: "04 Sep 24, 12:34PM"
 * 
 * dateWithTime('2024-09-04 12:34:56', false, false, false);
 * // Output: "04 Sep, 12:34PM"
 * 
 * dateWithTime('2024-09-04 12:34:56', true, false, true);
 * // Output: "04/09/24, 12:34PM"
 */
export const dateWithTime = (utcDateTime, numeric_month = false, full_month = false, year_include = false) => {

  if (!utcDateTime) {
    return "";
  }

  // Convert the UTC datetime string to ISO 8601 format for better compatibility
  const isoDateTime = utcDateTime.replace(' ', 'T') + 'Z';
  const date = new Date(isoDateTime);

  // Check for invalid date
  if (isNaN(date.getTime())) {
    console.warn("Invalid date given ::", utcDateTime);
    console.warn("iso converted ::", isoDateTime);
    return "";
  }

  // Get the current year and the year from the provided date
  const currentYear = new Date().getFullYear();
  const dateYear = date.getFullYear();

  // Format day, month, and time components
  const day = date.toLocaleString(undefined, { day: '2-digit' });
  const hour = date.toLocaleString(undefined, { hour: '2-digit', hour12: true });
  const [onlyHour, amPm] = hour.split(' ');
  const minute = date.toLocaleString(undefined, { minute: '2-digit' }).padStart(2, '0');

  let month;

  // Determine the month format based on the input flags
  if (numeric_month) {
    month = date.toLocaleString(undefined, { month: 'numeric' }).padStart(2, '0');
  } else {
    month = date.toLocaleString(undefined, { month: full_month ? 'long' : 'short' });
  }

  // Construct the formatted date string
  let formattedDate = numeric_month
    ? `${day}/${month}/${date.toLocaleString(undefined, { year: '2-digit' })}, ${onlyHour}:${minute}${amPm}`
    : `${day} ${month}, ${onlyHour}:${minute}${amPm}`;

  // Include the year if it's not the current year or if explicitly requested
  if (dateYear < currentYear || year_include) {
    const year = date.toLocaleString(undefined, { year: '2-digit' });
    formattedDate = numeric_month
      ? `${day}/${month}/${year}, ${onlyHour}:${minute}${amPm}`
      : `${day} ${month} ${year}, ${onlyHour}:${minute}${amPm}`;
  }

  return formattedDate;
};

/**
 * Converts a UTC datetime string to a formatted local date string.
 *
 * @param {string} utcDateTime - The UTC datetime string to be converted.
 * @param {boolean} [numeric_month=false] - If true, the month will be formatted as a number (MM). Defaults to false for abbreviated/full month name.
 * @param {boolean} [full_month=false] - If true and numeric_month is false, the month will be formatted as a full name. Defaults to false for abbreviated month name.
 * @param {boolean} [year_include=false] - If true, the year will be included in the formatted string, even if it is the current year. Defaults to false.
 * 
 * @returns {string} The formatted date string in local time or an empty string if no valid datetime is provided.
 *
 * @example
 * // Example usage:
 * onlyDateFormat('2024-09-04 12:34:56', false, true, true);
 * // Output: "04 September 2024"
 * 
 * onlyDateFormat('2024-09-04 12:34:56', false, false, true);
 * // Output: "04 Sep 2024"
 * 
 * onlyDateFormat('2024-09-04 12:34:56', false, false, false);
 * // Output: "04 Sep"
 * 
 * onlyDateFormat('2024-09-04 12:34:56', true, false, true);
 * // Output: "04/09/2024"
 */
export const onlyDateFormat = (utcDateTime, numeric_month = false, full_month = false, year_include = false) => {

  if (!utcDateTime) {
    return "";
  }

  // Convert the UTC datetime string to ISO 8601 format for better compatibility
  const isoDateTime = utcDateTime.replace(' ', 'T') + 'Z';
  const date = new Date(isoDateTime);

  // Check for invalid date
  if (isNaN(date.getTime())) {
    console.warn("Invalid date given ::", utcDateTime);
    console.warn("iso converted ::", isoDateTime);
    return "";
  }

  // Get the current year and the year from the provided date
  const currentYear = new Date().getFullYear();
  const dateYear = date.getFullYear();

  // Format day, month, and time components
  const day = date.toLocaleString(undefined, { day: '2-digit' });

  let month;

  // Determine the month format based on the input flags
  if (numeric_month) {
    month = date.toLocaleString(undefined, { month: 'numeric' }).padStart(2, '0');
  } else {
    month = date.toLocaleString(undefined, { month: full_month ? 'long' : 'short' });
  }

  // Construct the formatted date string
  let formattedDate = numeric_month
    ? `${day}/${month}/${date.toLocaleString(undefined, { year: 'numeric' })}`
    : `${day} ${month}`;

  // Include the year if it's not the current year or if explicitly requested
  if (dateYear < currentYear || year_include) {
    const year = date.toLocaleString(undefined, { year: 'numeric' });
    formattedDate = numeric_month
      ? `${day}/${month}/${year}`
      : `${day} ${month} ${year}`;
  }

  return formattedDate;
};