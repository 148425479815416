import { lazy, Suspense } from "react";
import AuthGuard from "../utils/authGuard";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";
import Home from "../pages/home";
import AddNews from "../pages/home/addNews";
import MenusList from "../pages/menus";
import AddNewMenus from "../pages/menus/addNewMenu";

const menuRoutes = [
  {
    path: "/menu",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <MenusList />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/menu/add-new",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddNewMenus />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/menu/edit/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddNewMenus />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/menu/add/:type",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddNews />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/menu/:type",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <Home />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
  {
    path: "/menu/edit/:type/:id",
    element: (
      <Suspense>
        <AuthGuard>
          <AuthGuardForSuperAdmin>
            <AddNews />
          </AuthGuardForSuperAdmin>
        </AuthGuard>
      </Suspense>
    ),
  },
];

export default menuRoutes;
