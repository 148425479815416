import React from "react";
import Header from "./header";
import DrawerMenu from "./drawer";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const {
        open,
        selectedItem,
        nameCollapsable,
        drawerVariant,
        typeCollapsable,
        usersCollapsable,
        fetchNewType,
        fetchNewMenu
    } = useSelector((state) => state?.drawerMenu);

    return (
        <>
            <Header />
            <DrawerMenu
                open={open}
                dispatch={dispatch}
                selectedItem={selectedItem}
                nameCollapsable={nameCollapsable}
                drawerVariant={drawerVariant}
                typeCollapsable={typeCollapsable}
                usersCollapsable={usersCollapsable}
                fetchNewType={fetchNewType}
                fetchNewMenu={fetchNewMenu}
            />
            {children}
        </>
    );
};

export default Layout;