import React from "react";
import {
  Typography,
  IconButton,
  Chip,
  Box
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGripVertical, faXmark } from "@fortawesome/free-solid-svg-icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Draggable, Droppable } from "react-beautiful-dnd";
import activeType from "../../../assets/images/active.png"
import { Image } from "react-bootstrap";

function NestedTableRow({ rows, index, handleClick, openMenuFoEdit, depth = 0, handleClose, handleClickForAttId, dispatch }) {

  return (
    <>
      <Draggable draggableId={rows?.id.toString()} index={index}>
        {(provided) => (
          <>
            <Box
              hover
              role="checkbox"
              tabIndex={-1}
              className="cursor-pointer tbl-row-attribute-row-2"
              ref={provided.innerRef}

              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <Box align="right" className="menu-items-cell">
                <div className="main-div-icon-text d-flex align-items-center gap-2">
                  <FontAwesomeIcon icon={faGripVertical} />
                  <div className="d-flex flex-wrap gap-2 align-items-center ps-1">
                    <Typography className="fs-14">{rows?.menuItemTitle}</Typography>
                    <Chip label={rows?.menuItemType === "external" ? rows?.menuItemLink : rows?.menuItemInternalValue?.value} className="menu-link-chip" />
                  </div>
                </div>
              </Box>
              <Box align="right" className="menu-items-cell">
                <div className="d-flex fex-wrap justify-content-end align-items-center gap-2 w-100">
                  {rows?.menuItemVisible === true ? (
                    <Image src={activeType} width={"10px"} height={"10px"} className="ms-5" />
                  ) : (
                    <FontAwesomeIcon icon={faXmark} className="cross-icon" />
                  )}

                  <IconButton
                    aria-controls={openMenuFoEdit ? "long-menu" : undefined}
                    aria-expanded={openMenuFoEdit ? "true" : undefined}
                    onClick={(e) => {
                      handleClick(e, rows)
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </div>
              </Box>
            </Box>
            <Box className="extra-row"></Box>
            {rows?.children?.length > 0 && (
              <Droppable droppableId={`children-${rows.id}`}  >
                {(provided) => (
                  <Box ref={provided.innerRef} {...provided.droppableProps} sx={{ paddingLeft: `${depth + 1 * 24}px !important` }} className="cho">
                    {rows?.children.map((childRow, childIndex) => (
                      <NestedTableRow
                        key={childRow.id}
                        rows={childRow}
                        index={childIndex}
                        handleClick={handleClick}
                        openMenuFoEdit={openMenuFoEdit}
                        depth={depth + 1} // Increment depth for children
                      />
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            )}
          </>
        )}
      </Draggable >
    </>
  );
}

export default NestedTableRow