import { lazy, Suspense } from "react";
import AuthGuardForSuperAdmin from "../utils/authGuardForSuperAdmin";

import AuthGuard from "../utils/authGuard";
import AdminMedia from "../pages/admin-media/adminMedia";

const mediaRoutes = [
    {
        path: "/media",
        element: (
            <Suspense>
                <AuthGuard>
                    <AdminMedia />
                </AuthGuard>
            </Suspense>
        ),
    },
]

export default mediaRoutes