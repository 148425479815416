import React from "react";
// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";


import ReactDOMServer from "react-dom/server";
import { UserMenu } from "../userMenu";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
const defaultTheme = createTheme();


const addUserMenuBlock = (editor, options) => {
    const { userMenuProps } = options; // Pass props dynamically to UserMenu
    console.log("userMenuProps:::", userMenuProps)

    // Convert UserMenu to a static HTML string
    const userMenuHTML = ReactDOMServer.renderToStaticMarkup(
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline />
            <UserMenu {...userMenuProps} />
        </ThemeProvider>
    );

    // Register the block
    // editor.BlockManager.add("user-menu", {
    //     label: "User Menu",
    //     category: "Custom Components",
    //     content: {
    //         type: "react-component", // Custom type for React
    //         components: userMenuHTML,
    //         tagName: "div", // Wrap the menu in a div
    //     },
    //     media: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>'
    // });

    // Add the React renderer for the custom block
    // editor.DomComponents.addType("react-component", {
    //     isComponent: (el) => el.tagName === "DIV" && el.dataset.type === "react-component",
    //     model: {
    //         defaults: {
    //             tagName: "div",
    //             // attributes: { "data-type": "react-component" },
    //             droppable: false, // Prevent other components from being dropped inside
    //             copyable: false, // Disable copy
    //             editable: false, // Disable editing
    //         },
    //     },
    //     view: {
    //         onRender() {
    //             // Render the React component dynamically inside the block
    //             const { userMenuProps } = options;
    //             const menuRoot = this.el;

    //             const iframe = document.querySelector('iframe');

    //             // Access the iframe's document
    //             const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;

    //             // Create a new <style> element
    //             const style = iframeDocument.createElement('style');
    //             style.innerHTML = `
    //             * {
    //                 font-family: "Inter" !important;
    //                 //   background-color: #fff;
    //             }
    //                     .MuiPaper-root {
    //                         width: 320px;
    //                         transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    //                         overflow-x: hidden;

    //                         padding-top: 125px;
    //                         padding-bottom: 30px;
    //                         height: 100vh;

    //                         z-index: 8 !important;
    //                          padding: 30px;

    //                              background-color: #fff;
    //                             color: rgba(0, 0, 0, 0.87);
    //                             box-shadow: none;
    //                             overflow-y: auto;
    //                             display: flex;
    //                             flex-direction: column;
    //                             position: fixed;
    //                             top: 0;
    //                             outline: 0;
    //                             left: 0;
    //                             border-right: 1px solid rgba(0, 0, 0, 0.12);
    //                             white-space:nowrap;
    //                         }
    //                     .MuiList-root{
    //                             list-style: none;
    //                             margin: 0;
    //                             padding: 0;
    //                             position: relative;

    //                             display: flex;
    //                             flex-direction: column;
    //                             gap: 10px;
    //                             width: 100%;
    //                             padding-top: 0 !important;
    //                             padding-bottom: 0 !important;
    //                             white-space:nowrap;
    //                     }
    //                             .MuiListItem-root{

    //                                     -webkit-box-pack: start;
    //                                     justify-content: flex-start;
    //                                     -webkit-box-align: center;
    //                                     align-items: center;
    //                                     position: relative;
    //                                     text-decoration: none;
    //                                     width: 100%;
    //                                     box-sizing: border-box;
    //                                     text-align: left;
    //                                     display: block;

    //                                     padding-top: 8px;
    //                                     padding-bottom: 8px;
    //                                     padding-left: 16px;
    //                                     padding-right: 16px;
    //                             }
    //                             .MuiButtonBase-root{
    //                                     -webkit-tap-highlight-color: transparent;
    //                                     background-color: transparent;
    //                                     outline: 0;
    //                                     border: 0;
    //                                     margin: 0;
    //                                     border-radius: 0;
    //                                     padding: 0;
    //                                     cursor: pointer;
    //                                     user-select: none;
    //                                     vertical-align: middle;
    //                                     -webkit-appearance: none;
    //                                     -webkit-text-decoration: none;
    //                                     color: inherit;
    //                                     display: flex;
    //                                     -webkit-box-flex: 1;
    //                                     align-items: center;
    //                                     position: relative;
    //                                     text-decoration: none;
    //                                     min-width: 0;
    //                                     box-sizing: border-box;
    //                                     text-align: left;
    //                                     padding-top: 8px;
    //                                     padding-bottom: 8px;
    //                                     transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    //                                     min-height: 48px;
    //                                     -webkit-box-pack: initial;
    //                                     justify-content: initial;
    //                                     padding-left: 20px;
    //                                     padding-right: 20px;
    //                             }
    //                             .MuiListItemIcon-root{
    //                                     color: rgba(0, 0, 0, 0.54);
    //                                     flex-shrink: 0;
    //                                     display: inline-flex;
    //                                     min-width: 0;
    //                                     margin-right: 16px;
    //                                     -webkit-box-pack: center;
    //                                     justify-content: center;
    //                             }
    //                                     .MuiListItemIcon-root svg{
    //                                             overflow: visible;
    //                                             box-sizing: content-box;
    //                                                 font-size: 15px;
    //                                                 color: #660C60;
    //                                                 font-weight: 400;
    //                                                 line-height: 15px;
    //                                     }
    //                                                 .svg-inline--fa {
    //                                                 display: var(--fa-display, inline-block);
    //                                                 height: 1em;
    //                                                 overflow: visible;
    //                                                 vertical-align: -.125em;
    //                                             }
    //                                                 .menu-icon {
    //                                                     font-size: 15px;
    //                                                     color: #660C60;
    //                                                     font-weight: 400;
    //                                                     line-height: 15px;
    //                                                 }
    //                                     .MuiListItemText-root{
    //                                             flex: 1 1 auto;
    //                                             min-width: 0;
    //                                             margin-top: 4px;
    //                                             margin-bottom: 4px;
    //                                             opacity: 1;
    //                                     }
    //                                     .MuiTypography-root{
    //                                             font-size: 15px;
    //                                             font-weight: 400;
    //                                             line-height: 15px;
    //                                             opacity: 50%;
    //                                     }
    //                                             .menu-name span {
    //                                                 font-size: 15px;
    //                                                 font-weight: 400;
    //                                                 line-height: 15px;
    //                                                 opacity: 50%;
    //                                             }
    //                                     .selected-menu-item .menu-name span {
    //                                         color: white !important;
    //                                         opacity: inherit;
    //                                     }
    //                                         .selected-menu-item {
    //                                         background-color: #660C60 !important;
    //                                         border-radius: 6px;
    //                                         color: white !important;
    //                                     }
    //                                         .selected-menu-item .menu-icon {
    //                                             color: white !important;
    //                                         }
    //         /* Add any other custom styles here */
    //     `;
    //             // Append the style to the head of the iframe document
    //             iframeDocument.head.appendChild(style);

    //             const root = ReactDOM.createRoot(menuRoot)
    //             root.render(
    //                 <ThemeProvider theme={defaultTheme}>
    //                     <CssBaseline />
    //                     <UserMenu {...userMenuProps} />
    //                 </ThemeProvider>
    //             );

    //         },
    //     },
    // });
};

export default addUserMenuBlock;