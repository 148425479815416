import React, { useEffect, useRef, useState } from 'react';

const InView = ({ children }) => {
  const [hasBeenInView, setHasBeenInView] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setHasBeenInView(true); // Set to true once in view
          observer.disconnect();  // Stop observing once it's in view
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the element is in view
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return <div ref={ref}>{hasBeenInView ? children : null}</div>;
};

export default InView;
